import React from "react";

const Improvements = ()=>{
    return(
        <div className="gridContainer">
        <main className="mainContainer">
        <div className="js-toc-content js-toc-content notes">
            <h2 className="content" id="imporvements">
                Improvements
            </h2>
            <br/>
            {/* 1 */}
            <h3 className="content notes">
                1. We improved and simplified responses on different endpoints, to use same fields names:
            </h3>
            <li className="content notes li">
                &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  Upload file - Create;
            </li>
            <li className="content notes li">
                &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  Check Upload Status;
            </li>
            <li className="content notes li">
                &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  Start to initialize a solutions;
            </li>
            <li className="content notes li">
                &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  Check a solution initialization status.
            </li>
            <br/><br/>
            {/* 2 */}
            <h3 className="content notes">
                2. We improved the export JSON file:
            </h3>
            <li className="content notes li">
               &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  The main result is an array of all products objects;
            </li>
            <li className="content notes li">
               &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  Each product object consists of product_coordinates and the result data from the requested solution/s.
            </li>
            <br/><br/>
            {/* 3 */}
            <h3 className="content notes">
                3. We updated and improved the structure of the response at the Automatic Tagging solutions endpoints:	
            </h3>
            <li className="content notes li">
               &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  Get Categories;
            </li>
            <li className="content notes li">
               &nbsp;&nbsp; &#x2022; &nbsp;&nbsp; Get Category Products;
            </li>
            <li className="content notes li">
               &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  Get Product;
            </li>
            <li className="content notes li">
               &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  Update/ Validate Product;
            </li>
            <li className="content notes li">
               &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  Tag One Image.
            </li>
            <br/><br/>
            {/* 4 */}
            <h3 className="content notes">
                4. We updated and improved <i>style</i> and <i>occasion</i> attributes types:	
            </h3>
            <li className="content notes li">
               &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  17% improved accuracy on both;
            </li>
            <li className="content notes li">
               &nbsp;&nbsp; &#x2022; &nbsp;&nbsp;  “Streetstyle” is removed from style attribute type.
            </li>
            <br/><br/>
            </div>
        </main>
    </div>
    )
}
export default Improvements