import { useStore } from "react-context-hook";
import React from "react";

const CheckCreateStatus = () => {
  const [langCode, ] = useStore("langCode",'');

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
        <h3 class="content" id="check_create_status">
          Check Create Status
        </h3>
        <div class="content" >
          <div class="content">
            <pre class="mainLink">GET /v2/create/status/{'{dataset_id}'}</pre>
          </div>

          <p class="content">
            This API endpoint retrieves status about image processing for
            certain create request. You can find the dataset_id for
            a create request in the response of the create call.
          </p>
          <br />
          <h4 class="content unbold">Response properties</h4>
          <br />

          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for a particular data file</td>
              </tr>
              <tr>
                <td>dataset_count</td>
                <td>Number of datasets by this user</td>
              </tr>
              <tr>
                <td>treated_images_percentage</td>
                <td>
                  Percentage value of the amount of treated image, over the
                  total number of images
                </td>
              </tr>
              <tr>
                <td>counter_success</td>
                <td>Number of images treated successfully</td>
              </tr>
              <tr>
                <td>counter_fail</td>
                <td>Number of images failed to be treated successfully</td>
              </tr>
              <tr>
                <td>number_of products</td>
                <td>Number of products in the file</td>
              </tr>
              <tr>
                <td>errors</td>
                <td>
                  A list of objects containing information about failed images
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
          {langCode== 'js' ? 
          <>
           <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/create/status/1570624498775133" , {'{'}<br />
            {'       '}method: "GET",<br />
            {'       '}headers: {'{'}"Authorization": "Bearer access_token"{'}'}<br />
            {'     '}{'}'})<br /> <br/>
          </>
          : langCode == "python" ?  
            <><span class="grayChar">$ </span>requests.get("https://pva.pixyle.ai/v2/create/status/1570624498775133", <br />
              {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
              {'     '})<br /></>
          : 
          <>
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v2/create/status/1570624498775133<br /><br />

          </>
          }<br/>
            <span class="grayChar"># example JSON response</span><br /><br />

            <span class="redChar">{'{'}</span><br />
            {'  '}<span class="orangeChar">"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
            {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"create/status"</span><span class="whiteChar">,</span><br />
            {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
            {'  '}"dataset_count"<span class="whiteChar">: 2,</span><br />
            {'  '}"meta"<span class="whiteChar">: </span> <span class="redChar">{'{'}</span><br />
            {'    '}"number_of products"<span class="whiteChar">: 1000,</span><br />
            {'    '}"counter_fail"<span class="whiteChar">: 2,</span><br />
            {'    '}"counter_success"<span class="whiteChar">: 998,</span><br />
            {'    '}"treated_images_percentage"<span class="whiteChar">: 100,</span><br />
            {'  '}<span class="redChar">{'}'}</span><br />
            {'  '}"errors"<span class="whiteChar">: </span> <span class="redChar">[</span><br />
            {'    '}<span class="redChar">{'{'}</span><br />
            {'      '}<span class="orangeChar">"product_id"</span><span class="whiteChar">: 1,</span><br />
            {'      '}"image_name"<span class="whiteChar">: </span> <span class="yellChar">"img_7.jpg"</span><span class="whiteChar">,</span><br />
            {'      '}"error_code"<span class="whiteChar">: 415,</span><br />
            {'      '}"error_message"<span class="whiteChar">:</span> <span class="yellChar">"Unsupported Media Type"</span><span class="whiteChar">,</span><br />
            {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
            {'    '}<span class="redChar">{'{'}</span><br />
            {'      '}<span class="orangeChar">"product_id"</span><span class="whiteChar">: 2,</span><br />
            {'      '}<span class="orangeChar">"image_name"</span><span class="whiteChar">: </span> <span class="yellChar">"img_51.jpg"</span><span class="whiteChar">,</span><br />
            {'      '}<span class="orangeChar">"error_code"</span><span class="whiteChar">: 422,</span><br />
            {'      '}<span class="orangeChar">"error_message"</span><span class="whiteChar">: <span class="yellChar">"Unprocessable Entity"</span><span class="whiteChar">,</span><br />
            {'    '} <span class="redChar">{'}'}</span><br />
            {'  '}<span class="redChar">]</span><br />
                <span class="redChar">{'}'}</span></span></span></pre>
        </div>
      </div>
    </main>
  </div>
  )
}

export default CheckCreateStatus