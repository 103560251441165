import React from "react";
import { useStore } from "react-context-hook";
const ShotType = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
        <h3 class="content" id="shot_type_detection">4. ShotType Detection</h3>
        <p class="content">
          Pixyle’s ShotType detection solution detects the shot type in which a product image is taken. This solution helps the team working on the product displaying operations by automating and speeding up the image selection process.
        </p>
        <p class="content">
          The algorithm will process all product images and detect what type of shot they are. After the process is finished you will get a JSON as a response filled with shot type information for each product image. It will return data about which image is the packshot of the product and which ones are images with models. This information will allow you to perform better organization and better product display in your catalogue.
        </p>
        <h4 class="content">ShotType Detection on One Product</h4>
        <div class="content">
          <div class="content">
            <pre class="mainLink">POST /v1/solutions/shot-type/product</pre>
          </div>
          <p class="content">
            To use this solution you can simply upload all images for one product using this endpoint, and you will get the shot class detections per image as a response.
          </p>
        </div>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Field</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>id</td>
              <td>A unique identifier</td>
            </tr>
            <tr>
              <td>image_url_1</td>
              <td>A valid image URL as a string</td>
            </tr>
          </tbody>
        </table>
        <h4 class="content unbold">Recommended fields</h4>
        <p class="content">
          This list of fields in the schema below are not necessary, but in order to provide more comfort and functionalities during solution development, they are recommended.
        </p>
        <table class="content">
          <tbody>
            <tr>
              <th>Field</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>image_url_2..8</td>
              <td>More image files or image URLs of a product (max 7)</td>
            </tr>
            <tr>
              <td>product_url</td>
              <td>URL of a product</td>
            </tr>
            <tr>
              <td>product_title</td>
              <td>Title of a product</td>
            </tr>
            <tr>
              <td>category</td>
              <td>Category name of a product</td>
            </tr>
            <tr>
              <td>description</td>
              <td>Semantic representation of a product</td>
            </tr>
            <tr>
              <td>gender</td>
              <td>Gender type of product</td>
            </tr>
            <tr>
              <td>age_group</td>
              <td>The age group of a product</td>
            </tr>
            <tr>
              <td>size</td>
              <td>Available sizes for a product</td>
            </tr>
            <tr>
              <td>color</td>
              <td>Color of a product</td>
            </tr>
          </tbody>
        </table>
        { langCode== 'js' ? 
             <div class=" theCode">
                <pre><span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span>
                <span className="letBlue">let</span> <span className="blue">bodyContent</span> = [{'{'} <br/>
                {'       '}<span className="txt">"id"</span>: <span className="txt">"productid1"</span>,<br />
                {'       '}<span className="txt">"image_url_1"</span>:<span className="txt"> "https://store.image1.jpg"</span>, <br />
                {'       '}<span className="txt">"image_url_2"</span>:<span className="txt"> "https://store.image2.jpg"</span>, <br />
                {'       '}<span className="txt">"image_url_3"</span>:<span className="txt"> "https://store.image3.jpg"</span>, <br />
                {'       '}<span className="txt">"product_title"</span>:<span className="txt"> "Women Jeans"</span>, <br />
                {'       '}<span className="txt">"category"</span>:<span className="txt"> ""</span>, <br />
                {'       '}<span className="txt">"description"</span>: <span className="txt">"Description specified for this product."</span> {'}'}]<br />
                {'   '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v1/solutions/shot-type/product"</span>, {'{'}<br />
                {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
                {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
                {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>(<span className="blue">bodyContent</span>)<br/> 
                {'     '}{'}'})<br />
              </pre>
            </div>
          : langCode == "python" ?  
             <div class=" theCode"><pre>
              <span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>
                headers = {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'              '}<span className="txt">"Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>"</span> {'}'}<br />
                {'  '}data = [{'{'} <span className="txt">"id"</span>: <span className="txt">"productid1"</span>, <br />
                {'            '}<span className="txt">"image_url_1"</span>:<span className="txt"> "https://store.image1.jpg"</span>, <br />
                {'            '}<span className="txt">"image_url_2"</span>:<span className="txt"> "https://store.image2.jpg"</span>, <br />
                {'            '}<span className="txt">"image_url_3"</span>: <span className="txt">"https://store.image3.jpg"</span>, <br />
                {'            '}<span className="txt">"product_title"</span>:<span className="txt"> "Women Jeans"</span>, <br />
                {'            '}<span className="txt">"category"</span>:<span className="txt"> ""</span>, <br />
                {'            '}<span className="txt">"description"</span>: <span className="txt">"Description specified for this product."</span> {'}'}]<br />
                
                {'  '}response = requests.post<br/>
                {'       '}( <span className="txt">"https://pva.pixyle.ai/v1/solutions/shot-type/product"</span>,<br />
                {'       '}<span className="blue">headers</span> = headers,<br />
                {'       '}<span className="blue">data</span> = json.dumps(data) <br />
                {'      '}) <br />
              </pre>
            </div>   
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
            {'       '}-H 'Authorization: Bearer access_token' \<br />
            {'       '}--data '[{'{'}"id": "productid1", \<br />
            {'       '}"image_url_1": "https://store.image1.jpg", \<br />
            {'       '}"image_url_2": "https://store.image2.jpg", \<br />
            {'       '}"image_url_3": "https://store.image3.jpg", \<br />
            {'       '}"product_title": "Women Jeans", \<br />
            {'       '}"category": "", \<br />
            {'       '}"description": "Description specified for this product. "{'}'}]'\<br />
            {'       '}-X POST https://pva.pixyle.ai/v1/solutions/shot-type/product
          </pre>
        </div>
      
      
          }


        
        <h4 class="content unbold">Response properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>result</td>
              <td>An array of objects for each product image</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>The unique product identifier</td>
            </tr>
            <tr>
              <td>attributes</td>
              <td>An object that contains shot type detections for an image</td>
            </tr>
            <tr>
              <td>attribute</td>
              <td>An attribute name for an image shot type</td>
            </tr>
            <tr>
              <td>score</td>
              <td>The confidence score for an image shot type</td>
            </tr>
            <tr>
              <td>errors</td>
              <td>An array of objects containing information about failed images</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br/><br/>
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br/>
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br/>
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"shot-type/product"</span><span class="whiteChar">,</span><br/>
              {'  '}"product_id"<span class="whiteChar">:</span> <span class="yellChar">"productid1"</span><span class="whiteChar">,</span><br/>
              {'  '}"result"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'    '}<span class="redChar">{'{'}</span><br/>
              {'      '}"image_url_index"<span class="whiteChar">:</span><span class="yellChar">"image_url_1"</span><span class="whiteChar">,</span><br/>
              {'      '}"image_url"<span class="whiteChar">:</span><span class="yellChar">"https://store.image1.jpg"</span><span class="whiteChar">,</span><br/>
              {'      '}"attributes"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br/>
              {'        '}"correct"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute"<span class="whiteChar">:</span> <span class="yellChar">"model-packshot"</span><span class="whiteChar">,</span><br/>
              {'            '}"score"<span class="whiteChar">:</span> <span class="whiteChar">0.7818</span><br/>
              {'          '}<span class="redChar">{'}'}</span><br/>
              {'        '}<span class="redChar">]</span><span class="whiteChar">,</span><br/>
              {'        '}"all"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute"<span class="whiteChar">:</span> <span class="yellChar">"model-packshot"</span><span class="whiteChar">,</span><br/>
              {'            '}"score"<span class="whiteChar">:</span> <span class="whiteChar">0.7818</span><br/>
              {'          '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br/>
              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute"<span class="whiteChar">:</span> <span class="yellChar">"model-no-packshot"</span><span class="whiteChar">,</span><br/>
              {'            '}"score"<span class="whiteChar">:</span> <span class="whiteChar">0.2102</span><br/>
              {'          '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br/>
              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute"<span class="whiteChar">:</span> <span class="yellChar">"packshot"</span><span class="whiteChar">,</span><br/>
              {'            '}"score"<span class="whiteChar">:</span> <span class="whiteChar">0.0042</span><br/>
              {'          '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br/>
              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute"<span class="whiteChar">:</span> <span class="yellChar">"undefined"</span><span class="whiteChar">,</span><br/>
              {'            '}"score"<span class="whiteChar">:</span> <span class="whiteChar">0.0038</span><br/>
              {'          '}<span class="redChar">{'}'}</span><br/>
              {'        '}<span class="redChar">]</span><br/>
              {'      '}<span class="redChar">{'}'}</span><br/>
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br/>
              {'    '}<span class="redChar">{'{'}</span><br/>
              {'      '}"image_url_index"<span class="whiteChar">:</span> <span class="yellChar">"image_url_2"</span><span class="whiteChar">,</span><br/>
              {'      '}"image_url"<span class="whiteChar">:</span><span class="yellChar">"https://store.image2.jpg"</span><span class="whiteChar">,</span><br/>
              {'      '}"attributes"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br/>
              {'        '}"correct"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute"<span class="whiteChar">:</span> <span class="yellChar">"packshot"</span><span class="whiteChar">,</span><br/>
              {'            '}"score"<span class="whiteChar">:</span> <span class="whiteChar">0.9629</span><br/>
              {'          '}<span class="redChar">{'}'}</span><br/>
              {'        '}<span class="redChar">]</span><span class="whiteChar">,</span><br/>
              {'        '}"all"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute"<span class="whiteChar">:</span> <span class="yellChar">"packshot"</span><span class="whiteChar">,</span><br/>
              {'            '}"score"<span class="whiteChar">:</span> <span class="whiteChar">0.9629</span><br/>
              {'          '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br/>
              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute"<span class="whiteChar">:</span> <span class="yellChar">"model-packshot"</span><span class="whiteChar">,</span><br/>
              {'            '}"score"<span class="whiteChar">:</span> <span class="whiteChar">0.0301</span><br/>
              {'          '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br/>
              {'          '}<span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><br/>
              {'        '}<span class="redChar">]</span><br/>
              {'      '}<span class="redChar">{'}'}</span><br/>
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br/>
              {'    '}<span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><br/>
              {'  '}<span class="redChar">]</span><span class="whiteChar">,</span><br/>
              {'  '}"errors"<span class="whiteChar">:</span> <span class="redChar">[]</span><br/>
              <span class="redChar">{'}'}</span>
            </span></pre></div><br/><br/><br/>
      </div>
    </main>
  </div>

  )
}

export default ShotType