import React from "react";
import { useStore } from "react-context-hook";

const CheckUploadStatus = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
      <h3 class="content" id="check_upload_status">
          Check Upload Status
        </h3>
        <div class="content">
          <pre class="mainLink">GET /v3/datasets/<i>{'{dataset_id}'}</i>/status</pre>
        </div>
        <p class="content">
        This API endpoint retrieves status about image processing for certain create request. You can find the dataset_id for a create request in the response of the create call.
        </p>
        <br />
        <h4 class="content unbold">Request Parameters</h4>
        <div class="content" >
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A valid id of one of your datasets</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h4 class="content unbold">Response properties</h4>
        </div>
        
          {langCode== 'js' ? 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span><span className="yellow">fetch</span>(<span className="txt">"https://pva.pixyle.ai/v3/datasets/1570624498775133/status"</span>, {'{'}<br />
              {'       '}<span className="blue">method</span>:<span className="txt"> "GET"</span>,<br />
              {'       '}<span className="blue">headers</span>: {'{'}<span className="txt">"Authorization"</span>: <span className="txt">"Bearer access_token"</span>{'}'}<br />
              {'     '}{'}'})<br />
            </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.get(<span className="txt">"https://pva.pixyle.ai/v3/datasets/1570624498775133/status"</span>, <br />
            {'       '}<span className="blue">headers</span>= {'{'}<span className="txt">"Authorization"</span>: <span className="txt">"Bearer access_token"</span>{'}'}<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'      '} -X GET https://pva.pixyle.ai/v3/datasets/1570624498775133/status
          </pre></div>
          }
        <div class="content" >
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for the data file</td>
              </tr>
              <tr>
                <td>dataset_count</td>
                <td>Number of datasets by this user</td>
              </tr>
              <tr>
                <td>treated_images_percentage</td>
                <td>
                Percentage value of the amount of treated image, over the total number of images
                </td>
              </tr>
              <tr>
                <td>counter_success</td>
                <td>Number of images treated successfully</td>
              </tr>
              <tr>
                <td>counter_fail</td>
                <td>Number of images failed to be treated successfully</td>
              </tr>
              <tr>
                <td>counter_products</td>
                <td>Number of products in this dataset</td>
              </tr>
              <tr>
                <td>errors</td>
                <td>
                  A list of objects containing information about failed images
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" theCode">
          <pre><span class="grayChar"># example JSON response</span><br /><br />
            <span class="redChar">{'{'}</span><br />
            {'  '}<span class="orangeChar">"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"create/status"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"dataset_count"<span class="whiteChar">: 2,</span><br />
              {'  '}"meta"<span class="whiteChar">: </span> <span class="redChar">{'{'}</span><br />
              {'    '}"counter_products"<span class="whiteChar">: 1000,</span><br />
              {'    '}"counter_fail"<span class="whiteChar">: 2,</span><br />
              {'    '}"counter_success"<span class="whiteChar">: 998,</span><br />
              {'    '}"treated_images_percentage"<span class="whiteChar">: 100,</span><br />
              {'    '}"collection_name<span class="whiteChar">:</span><span class="yellChar">"images.csv"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              {'  '}"errors"<span class="whiteChar">: </span> <span class="redChar">[</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}<span class="orangeChar">"product_id"</span><span class="whiteChar">: 1,</span><br />
              {'      '}"image_name"<span class="whiteChar">: </span> <span class="yellChar">"img_7.jpg"</span><span class="whiteChar">,</span><br />
              {'      '}"error_code"<span class="whiteChar">: 415,</span><br />
              {'      '}"error_message"<span class="whiteChar">:</span><span class="yellChar">"Unsupported Media Type"</span><span class="whiteChar"></span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}<span class="orangeChar">"product_id"</span><span class="whiteChar">: 2,</span><br />
              {'      '}<span class="orangeChar">"image_name"</span><span class="whiteChar">: </span> <span class="yellChar">"img_51.jpg"</span><span class="whiteChar">,</span><br />
              {'      '}<span class="orangeChar">"error_code"</span><span class="whiteChar">: 422,</span><br />
              {'      '}<span class="orangeChar">"error_message"</span><span class="whiteChar">: </span><span class="yellChar">"Unprocessable Entity"</span><span class="whiteChar"></span><br />
              {'    '}<span class="redChar">{'}'}<br />
                {'  '}]<br />
                {'}'}</span>
            </span>
          </pre>
        </div>
      </div>
    </main>
  </div>

  )
}

export default CheckUploadStatus