import React from "react";
import { useStore } from "react-context-hook";
import { HashLink } from 'react-router-hash-link';

const VisualSearch = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br />
        <h2 class="content" id="solutions">Solutions</h2>
        <p class="content">
          Pixyle provides you with two types of solution.One type of solutions
          for your customers and one for your business.
        </p>
        <h4 class="content">For your customers</h4>
        <h3 class="content" id="visual_search">1. Visual Search</h3>
        <div class="content">
          <HashLink to={'/v4-documentation/visual-search#startSolution'}>
            <h4 class="content" id="startSolution">Start Solution</h4></HashLink><br />
          <p class="content">
            Our Visual Search solution allows your customers to search your online collection with images.To start using visual search, you first need to start initializing the setup for that collection with the corresponding dataset_id.This will create all files necessary for Visual Search to work for your online store.
          </p>
          <div class="content">
            <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/visual-search</pre>
          </div>
        </div>
        <br />
        <h4 class="content unbold">Request Parameters</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, to mark for visual search</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
            {'       '}/solutions/visual-search"</span>, {'{'}<br />
            {'       '}<span className="blue">method</span>:<span className="txt"> "GET"</span>,<br />
            {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
            {'     '}{'}'})<br />
          </pre>
        </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
            {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
            {'       '}/solutions/visual-search"</span>, <br />
            {'       '}<span className="blue">headers</span> = headers<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
            {'       '}/solutions/visual-search</pre></div>
          }
        <h4 class="content unbold">Response Properties</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>collection_name</td>
              <td>The name of the collection</td>
            </tr>
            <tr>
              <td>start_time</td>
              <td>The time when the visual search initialization process has started</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar"><span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"visual-search"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: </span> <span class="whiteChar">1570624498775133,</span><br />
              {'  '}"message"<span class="whiteChar">: </span> <span class="yellChar">"Visual search initializing process has started..."</span><span class="whiteChar">,</span><br />
              {'  '}"meta"<span class="whiteChar">: </span> <span class="redChar">{'{'}</span><br />
              {'    '}"user"<span class="whiteChar">: </span> <span class="yellChar">"FrontEnd"</span><span class="whiteChar">,</span><br />
              {'    '}"collection_name"<span class="whiteChar">: </span> <span class="yellChar">"images.csv"</span><span class="whiteChar">,</span><br />
              {'    '}"start_time"<span class="whiteChar">: </span> <span class="yellChar">"Thu, 10 Jan 2020 09:19:34"</span><span class="whiteChar"></span><br />
              {'  '}<span class="redChar">{'}'}<br />
                {'}'}
              </span></span></pre></div>
        <p class="content">
          <HashLink to={'/v4-documentation/visual-search#solutionStatus'}>
            <h4 class="content" id="solutionStatus">Solution Status</h4></HashLink><br />
          Once the visual search initialization endpoint is called, you will get this response immediately.Depending on the number of images, this may take some time, so you can check the visual search status on the following endpoint.
        </p>
        <div class="content">
          <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/visual-search/status</pre>
        </div>
        <br />
        <h4 class="content unbold">Request Parameters</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, where the visual search process is started or finished</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
           <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
                {'       '}/solutions/visual-search/status"</span>, {'{'}<br />
                {'       '}<span className="blue">method</span>: <span className="txt">"GET"</span>,<br />
                {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
                {'     '}{'}'})<br />
              </pre>
            </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
            {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
                {'       '}/solutions/visual-search/status"</span>, <br />
            {'       '}<span className="blue">headers</span> = headers<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
            {'       '} /solutions/visual-search/status </pre>
        </div>
          }
        <p class="content">This endpoint will send back information about the initialization progress of visual search for the given dataset_id.</p>
        <br /><br />
        <h4 class="content unbold">Response Properties</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>counter_products</td>
              <td>Total number of products</td>
            </tr>
            <tr>
              <td>processed_products</td>
              <td>Number of products successfully processed</td>
            </tr>
            <tr>
              <td>processed_products_percentage</td>
              <td>Percentage value of the number of processed products, over the total number of products</td>
            </tr>
            <tr>
              <td>dataset_count</td>
              <td>Number of datasets by the user</td>
            </tr>
            <tr>
              <td>collection_name</td>
              <td>Name of the collection</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"visual-search/status"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: </span> <span class="whiteChar">1570624498775133,</span><br />
              {'  '}"meta"<span class="whiteChar">: </span><span class="redChar">{'{'}</span><br />
              {'    '}"counter_products"<span class="whiteChar">: </span><span class="whiteChar"> 1000,</span><br />
              {'    '}"processed_products"<span class="whiteChar">: </span><span class="whiteChar"> 100,</span><br />
              {'    '}"processed_products_percentage"<span class="whiteChar">: </span> <span class="whiteChar"> 10.0</span><span class="whiteChar">,</span><br />
              {'    '}"collection_name"<span class="whiteChar">: </span>  <span class="yellChar">"images.csv"</span><span class="whiteChar"></span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}"dataset_count<span class="whiteChar">:</span><span class="whiteChar"> 2</span><br />
              <span class="redChar">{'}'}</span></span></pre></div>
        <p class="content">
          <HashLink to={'/v4-documentation/visual-search#searchByImage'}>
            <h4 class="content" id="searchByImage">Search by Image</h4></HashLink><br />
          Once the visual search initialization process is finished, the collection can be used for searching with a query image by uploading an image or sending a valid url.We will detect every fashion item in the query image, and for each of them, we'll return visually similar products. Images can be uploaded on the following endpoint.
        </p>
        <div class="content">
          <pre class="mainLink">POST /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/visual-search/image</pre>
        </div>
        <p class="content">
          Please note that:
        </p>
        <div class="content">
          <ul>
            <li>
              - The request must be made with Content-Type: multipart/form-data.
            </li>
          </ul>
        </div>
        <br />
        <h4 class="content unbold">Request Parameters</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>image</td>
              <td>A valid image file or a valid image URL as a string</td>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, where the visual search process is finished</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request with image file</span><br /><br />
            <span class="grayChar">$ </span>
            {/* <span className="letBlue">let</span> <span className="blue">file</span>= <span className="txt">'@img.jpg'</span><br /> */}
            <span className="letBlue">const</span> <span className="blue">formData</span> = <span className="letBlue">new</span> <span className="green">FormData()</span>;<br />
            {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>( <span className="txt">"image"</span>, <span className="blue">file</span> );<br />
            {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
            {'       '}/solutions/visual-search/image"</span>, {'{'}<br />
            {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
            {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "multipart/form-data"</span>,<br />
            {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
            {'       '}<span className="blue">body</span>: <span className="letBlue">formData</span> <br />
            {'     '}{'}'})<br />
         
          <br />
          <span class="grayChar"> # example request with image URL</span><br /><br />
          <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
            {'       '}/solutions/visual-search/image"</span>, {'{'}<br />
            {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
            {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "multipart/form-data"</span>,<br />
            {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
            {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>({'{'}<span className="txt"> "https://image-red-dress-photo-2896963/img.jpg" </span>{'}'})<br/>
            {'     '}{'}'})<br />
            </pre>  
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
          <pre><span class="grayChar"># example request with image file</span><br /><br />
          <span class="grayChar">$ </span>
          headers = {'{'}
                  <span className="txt"> "Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>" </span>
                  {'}'} <br/>
          {'  '}files = {'{'}
              <span className="txt"> "image"</span>: (<span className="txt"> "img.jpg"</span>, <span className="yellow">open</span>( <span className="txt">"img.jpg"</span>, <span className="txt">"rb"</span> ), )
                          {'}'}<br/>
          {'  '}response = requests.post<br/>
          {'       '}( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
            {'       '}/solutions/visual-search/image"</span>,<br />
          {'       '}<span className="blue">headers</span> = headers,<br />
          {'       '}<span className="blue">files</span> = files <br />
          {'      '})<br />
          <br/>
          <span class="grayChar"># example request with image URL</span><br /><br />
          <span class="grayChar">$ </span>
          headers = {'{'}
                  <span className="txt"> "Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>" </span>
                  {'}'} <br/>
          {'  '}files = {'{'}
          <span className="txt"> "image"</span>: (<span className="blue"> None</span>, <span className="txt">"https://image-red-dress-photo-2896963/img.jpg" </span>), {'}'}<br/>
          
          {'  '}response = requests.post<br/>
          {'       '}( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
            {'       '}/solutions/visual-search/image"</span>,<br />
          {'       '}<span className="blue">headers</span> = headers,<br />
          {'       '}<span className="blue">files</span> = files <br />
          {'      '})<br />
          </pre>
        </div>
          : 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request with image file</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
            {'       '}-H 'Authorization: Bearer access_token' \<br />
            {'       '}-F 'image<span class="redChar">=</span>@img.jpg' \<br />
            {'       '}-X POST https://pva.pixyle.ai/v4/datasets/1570624498775133/solutions\<br />
            {'       '}/visual-search/image<br /><br />

            <span class="grayChar"># example request with image URL</span><br /><br />

            <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
            {'       '}-H 'Authorization: Bearer access_token' \<br />
            {'       '}-F 'image<span class="redChar">=</span>=https://image-red-dress-photo-2896963/img.jpg' \<br />
            {'       '}-X POST https://pva.pixyle.ai/v4/datasets/1570624498775133/solutions \<br />
            {'       '}/visual-search/image
            </pre>
            </div>
          }
        <p class="content">
          Pixyle returns an array of objects for each detected item on the query image, sorted by their confidence score.Each object contains an array of results for the similar items for that box.
        </p>
        <p class="content">For each product in the result, Pixyle can return all metadata provided per it.</p>
        <br />
        <br />
        <h4 class="content unbold">Response properties</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id </td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>detected_categories </td>
              <td>An array of objects for all detected categories in the query image</td>
            </tr>
            <tr>
              <td>category</td>
              <td>A detected category on one fashion item (product) in the query image</td>
            </tr>
            <tr>
              <td>score</td>
              <td>
                The confidence score for the detected product category
              </td>
            </tr>
            <tr>
              <td>product_coordinates</td>
              <td>Coordinates of the box around the detected product (top-left corner and bottom-right corner)</td>
            </tr>
            <tr>
              <td>result</td>
              <td>
                An array of most visually similar products for the detected product in the query image
              </td>
            </tr>
            <tr>
              <td>custom_field</td>
              <td>Information about a certain field.(eg.title, description etc.)</td>
            </tr>
            <tr>
              <td>counter_categories</td>
              <td>Number of categories detected in the query image</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"visual-search/image"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: </span> <span class="whiteChar">1570624498775133,</span><br />
              {'  '}"meta"<span class="whiteChar">: </span> <span class="redChar">{'{'}</span><br />
              {'    '}"counter_categories"<span class="whiteChar">: </span> <span class="whiteChar">15,</span><br />
              {'    '}"collection_name"<span class="whiteChar">: </span> <span class="yellChar">"images.csv"</span><span class="whiteChar">,</span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}"detected_categories"<span class="whiteChar">: </span> <span class="redChar">[</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}"category"<span class="whiteChar">: </span> <span class="yellChar">"dress"</span><span class="whiteChar">,</span><br />
              {'      '}"score"<span class="whiteChar">: </span> <span class="yellChar">"91.25%"</span><span class="whiteChar">,</span><br />
              {'      '}"product_coordinates"<span class="whiteChar">: </span> <span class="redChar">{'{'}</span><br />
              {'        '}"xmin"<span class="whiteChar">: 18,</span><br />
              {'        '}"ymin"<span class="whiteChar">: 244,</span><br />
              {'        '}"xmax"<span class="whiteChar">: 437,</span><br />
              {'        '}"ymax"<span class="whiteChar">: 718</span><br />
              {'      '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"result"<span class="whiteChar">: </span><span class="redChar">[</span><br />
              {'        '}<span class="redChar">{'{'}</span><br />
              {'          '}"title"<span class="whiteChar">: </span> <span class="yellChar">""</span><span class="whiteChar">,</span><br />
              {'          '}"description"<span class="whiteChar">: </span> <span class="yellChar">""</span><span class="whiteChar">,</span><br />
              {'          '}"primary_image"<span class="whiteChar">: </span> <span class="yellChar">"https://www.projectcece.nl/static/img/products/people-tree/1d59b42.jpg"</span><span class="whiteChar">,</span><br />
              {'          '}"product_url"<span class="whiteChar">: </span> <span class="yellChar">"https://www.projectcece.nl/static/img/products/people-tree/1d59b42.jpg"</span><span class="whiteChar">,</span><br />
              {'          '}"category"<span class="whiteChar">: </span> <span class="yellChar">"dress"</span><span class="whiteChar">,</span><br />
              {'          '}"product_code"<span class="whiteChar">: </span> <span class="yellChar">"12"</span><span class="whiteChar">,</span><br />
              {'          '}"original_product_url"<span class="whiteChar">: </span> <span class="yellChar">"12"</span><br />
              {'        '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'        '}<span class="redChar">{'{'}</span><br />
              {'          '}"title"<span class="whiteChar">: </span> <span class="yellChar">""</span><span class="whiteChar">,</span><br />
              {'          '}"description"<span class="whiteChar">: </span> <span class="yellChar">""</span><span class="whiteChar">,</span><br />
              {'          '}"primary_image"<span class="whiteChar">: </span> <span class="yellChar">"https://www.projectcece.nl/static/img/products/8f7r.jpg"</span><span class="whiteChar">,</span><br />
              {'          '}"product_url"<span class="whiteChar">: </span> <span class="yellChar">"https://www.projectcece.nl/products/8f7r.jpg"</span><span class="whiteChar">,</span><br />
              {'          '}"category"<span class="whiteChar">: </span> <span class="yellChar">"dress"</span><span class="whiteChar">,</span><br />
              {'          '}"product_code"<span class="whiteChar">: </span> <span class="yellChar">"156"</span><span class="whiteChar">,</span><br />
              {'          '}"original_product_url"<span class="whiteChar">: </span> <span class="yellChar">"156"</span><br />
              {'        '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'        '}<span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><br />
              {'      '}<span class="redChar">]</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}"category"<span class="whiteChar">: </span> <span class="yellChar">"sneakers"</span><span class="whiteChar">,</span><br />
              {'      '}"score"<span class="whiteChar">: </span> <span class="yellChar">"91.25%"</span><span class="whiteChar">,</span><br />
              {'      '}"product_coordinates"<span class="whiteChar">: </span> <span class="redChar">{'{'}</span><br />
              {'        '}"xmin"<span class="whiteChar">: 18,</span><br />
              {'        '}"ymin"<span class="whiteChar">: 244,</span><br />
              {'        '}"xmax"<span class="whiteChar">: 437,</span><br />
              {'        '}"ymax"<span class="whiteChar">: 718</span><br />
              {'      '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"result"<span class="whiteChar">: </span><span class="redChar">[</span><span class="whiteChar">...</span><span class="redChar">]</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}<span class="redChar">]</span><br />
              <span class="redChar">{'}'}</span><br />
            </span></pre></div>

      </div>
    </main>
  </div>

  )
}

export default VisualSearch