import React from "react";
import { useStore } from "react-context-hook";
import { HashLink } from 'react-router-hash-link';

const BackgroundRemoval = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br />
       
       
        <h3 class="content" id="background_removal">7. Background Removal</h3>
        <div class="content">
          {/* <HashLink to={'/v4-documentation/background_removal#startSolution'}>
            <h4 class="content" id="startSolution">Start Solution</h4></HashLink><br /> */}
          <p className="content" style={{marginBottom:"2rem"}}>
          Pixyle’s Background Removal solution is designed to process images and seamlessly eliminate their backgrounds, resulting in images with only the main objects displayed on a clear background. This solution significantly enhances the efficiency of product display operations by automating and expediting the image selection process.
          </p>
          <p class="content">
          To leverage the Background Removal solution provided by the Pixyle.ai API, you can interact with its endpoint. By sending a single image as a request parameter to the endpoint, our system will respond by isolating the foreground object(s). The outcome can be tailored to include either a transparent background or one against a solid background color. The choice between these options depends on the desired output format and whether or not you have specified a preferred background color.
          </p>
          <div class="content">
            <pre class="mainLink">POST /v1/solutions/background-removal/image</pre>
          </div>
          <p class="content">
          To utilize the Background Removal solution, construct a POST request to the provided endpoint with the image you want to process. Ensure the image is included as a request parameter in the format below.
          You will receive the background removed as a response.
          </p>
        </div>
        <br />
        <h4 class="content unbold">Request parameters (multipart/form-data)</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>image</td>
              <td>Binary representation of the source image file</td>
            </tr>
          </tbody>
        </table>
        <h4 class="content unbold">Request parameters (application/json)</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>image</td>
              <td>Source image file (binary)</td>
            </tr>
            <tr>
              <td>image_file_b64</td>
              <td>Base64-encoded string of the source image file</td>
            </tr>
            <tr>
              <td>image_url</td>
              <td>URL of the source image file</td>
            </tr>
          </tbody>
          </table>
          
          <div class="content">
          <ul>
            <li>- You need to include only one of the above-listed request parameters for the content type that you intend to use. If you provide one image source parameter, make sure the other image source parameters are left empty.</li>
            <li>
              - Supported image formats include JPG, JPEG, PNG, and WEBP.
            </li>
            <li>- The maximum image size can be 12 MB.</li>
          </ul>
        </div><br />
        
        
        <h4 class="content unbold">Recommended fields</h4>
        <br />
        <p class="content">
        The schema below outlines optional parameters for both content types. If not provided, the fields will assume their default values. However, including these fields is recommended for added convenience and functionality during solution development.
          </p>
        <div className="content">
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>foreground_type</td>
              <td>Foreground type = [”fashion”, “person”, “auto”]</td>
            </tr>
            <tr>
              <td>output_format</td>
              <td>Output format = [”png”, “jpeg”]</td>
            </tr>
            <tr>
              <td>bg_color</td>
              <td>Solid background color</td>
            </tr>
            <tr>
              <td>category</td>
              <td>Category of fashion item to be segmented = ["overclothes", "topwear", "bottomwear", "overall", "shoes", "bags", "accessories"]</td>
            </tr>
            <tr>
              <td>get_base64</td>
              <td>Output type of final image</td>
            </tr>
           
          </tbody>
        </table>
       
        <div class="content">
          <ul>
            <li>- The "foreground_type" field establishes the criteria for object segmentation and can be assigned one of three values:
             <ul>
              <li className="circle">
               "fashion": Segments only fashion items. If the field is left empty, this value is assigned by default.

              </li>
            
              <li className="circle">"person": Segments the entire person.</li>
              <li className="circle">"auto": Initiates an attempt to detect both people and fashion items, with segmentation based on the largest object detected.</li>

             </ul></li>
            <li>- If "output_format" is not specified, it defaults to "png"</li>
            <li>
              - The "bg_color" field allows the addition of a solid color background, entered as a hex code (#0000FF), color name (”blue”), or RGB values (rgb(0,0,255)). Defaults vary based on the output format (default for "jpeg": white, default for "png": transparent).
            </li>
            <li>
             - The "category" field takes a category from the provided list, indicating which specific fashion item to be segmented in the output result. In the absence of a specified category, the solution will default to segmenting the largest detected object.
            </li>
            <li>- "get_base64" defaults to "True," indicating the final image will be returned as a base64-encoded string; otherwise, it returns as binary image data.</li>
          </ul>
        </div><br/>
            <div className="noteText">
            * Note: The solution can be set up to focus on the entire image (ignoring the foreground type) or only on specific objects (default setting). Please contact us if you need to change this setting.
            </div>
        </div>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># Example with image URL, Content-Type: application/json</span><br /><br />
            <span className="letBlue">let</span> <span className="blue">bodyContent</span> = [{'{'} <span className="txt">"image_url"</span>: <span className="txt">"https://store.image1.jpg"</span>, <br />
                {'       '}<span className="txt">"image_file_b64"</span>: <span className="txt">""</span>, <br />
                {'       '}<span className="txt">"foreground_type"</span>: <span className="txt">"fashion"</span>, <br />
               
                {'       '}<span className="txt">"output_format"</span>:<span className="txt"> "jpeg"</span>, <br />
                {'       '}<span className="txt">"bg_color"</span>: <span className="txt">"blue"</span>,  <br />
                {'       '}<span className="txt">"category"</span>: <span className="txt">"topwear"</span>,  <br />
                {'       '}<span className="txt">"get_base64"</span>: <span className="txt">true</span>{'}'}]<br />
                {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>, {'{'}<br />
                {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
                {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
                {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>(<span className="blue">bodyContent</span>),<br/> 
                {'       '}<span className="blue">redirect</span>: <span className="txt">"follow"</span><br />
                {'     '}{'}'})
          </pre>
          <br/>
          <pre>
            <span class="grayChar"># Example with image URL, Content-Type: application/json</span><br /><br />
            <span className="letBlue">const</span> <span className="blue">formData</span> = <span className="letBlue">new</span> <span className="yellow">FormData()</span>; <br />

                {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt">"image"</span>: file);<br />
                {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt">"foreground_type"</span>: <span className="txt">"fashion"</span>); <br />
                {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt">"output_format"</span>: <span className="txt">"png"</span>);<br />
                {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt">"bg_color"</span>: <span className="txt">"blue"</span>); <br />
                {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt">"category"</span>: <span className="txt">"topwear"</span>); <br />
                {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt">"get_base64"</span>: <span className="txt">"true"</span>); <br />
                
                {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>, {'{'}<br />
                {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
                {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
                {'       '}<span className="blue">body</span>: <span className="blue">formdata</span>,<br/> 
                {'       '}<span className="blue">redirect</span>: <span className="txt">"follow"</span><br />
                {'     '}{'}'})
          </pre>
        </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># Example with image URL, Content-Type: application/json</span><br /><br />
            <span class="grayChar">$ </span>
              headers = {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'              '}<span className="txt">"Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>"</span> {'}'}<br />
                {'  '}data =  [{'{'}<span className="txt">"image_url"</span>:<span className="txt"> "https://store.image1.jpg"</span>, <br />
                {'            '}<span className="txt">"image_file_b64"</span>:<span className="txt"> ""</span>,<br />
                {'            '}<span className="txt">"foreground_type"</span>: <span className="txt">"fashion"</span>, <br />
                {'            '}<span className="txt">"output_format"</span>:<span className="txt"> "jpeg"</span>, <br />
                
                {'            '}<span className="txt">"bg_color"</span>: <span className="txt">"blue"</span>,<br />
                {'            '}<span className="txt">"category"</span>: <span className="txt">"topwear"</span>,<br />
                {'            '}<span className="txt">"get_base64"</span>:<span className="txt"> True</span>  {'}'}]<br />
                {'  '}response = requests.post<br/>
                {'       '}( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>,<br/>
                {'       '}<span className="blue">headers</span> = headers,<br />
                {'       '}<span className="blue">data</span> = json.dumps(data) <br />
                {'      '}) <br /><br />
            </pre>
            <pre><span class="grayChar"># Example with image file, Content-Type: multipart/form-data</span><br /><br />
            <span class="grayChar">$ </span>
              headers = {'{'} <span className="txt">"Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>"</span> {'}'}<br />
                {'  '}data =  [{'{'}<span className="txt">"foreground_type"</span>: <span className="txt">"fashion"</span>, <br />
                {'            '}<span className="txt">"output_format"</span>:<span className="txt"> "jpeg"</span>, <br />
                {'            '}<span className="txt">"bg_color"</span>: <span className="txt">"blue"</span>,<br />
                {'            '}<span className="txt">"category"</span>: <span className="txt">"topwear"</span>,<br />
                {'            '}<span className="txt">"get_base64"</span>:<span className="txt"> True</span>  {'}'}]<br />
                {'  '}files = [( <span className="txt">"image"</span>, (<span className="txt">"img.jpg", open</span>(<span className="txt">"img.jpg", "rb"</span>),<span className="txt">'image/jpeg' </span>))]<br />
                {'  '}response = requests.post<br/>
                {'       '}( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>,<br/>
                {'       '}<span className="blue">headers</span> = headers,<br />
                {'       '}<span className="blue">data</span> = json.dumps(data) <br />
                {'       '}<span className="blue">files</span> = files <br />
                {'      '}) <br /><br />
            </pre>
          </div>
          : 
          <div class=" theCode">
            <pre>
              <span class="grayChar"># Example with image URL, Content-Type: application/json</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
              {'       '}-H 'Authorization: Bearer access_token' \<br />
              {'       '}--data '[{'{'}"image_url": "https://store.image1.jpg", \<br />
              {'       '}"image_file_b64": "", \<br />
              {'       '}"foreground_type": "fashion", \<br />
              {'       '}"output_format": "jpeg", \<br />
              {'       '}"bg_color": "blue", \<br />
              {'       '}"category": "topwear", \<br />
              {'       '}"get_base64": true, {'}'}]'  \<br />
              {'       '}-X  POST https://pva.pixyle.ai/v1/solutions/background-removal/image<br /><br />
            </pre>
            <pre>
              <span class="grayChar"># Example with image file, Content-Type: multipart/form-data</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
              {'       '}-H 'Authorization: Bearer access_token' \<br />
              {'       '}--form 'image=@"img.jpg"' \<br />
              {'       '}--form 'foreground_type="fashion"' \<br />
              {'       '}--form 'output_format="png"' \<br />
              {'       '}--form 'bg_color="blue"' \<br />
              {'       '}--form 'category="topwear"' \<br />
              {'       '}--form 'get_base64="true"' \<br />
              {'       '}-X  POST https://pva.pixyle.ai/v1/solutions/background-removal/image<br /><br />
            </pre>
            
            
          </div>
          }
        <br />
      
        <h4 class="content unbold">Response Properties</h4>
        <br />
        <div className="content"> 
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>result_b64</td>
              <td>Base64-encoded image data</td>
            </tr>
            <tr>
              <td>image</td>
              <td>Binary image data</td>
            </tr>
           
          </tbody>
        </table>
        <p className="content">
        Upon successful processing of your request, the Pixyle API will respond with the modified image data. The data can be in either base64-encoded or binary format, depending on your preference.
        </p>
        </div>
        
        <div class=" theCode">
          <pre>
            <span class="grayChar"># Request body (application/json) - Controls Accept header</span><br/><br/>
            <span class="orangeChar">
              <span class="redChar">{'{'}</span> "result_b64"<span class="whiteChar">:</span> <span class="yellChar">"iVBORw0KGgoAAAANSUhEUgAAAIsAAACFC..." </span><span class="redChar">{'}'}</span><span class="whiteChar"></span><br/>
             </span>
          </pre>
          <pre><br/><br/>
            <span class="grayChar"># Request body (image/*) - Controls Accept header</span><br/><br/>
            <span class="orangeChar">
              <span class="grayChar">{'('}</span>binary image data<span class="whiteChar">)</span> <br/>
             </span>
          </pre>
     
        </div><br/><br/><br/>
        {/* ERRORS */}
        <h4 class="content unbold">Error Handling</h4>
        <p className="content">
        In the event of an error, the API will provide a detailed error response with relevant information. Ensure to handle errors appropriately based on the status code and error message received.
        </p>
        
        <h4 class="content unbold">Example 1:</h4>
        <p class="content">
        If neither form or JSON data has been entered, a proper value error is raised.
        </p>
        <div class=" theCode">
          {/* req example */}
          {langCode== 'js' ? 
          <div class="">
          <pre>
            <span class="grayChar"># Example request</span><br /><br />
            <span className="letBlue">let</span> <span className="blue">bodyContent</span> = [{'{'}\n    \n{'}'}]<br />
                {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>, {'{'}<br />
                {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
                {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
                {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>(<span className="blue">bodyContent</span>),<br/> 
                {'       '}<span className="blue">redirect</span>: <span className="txt">"follow"</span><br />
                {'     '}{'}'})
          </pre>
          
        </div>
          : langCode == "python" ?  
          <div class="">
            <pre><span class="grayChar"># Example request</span><br /><br />
            <span class="grayChar">$ </span>
              headers = {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'              '}<span className="txt">"Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>"</span> {'}'}<br />
                {'  '}data =  [{'{'}{'}'}]<br />
                {'  '}response = requests.post<br/>
                {'       '}( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>,<br/>
                {'       '}<span className="blue">headers</span> = headers,<br />
                {'       '}<span className="blue">data</span> = json.dumps(data) <br />
                {'      '}) <br /><br />
            </pre>
               
          </div>
          : 
          <div class="">
            <pre>
              <span class="grayChar"># Example request</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
              {'       '}-H 'Authorization: Bearer access_token' \<br />
              {'       '}--data '[{'{'}{'}'}]'  \<br />
              {'       '}-X  POST https://pva.pixyle.ai/v1/solutions/background-removal/image<br /><br />
            </pre>
         
          </div>
          }
        <br/><br/>
          <pre><span class="grayChar"># example erorr response</span><br /><br />
            <span class="redChar">{'{'}</span><br />
            {'  '}<span class="orangeChar">"id"</span><span class="whiteChar">:</span> <span class="yellChar">"ba81a00e8aae11eead6e0242ac120003"</span>.<br />  
            {'  '}<span class="orangeChar">"meta"</span><span class="whiteChar">: </span><span class="redChar">{'{'}</span><br />
              {'    '}<span class="orangeChar">"timestamp"</span><span class="whiteChar">:</span><span class="yellChar"> "17008193553064153"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              {'  '}<span class="orangeChar">"title"</span><span class="whiteChar">: </span> <span class="yellChar">"Bad Request"</span><span class="whiteChar">,</span><br />
              {'  '}<span class="orangeChar">"status"<span class="whiteChar">: 400 ,</span><br />
              {'  '}<span class="orangeChar">"code"</span><span class="whiteChar">: 400 ,</span><br />
              {'  '}<span class="orangeChar">"detail"</span><span class="whiteChar">:</span><span class="yellChar"> "Missing data in request. Please enter form or JSON data."</span><br />
              <span class="redChar">{'}'}</span>
            </span>
          </pre>
         
        </div>
        {/* error 2 */}
        <h4 class="content unbold">Example 2:</h4>
        <p class="content">
        Missing image file in the request form would raise an error with status code 400.
        </p>
        <div class=" theCode">
           {/* req example */}
          {langCode== 'js' ? 
          <div class="">
          <pre>
            <span class="grayChar"># Example request</span><br /><br />
            <span className="letBlue">const</span> <span className="blue">formData</span> = <span className="letBlue">new</span> <span className="yellow">FormData()</span>; <br />

            {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt">"image", fileInput.files[0], "file");</span><br />
            {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt">"foreground_type"</span>: <span className="txt">"fashion"</span>); <br />

            {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>, {'{'}<br />
            {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
            {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
            {'       '}<span className="blue">body</span>: <span className="blue">formdata</span><br/> 
            {'       '}<span className="blue">redirect</span>: <span className="txt">"follow"</span>,<br />

            {'     '}{'}'})
          </pre>
          <br />
        </div>
          : langCode == "python" ?  
          <div class="">
            <pre><span class="grayChar"># Example request</span><br /><br />
            <span class="grayChar">$ </span>
              headers = {'{'} <span className="txt">"Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>"</span> {'}'}<br />
                {'  '}data =  [{'{'}<span className="txt">"foreground_type"</span>: <span className="txt">"fashion"</span>{'}'}]<br />
                {'  '}files = [( <span className="txt">"image"</span>, (<span className="txt">"file", open</span>(<span className="txt">"/path/to/file","rb"</span>), <br/>
                {'  '}<span className="txt">'application/octet-stream' </span>))]<br />
                {'  '}response = requests.post<br/>
                {'       '}( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>,<br/>
                {'       '}<span className="blue">headers</span> = headers,<br />
                {'       '}<span className="blue">data</span> = json.dumps(data) <br />
                {'       '}<span className="blue">files</span> = files <br />
                {'      '}) <br /><br />
            </pre>
            <br />
          </div>
          : 
          <div class="">
            <pre>
            <span class="grayChar"># Example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
              {'       '}-H 'Authorization: Bearer access_token' \<br />
              {'       '}--form 'image=@"img.jpg"' \<br />
              {'       '}--form 'foreground_type="fashion"' \<br />
              {'       '}-X  POST https://pva.pixyle.ai/v1/solutions/background-removal/image<br /><br />
            </pre>
            <br />
          </div>
          }
          <pre><span class="grayChar"># example error response</span><br /><br />
            <span class="redChar">{'{'}</span><br />
            {'  '}<span class="orangeChar">"id"</span><span class="whiteChar">:</span> <span class="yellChar">"e89412a48ab011eeb1a00242ac120003"</span>.<br />  
            {'  '}<span class="orangeChar">"meta"</span><span class="whiteChar">: </span><span class="redChar">{'{'}</span><br />
              {'    '}<span class="orangeChar">"timestamp"</span><span class="whiteChar">:</span><span class="yellChar"> "17008202915959983"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              {'  '}<span class="orangeChar">"title"</span><span class="whiteChar">: </span> <span class="yellChar">"Bad Request"</span><span class="whiteChar">,</span><br />
              {'  '}<span class="orangeChar">"status"<span class="whiteChar">: 400 ,</span><br />
              {'  '}<span class="orangeChar">"code"</span><span class="whiteChar">: 400 ,</span><br />
              {'  '}<span class="orangeChar">"detail"</span><span class="whiteChar">:</span><span class="yellChar"> "Missing image file in request."</span><br />
              <span class="redChar">{'}'}</span>
            </span>
          </pre>
         
        </div>

        {/* erorr 3 */}
        <h4 class="content unbold">Example 3:</h4>
        <p class="content">
        If the image’s format is not one of the supported ones (jpeg, jpg, png, webp), an error with status code 415 and "Unsupported image format. Allowed formats are: JPG, JPEG, PNG and WEBP.” message is raised.
        </p>
        <div class=" theCode">
        {langCode== 'js' ? 
          <div class=" ">
          <pre>
            <span class="grayChar">#  Example request </span><br /><br />
            <span className="letBlue">const</span> <span className="blue">formData</span> = <span className="letBlue">new</span> <span className="yellow">FormData()</span>; <br />

                {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt">"image"</span>, fileInput.files[0], "image1.gif");<br />
                {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt">"foreground_type"</span>: <span className="txt">"fashion"</span>); <br />
                
                {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>, {'{'}<br />
                {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
                {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
                {'       '}<span className="blue">body</span>: <span className="blue">formdata</span>,<br/> 
                {'       '}<span className="blue">redirect</span>: <span className="txt">"follow"</span><br />
                
                {'     '}{'}'})
          </pre>
        </div>
          : langCode == "python" ?  
          <div class="">
            <pre><span class="grayChar"># Example request </span><br /><br />
            <span class="grayChar">$ </span>
              headers = {'{'} <span className="txt">"Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>"</span> {'}'}<br />
                {'  '}data =  [{'{'}<span className="txt">"foreground_type"</span>: <span className="txt">"fashion"</span>  {'}'}]<br />
                {'  '}files = [( <span className="txt">"image"</span>, (<span className="txt">"image1.gif", <br/>
                {'   '}open</span>(<span className="txt">"/image1.gif", "rb"</span>),<span className="txt">'image1.gif' </span>)) ]<br />
                {'  '}response = requests.post<br/>
                {'       '}( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>,<br/>
                {'       '}<span className="blue">headers</span> = headers,<br />
                {'       '}<span className="blue">data</span> = json.dumps(data) <br />
                {'       '}<span className="blue">files</span> = files <br />
                {'      '}) <br /><br />
            </pre>
          </div>
          : 
          <div class="">
            <pre>
              <span class="grayChar"># Example request </span><br /><br />
              <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
              {'       '}--form 'image=@"/image1.gif"' \<br />
              {'       '}--form 'foreground_type="fashion"' \<br />
              
              {'       '}-X  POST https://pva.pixyle.ai/v1/solutions/background-removal/image<br /><br />
            </pre>
          </div>
          }
          <pre><br/><span class="grayChar"># example error response</span><br /><br />
            <span class="redChar">{'{'}</span><br />
            {'  '}<span class="orangeChar">"id"</span><span class="whiteChar">:</span> <span class="yellChar">"7ee541e68ab211ee9c560242ac120003"</span>.<br />  
            {'  '}<span class="orangeChar">"meta"</span><span class="whiteChar">: </span><span class="redChar">{'{'}</span><br />
              {'    '}<span class="orangeChar">"timestamp"</span><span class="whiteChar">:</span><span class="yellChar"> "17008209732830086"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              {'  '}<span class="orangeChar">"title"</span><span class="whiteChar">: </span> <span class="yellChar">"Unsupported Media Type."</span><span class="whiteChar">,</span><br />
              {'  '}<span class="orangeChar">"status"<span class="whiteChar">: 415 ,</span><br />
              {'  '}<span class="orangeChar">"code"</span><span class="whiteChar">: 415 ,</span><br />
              {'  '}<span class="orangeChar">"detail"</span><span class="whiteChar">:</span><span class="yellChar"> "Unsupported image format. Allowed formats are: JPG, JPEG, PNG and WEBP."</span><br />
              <span class="redChar">{'}'}</span>
            </span>
          </pre>
          
        </div>
        {/* error 4 */}
        <h4 class="content unbold">Example 4:</h4>
        <p class="content">
        ‘foreground_type’ can only take one value: ‘fashion’. Any other value would raise an error.
        </p>
        <div class=" theCode">

        {langCode== 'js' ? 
          <div class=" ">
          <pre>
            <span class="grayChar"># Example request</span><br /><br />
            <span className="letBlue">let</span> <span className="blue">bodyContent</span> = [{'{'} <span className="txt">"image_url"</span>: <span className="txt">"https://store.image1.jpg"</span>, <br />
                {'       '}<span className="txt">"foreground_type"</span>: <span className="txt">"FASHION"</span>, <br />
                {'       '}<span className="txt">"output_format"</span>:<span className="txt"> "jpeg"</span>{'}'}]<br />
                {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>, {'{'}<br />
                {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
                {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
                {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>(<span className="blue">bodyContent</span>)<br/> 
                {'     '}{'}'})
          </pre>
          
        </div>
          : langCode == "python" ?  
          <div class=" ">
            <pre><span class="grayChar"># Example request</span><br /><br />
            <span class="grayChar">$ </span>
              headers = {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'              '}<span className="txt">"Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>"</span> {'}'}<br />
                {'  '}data = [{'{'} <span className="txt">"image_url"</span>:<span className="txt"> "https://store.image1.jpg"</span>, <br />
                {'            '}<span className="txt">"foreground_type"</span>: <span className="txt">"FASHION"</span>, <br />
                {'            '}<span className="txt">"output_format"</span>:<span className="txt"> "jpeg"</span> {'}'}]<br />
                {'  '}response = requests.post<br/>
                {'       '}( <span className="txt">"https://pva.pixyle.ai/v1/solutions/background-removal/image"</span>,<br/>
                {'       '}<span className="blue">headers</span> = headers,<br />
                {'       '}<span className="blue">data</span> = json.dumps(data) <br />
                {'      '}) <br /><br />
            </pre>
            
          </div>
          : 
          <div class="">
            <pre>
              <span class="grayChar"># Example request</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
              {'       '}-H 'Authorization: Bearer access_token' \<br />
              {'       '}--data '[{'{'}"image_url": "https://store.image1.jpg", \<br />
              {'       '}"foreground_type": "FASHION", \<br />
              {'       '}"output_format": "jpeg" {'}'}]'  \<br />
              {'       '}-X  POST https://pva.pixyle.ai/v1/solutions/background-removal/image<br /><br />
            </pre>
            
            
            
          </div>
          }
          <pre><span class="grayChar"># example error response</span><br /><br />
            <span class="redChar">{'{'}</span><br />
            {'  '}<span class="orangeChar">"id"</span><span class="whiteChar">:</span> <span class="yellChar">"201049d08ab311ee9abf0242ac120003"</span>.<br />  
            {'  '}<span class="orangeChar">"meta"</span><span class="whiteChar">: </span><span class="redChar">{'{'}</span><br />
              {'    '}<span class="orangeChar">"timestamp"</span><span class="whiteChar">:</span><span class="yellChar"> "17008212436781805"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              {'  '}<span class="orangeChar">"title"</span><span class="whiteChar">: </span> <span class="yellChar">"Unprocessable Entity"</span><span class="whiteChar">,</span><br />
              {'  '}<span class="orangeChar">"status"<span class="whiteChar">: 422 ,</span><br />
              {'  '}<span class="orangeChar">"code"</span><span class="whiteChar">: 422 ,</span><br />
              {'  '}<span class="orangeChar">"detail"</span><span class="whiteChar">:</span><span class="redChar">[</span><span class="redChar">{'{'}</span>
              <span class="orangeChar"> "loc"</span><span class="whiteChar">:</span> <span class="redChar">[</span><span class="yellChar"> "foreground_type" </span><span class="redChar">]</span>,<br/>
              {'     '}<span class="orangeChar">"msg"</span><span class="whiteChar">: </span><span class="yellChar"> "Invalid input. Please choose 'fashion', 'person' or 'auto' for the foreground type."</span>,<br/>
              {'     '}<span class="orangeChar">"type"</span><span class="whiteChar">: </span><span class="yellChar"> "value_error.const"</span>,<br/>
              {'     '}<span class="orangeChar">"ctx"</span><span class="whiteChar">: </span><span class="redChar">{'{'}</span>
              <span class="orangeChar"> "given"</span><span class="whiteChar">: </span><span class="yellChar"> "FASHION"</span>,<br/>
             
              {'       '}<span class="orangeChar"> "permitted"</span><span class="whiteChar">: </span><span class="redChar">[</span><span class="yellChar"> "fashion" , "person", "auto"</span><span class="redChar">]</span>,<br/>
              
              {'     '} <span class="redChar">{'}'}</span><br/>

              {'   '}<span class="redChar">{'}'}]</span><br/>
              
              <span class="redChar">{'}'}</span>
            </span>
          </pre>
        </div>





        <p class="content">
          <br/>
          <br/>
          <br/>
        </p>
         
        

      </div>
    </main>
  </div>

  )
}

export default BackgroundRemoval