import React from "react";
import { useStore } from "react-context-hook";
const ScalingImages = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content">
        <h3 class="content" id="scaling_images">
          2. Scaling Images
        </h3>
        <div class="content">
          <div class="content">
            <pre class="mainLink">POST /v2/scale-images</pre>
          </div>
          <p class="content">
            Pixyle's scaling images solution, detects fashion items in the image, and scales the image proportionally so that the garments inside fit proportionally to the human body. This solution works with garments on a plain background, without a model wearing them.<br /><br />
            You can use it by simply uploading an image. Pixyle will then localize the fashion item, detect the category and scale the image proportionally to the human body. A JSON object is returned as a response, containing the detected category, a scale factor and the bounding box coordinates of the localized fashion item. This information will allow you to perform resizing and cutting of the fashion item only for your catalogue images.
          </p>

        </div>
        <h4 class="content unbold">Request Parameters</h4>


        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>image</td>
              <td>A valid image file or a valid image URL as string</td>
            </tr>

          </tbody>
        </table>
        {langCode== 'js' ? 
           <div class=" theCode">
           <pre>
           <span class="grayChar"># example request without changes</span><br /><br />
             <span class="grayChar">$ </span> let files = '@image1.jpg'<br />
             {'       '}const formData = new FormData();<br />
             {'       '}formData.append("image", files);<br />
             {'       '}fetch("https://pva.pixyle.ai/v2/scale-images", {'{'}<br />
             {'       '}method: "POST",<br />
             {'       '}headers: {'{'}"Content-Type": "multipart/form-data"<br />
             {'       '}"Authorization": "Bearer access_token" {'}'}<br />
             {'       '}body: formData <br />
             {'     '}{'}'})<br />
          
           <br />
           <span class="grayChar"># example request with changes</span><br /><br />
           <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/scale-images", {'{'}<br />
             {'       '}method: "POST",<br />
             {'       '}headers: {'{'}"Content-Type": "multipart/form-data"<br />
             {'       '}"Authorization": "Bearer access_token" {'}'}<br />
             {'       '}body:JSON.stringify({'{'}'https://store.image1.jpg'{'}'})<br/>
             {'     '}{'}'})<br />
             </pre>  
           </div> : langCode == "python" ?  
          <div class=" theCode">
          <pre><span class="grayChar"># example request with image file</span><br /><br />
          <span class="grayChar">$ </span>
          files = '@image1.jpg'<br />
          {'       '}response = requests.post<br/>
          {'       '}('https://pva.pixyle.ai/v2/scale-images',<br />
          {'       '}headers= {'{'}"Content-Type": "multipart/form-data", <br />
          {'       '}"Authorization": "Bearer access_token"{'}'}, <br />
          {'       '}files=files<br/>
          {'      '}) <br /><br />
          <span class="grayChar"># example request with changes</span><br /><br />
          <span class="grayChar">$ </span>
          files =json.dumps({'{'}'image':'https://store.image1.jpg'{'}'})<br />
          {'       '}response = requests.post<br/>
          {'       '}('https://pva.pixyle.ai/v2/scale-images',<br/>
          {'       '}headers= {'{'}"Content-Type": "multipart/form-data", <br />
          {'       '}"Authorization": "Bearer access_token"{'}'}, <br />
          {'       '}files=files<br/>
          {'      '}) <br />
          </pre>
        </div>
          : 
          <div class=" theCode">
            <pre>
            <span class="grayChar"># example request without changes</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br/>
              {'       '}-H 'Authorization: Bearer access_token' \<br/>
              {'       '}-F 'image=@image1.jpg' \<br/>
              {'       '}-X POST https://pva.pixyle.ai/v2/scale-images<br/><br/>

              <span class="grayChar"># example request with changes</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br/>
              {'       '}-H 'Authorization: Bearer access_token' \<br/>
              {'       '}-F 'image=https://store.image1.jpg' \<br/>
              {'       '}-X POST https://pva.pixyle.ai/v2/scale-images</pre>
          </div>

          }
        <h4 class="content unbold">Response Properties</h4>


        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>category</td>
              <td>Category of the product on the image</td>
            </tr>
            <tr>
              <td>scale_factor</td>
              <td>Number that indicates how much the image needs to be scaled</td>
            </tr>
            <tr>
              <td>product_coordinates</td>
              <td>
                Coordinates of the box that should be cropped
              </td>
            </tr>

          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br/><br/>
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br/>
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br/>
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"/scale-images"</span><span class="whiteChar">,</span><br/>
              {'  '}"img_name"<span class="whiteChar">:</span> <span class="yellChar">"image1.jpg"</span><span class="whiteChar">,</span><br/>
              {'  '}"category"<span class="whiteChar">:</span> <span class="yellChar">"jeans"</span><span class="whiteChar">,</span><br/>
              {'  '}"scale_factor"<span class="whiteChar">:</span> <span class="whiteChar"> 0.75,</span><br/>
              {'  '}"product_coordinates"<span class="whiteChar">:</span><span class="redChar"> {'{'}</span><br/>
              {'    '}"x_min"<span class="whiteChar">: 516,</span><br/>
              {'    '}"y_min"<span class="whiteChar">: 569,</span><br/>
              {'    '}"x_max"<span class="whiteChar">: 1063,</span><br/>
              {'    '}"y_max"<span class="whiteChar">: 1695</span><br/>
              {'  '}<span class="redChar">{'}'}</span><br/>
              <span class="redChar">{'}'}</span></span></pre>
        </div><br/><br/><br/><br/>
        
      </div>
    </main>
  </div>
  )
}

export default ScalingImages