import UploadDataFeed from './components/Version-4/UploadDataFeed'
import IntegratePixyleSolutions from './components/Version-4/IntagratePixyleSolutions'
import LoginV3 from './components/Version-3/Login-v3'
import ChangePasswordV3 from './components/Version-3/ChangePassword-v3';
import Authentication from './components/Version-4/Authentication';
import Errors from './components/Version-4/Errors';
import UploadFileV3 from './components/Version-3/UploadFile-v3';
import RestartUploadV3 from './components/Version-3/RestartUpload-v3';
import CheckUploadStatusV3 from './components/Version-3/CheckUploadStatus-v3';
import UpgradeDatasetV3 from './components/Version-3/UpgradeDataset-v3';
import RenameFileV3 from './components/Version-3/RenameFile-v3';
import DeleteFileV3 from './components/Version-3/DeleteFile-v3';
import DashboardV3 from './components/Version-3/Dashboard-v3';
import VisualSearchV3 from './components/Version-3/VisualSearch-v3';
import AutomaticTaggingV3 from './components/Version-3/AutomaticTagging-v3';
import AutomaticRescaleV3 from './components/Version-3/AutomaticRescale-v3';
import { withStore } from 'react-context-hook';
function App() {
  return (
    <div id='content'>
      <UploadDataFeed/>
      <IntegratePixyleSolutions/>
      <LoginV3/>
      <ChangePasswordV3/>
      <Authentication/>
      <Errors/>
      <UploadFileV3/>
      <RestartUploadV3/>
      <CheckUploadStatusV3/>
      <UpgradeDatasetV3/>
      <RenameFileV3/>
      <DeleteFileV3/>
      <DashboardV3/>
      <VisualSearchV3/>
      <AutomaticTaggingV3/>
      <AutomaticRescaleV3/>
    </div>
  );
}

export default withStore(App);
