import React from "react";
import { useStore } from "react-context-hook";

const Dashboard = () => {
  const [langCode, ] = useStore("langCode",'');

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
        <h3 class="content" id="dashboard">
          Dashboard
        </h3>

        <div class="content">
          <div class="content">
            <pre class="mainLink" style={{margin: '0 0 16px 0'}}>GET /v2/autotag/dashboard</pre>
          </div>

          <p class="content">
            This API endpoint allows you to check all your datasets. As a response you will get a list of JSON objects, each representing a dataset data.
          </p>
          <br />
          <h4 class="content unbold">Response properties</h4>
          <br />
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for a particular data file</td>
              </tr>
              <tr>
                <td>collection_name</td>
                <td>The original name of the collection</td>
              </tr>
              <tr>
                <td>created_at</td>
                <td>Date when the dataset was created</td>
              </tr>
              <tr>
                <td>updated_at</td>
                <td>Date when last time the dataset was updated</td>
              </tr>
              <tr>
                <td>number_of_products</td>
                <td>Total number of products in the dataset</td>
              </tr>
              <tr>
                <td>verified_products</td>
                <td>Number of validated products</td>
              </tr>
              <tr>
                <td>verified_products_percentage</td>
                <td>Percentage value of the amount of validated products, over the total number of products</td>
              </tr>
              <tr>
                <td>tagged_percentage</td>
                <td>Percentage value of the amount of tagged products, over the total number of products</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class=" theCode">
          <pre>
          <span class="grayChar"># example request</span><br /><br />

          {langCode== 'js' ? 
          <>
           <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/dashboard" , {'{'}<br />
            {'       '}method: "GET",<br />
            {'       '}headers: {'{'}"Authorization": "Bearer access_token"{'}'}<br />
            {'     '}{'}'})<br /> <br/>
          </>
          : langCode == "python" ?  
            <>
              <span class="grayChar">$ </span>requests.get("https://pva.pixyle.ai/v2/autotag/dashboard", <br />
              {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
              {'     '})<br />
            </>
          :
          <>
          <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br/>
            {'       '}-X GET https://pva.pixyle.ai/v2/autotag/dashboard<br/><br/>
          </> 
          }<br />
            <span class="grayChar"># example JSON response</span><br/><br/>
            <span class="orangeChar">
              <span class="redChar">[</span><br/>
              {'  '}<span class="redChar">{'{'}</span><br/>
              {'    '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br/>
              {'    '}"collection_name"<span class="whiteChar">:</span> <span class="yellChar">"images.csv"</span><span class="whiteChar">,</span><br/>
              {'    '}"created_at"<span class="whiteChar">:</span> <span class="yellChar">"Fri, 27 Dec 2019 12:25:46 GMT"</span><span class="whiteChar">,</span><br/>
              {'    '}"updated_at"<span class="whiteChar">:</span> <span class="yellChar">"Fri, 27 Dec 2019 12:25:46 GMT"</span><span class="whiteChar">,</span><br/>
              {'    '}"number_of_products"<span class="whiteChar">: 1000,</span><br/>
              {'    '}"verified_products"<span class="whiteChar">: 0,</span><br/>
              {'    '}"verified_products_percentage"<span class="whiteChar">: 0.00,</span><br/>
              {'    '}"tagged_percentage"<span class="whiteChar">: 100.00,</span><br/>
              {'  '}<span class="redChar">{'}'}<span class="whiteChar">,</span></span><br/>
              {'  '}<span class="whiteChar">...</span><br/>
              <span class="redChar">]</span><br/>
            </span></pre></div>
      </div>
    </main>
  </div>
  )
}

export default Dashboard