import React from "react";
import { useStore } from "react-context-hook";
const RestartUpload = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
        <h3 class="content" id="restart_upload">Restart Upload</h3>
        <div class="content">
          <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/restart</pre>
        </div>

        <p class="content">
          This API endpoint is for restarting the process of creating a dataset if it was interrupted by an error while uploading data to the server.
        </p>
        <br />
        <h4 class="content unbold">Request Parameters</h4>
        <br />
        <div class="content" >
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for a certain data file</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h4 class="content unbold">Response properties</h4>
        </div>

        {langCode== 'js' ? 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133/restart"</span>, {'{'}<br />
              {'       '}<span className="blue">method</span>:<span className="txt"> "GET"</span>,<br />
              {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
              {'     '}{'}'})<br />
            </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'} <span className="txt">"Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>"</span></span> {'}'}<br />
            {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133/restart"</span>, <br />
            {'       '}<span className="blue">headers</span> = headers <br/>
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$</span> curl  -H 'Authorization: Bearer access_token' \<br />
              {'        '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133/restart
            </pre>
          </div>
          }
        <div class="content" >
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for the data file</td>
              </tr>
              <tr>
                <td>collection_name</td>
                <td>
                  Name of the collection
                </td>
              </tr>
              <tr>
                <td>dataset_count</td>
                <td>Number of datasets by this user</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" theCode">
          <pre><span class="grayChar"># example JSON response</span><br /><br />
            <span class="redChar">{'}'}</span><br />
            {'  '}<span class="orangeChar">"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"restart"</span><span class="whiteChar">,</span><br />
              {'  '}"message"<span class="whiteChar">: </span> <span class="yellChar">"Dataset restarted successfully, please check the status of downloading data from the uploaded dataset  on /v4/datasets/&lt;dataset_id&gt;/status"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"dataset_count"<span class="whiteChar">: 2,</span><br />
              {'  '}"meta"<span class="whiteChar">: </span><span class="redChar">{'{'}</span><br />
              {'    '}"collection_name"<span class="whiteChar">:</span><span class="yellChar"> "images.csv"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span>
            </span>
          </pre>
        </div>
      </div>
    </main>
  </div>

  )
}

export default RestartUpload