import React from "react";
import { useStore } from "react-context-hook";

const DeleteFile = () => {
  const [langCode, ] = useStore("langCode",'');

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
      <h3 class="content" id="delete_file">
          Delete File
        </h3>
        <div class="content">
          <pre class="mainLink">DELETE /v3/datasets/<i>{'{dataset_id}'}</i></pre>
        </div>
        <div class="content">
          <p class="content">
            This API endpoint allows you to delete a certain dataset. When deleting a dataset, all its images are also deleted, so you can not use them anymore. This process may take up to few minutes, depending on the number of images, so please wait for the API response.
          </p>
        </div>
        
        <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
          {langCode== 'js' ? 
             <div>
             <span class="grayChar">$ </span><span className="yellow">fetch</span>(<span className="txt">"https://pva.pixyle.ai/v3/datasets/1570624498775133"</span>, {'{'}<br />
             {'       '}<span className="blue">method</span>:<span className="txt"> "DELETE"</span>,<br />
             {'       '}<span className="blue">headers</span>: {'{'}<span className="txt">"Authorization"</span>:<span className="txt"> "Bearer access_token"</span> {'}'}<br />
             {'     '}{'}'})<br /><br /></div>
          : langCode == "python" ?  
             <div>
                <span class="grayChar">$ </span>requests.delete(<span className="txt">"https://pva.pixyle.ai/v3/datasets/1570624498775133"</span>,<br/>
                {'       '}<span className="blue">headers</span>= {'{'}<span className="txt">"Authorization"</span>:<span className="txt"> "Bearer access_token"</span>{'}'}<br />
                {'     '})<br />
             </div> 
          : 
          <div>
            <br/>
              <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
              {'       '}-X DELETE https://pva.pixyle.ai/v3/datasets/1570624498775133<br /><br />
            </div>
          }<br/><br/>
          <br/>
              {/* response */}
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'   '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'   '}"method"<span class="whiteChar">:</span> <span class="yellChar">"delete"</span><span class="whiteChar">,</span><br />
              {'   '}"dataset_id"<span class="whiteChar">: 1570624498775133</span><br />
              <span class="redChar">{'}'}</span></span></pre></div>
      </div>
    </main>
  </div>
  )
}

export default DeleteFile