import React from "react";

const Authentication = () => {
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
      <h3 class="content" id="authentication">
            Authentication
          </h3>
  
          <p class="content">
            Pixyle's API can only be used by verified clients. We generate unique user access token for each client to use, for sending authenticated requests to our API. The access token will be used to authenticate each API call. We provide OAuth 2.0 to authentication. An opaque string, called Bearer token is an Access token that our clients need to add in all request headers to be authenticated as a concrete user.
          </p>
  
          
          <h4 class="content" id="oauth">OAuth 2.0</h4>
          
          <p class="content">
            This OAuth 2.0. authentication method with Bearer Tokens is the
            predominant type of access token.
          </p><br/><br/><br/>
      </div>
    </main>
  </div>
  )
}

export default Authentication