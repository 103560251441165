import WhatIsNew from './components/Version-4-releaseNotes/WhatIsNew';
import Improvements from './components/Version-4-releaseNotes/Improvements';
import TaxonomyChanges from './components/Version-4-releaseNotes/TaxonomyChanges';
import { withStore } from 'react-context-hook';
import NavbarV4ReleaseNotes from './components/Version-4-releaseNotes/Navbar-v4-ReleaseNotes'
function App() {
  return (
    <div id='content'>
      <NavbarV4ReleaseNotes/>
      <WhatIsNew/>
      <Improvements/>
      <TaxonomyChanges/>
    </div>
  );
}

export default withStore( App);
