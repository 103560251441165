import React from "react";
import { useStore } from "react-context-hook";

const Login = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
        <div class="content">
          <h2 class="content" id="api_overview">
            API Overview
          </h2><br/>

          <h3 class="content" id="introduction">
            Introduction
          </h3><br/>

          <p class="content">
            Pixyle’s API is accurate, powerful and integrates seamlessly with any tech stack.It provides endpoints that allows developers to index their data and use our solutions efficiently.Here you can find detailed guidelines on how to use the Pixyle's API. We are continuously improving the accuracy of our Visual AI technology and all solutions, and releasing new versions twice a year. At any release, the API documentation will be updated.
          </p>
        </div>

        <div class="content">

          <h3 class="content" id="login">
            Login
          </h3>
          <br />
          <p class="content">
            After we give you your username and password, you can use them to log in on this endpoint.As a response you will get access_token and refresh_token, so later you can use them on other endpoints.
          </p>
          <div class="content">
            <pre class="mainLink">POST /v2/login</pre>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
        </div>

        <div class="content">

          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>username</td>
                <td>Username you are provided with</td>
              </tr>
              <tr>
                <td>password</td>
                <td>Password you are provided with</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h4 class="content unbold">Response properties</h4>
          <br />
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>access_token</td>
                <td>A unique token for each user, to use for authorization</td>
              </tr>
              <tr>
                <td>refresh_token</td>
                <td>A refresh token, to get new access token, when the old one is expired</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" theCode">
          <pre>
          <span class="grayChar"># example request</span><br /><br />
        {langCode== 'js' ? 
         <div>
            <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/login", {'{'}<br />
          {'       '}method: "POST",<br />
          {'       '}headers: {'{'}"Content-Type": "application/json"{'}'},<br />
          {'       '}body:'{"{"}"username":"FrontEnd","password":"Frontend123#"{'}'}'<br />
          {'     '}{'}'})<br /> <br/>
         </div>
          : langCode == "python" ?  
            <div>
              <span class="grayChar">$ </span>requests.post("https://pva.pixyle.ai/v2/login", <br />
                {'       '}data= '{'{'}"username":"FrontEnd","password":"Frontend123#"{'}'}',<br />
                {'       '}headers= {'{'}"Content-Type": "application/json"{'}'}<br />
                {'     '})<br />
            </div>
          : 
            <div>
                <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br/>
                  {'       '}--data '{"{"}"username":"FrontEnd","password":"Frontend123#"{'}'}' \<br/>
                  {'       '}-X POST https://pva.pixyle.ai/v2/login<br/><br/>
              </div>
          }
        <br /><br />

            <span class="grayChar"># example JSON response</span><br/><br/>

            <span class="redChar">{'{'}</span><br/>
            {'  '}<span class="orangeChar">"access_token"</span>: <span class="yellChar">"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE...5Kxsf8txew3c"</span>,<br/>
            {'  '}<span class="orangeChar">"refresh_token"</span>: <span class="yellChar">"eyJ0eXAiOiJKV1QiLCbGciOiJIUzI1NiJ9.eyJpYXQiOj...6cmfmjFbu6mHE2g"</span>,<br/>
            <span class="redChar">{'}'}</span></pre>
        </div>
      </div>
    </main>
  </div>

  )
}

export default Login