import React from "react";

const Errors = () => {
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
      <h3 class="content" id="errors">
            Errors
          </h3>
  
          <p class="content">
            The response from the API includes response codes to indicate the
            success or failure of a request. If the request was successful,
            without any errors, the response code will be 200, and generally all
            codes in the 2xx range mean that the request was successful. Other
            types of codes that the API can return are codes in the 4xx range,
            that indicate an error in the client’s request, and codes in the 5xx
            range point to a server error.
          </p>
  
          <h4 class="content" id="the-error-object">The Error Object</h4>
  
          <p class="content">
            When a request fails, the API will return an error object, which may have the following elements:
          </p>
  
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>id</td>
                <td>
                  A unique identifier for this particular occurrence of the problem
                </td>
              </tr>
              <tr>
                <td>title</td>
                <td>
                  A short, human-readable summary of the problem that should not
                  change from occurrence to occurrence of the problem, except for
                  purposes of localization
                </td>
              </tr>
              <tr>
                <td>status</td>
                <td>
                  The HTTP status code applicable to this problem, expressed as a
                  string value
                </td>
              </tr>
              <tr>
                <td>code</td>
                <td>
                  An application-specific error code, expressed as a string value
                </td>
              </tr>
              <tr>
                <td>detail</td>
                <td>
                  A human-readable explanation specific to this occurrence of the
                  problem. Like title, this field’s value can be localized
                </td>
              </tr>
              <tr>
                <td>meta</td>
                <td>
                  An object containing non-standard meta-information about the
                  error
                </td>
              </tr>
            </tbody>
          </table>
  
          <h4 class="content">HTTP Response Codes</h4>
  
          <table class="content">
            <tbody>
              <tr>
                <th>Response Code</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>200</td>
                <td>
                  OK – The request has succeeded and results were sent in
                  response
                </td>
              </tr>
              <tr>
                <td>204</td>
                <td>
                  No Content – Successful request, but no results to return
                </td>
              </tr>
              <tr>
                <td>400</td>
                <td>Bad Request – Missing or invalid request body</td>
              </tr>
              <tr>
                <td>401</td>
                <td>Unauthorized – The user token is missing</td>
              </tr>
              <tr>
                <td>403</td>
                <td>Forbidden – Exceeded number of datasets / daily images</td>
              </tr>
              <tr>
                <td>404</td>
                <td>Not Found – The requested resource could not be found</td>
              </tr>
              <tr>
                <td>405</td>
                <td>
                  Method Not Allowed – The request method is not supported for the
                  requested resource
                </td>
              </tr>
              <tr>
                <td>408</td>
                <td>Request Timeout – The request has timed out</td>
              </tr>
              <tr>
                <td>413</td>
                <td>Entity Too Large – The request size exceeds the limit</td>
              </tr>
              <tr>
                <td>415</td>
                <td>
                  Unsupported Media Type – The request contains media type that
                  the API does not support
                </td>
              </tr>
              <tr>
                <td>422</td>
                <td>
                  Unprocessable Entity – Invalid token, can't complete authorization
                </td>
              </tr>
              <tr>
                <td>429</td>
                <td>
                  Too Many Requests – Client has reached rate limit for a certain amount of time
                </td>
              </tr>
              <tr>
                <td>500</td>
                <td>
                  Internal Server Error – Server encountered a problem. Try again
                  later
                </td>
              </tr>
              <tr>
                <td>502</td>
                <td>
                  Bad Gateway – Server received invalid response from the upstream
                  server
                </td>
              </tr>
              <tr>
                <td>503</td>
                <td>
                  Service Unavailable – The API is temporary offline. Try again
                  later
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </main>
  </div>

  )
}

export default Errors