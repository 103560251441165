import React from "react";
import { useStore } from "react-context-hook";

const ChangePassword = () => {
  const [langCode, ] = useStore("langCode",'');
  
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
        <div class="content">
          <h3 class="content" id="change_password">
            Change password
          </h3>
          <br />
          <p class="content">
            After we provide you with your user credentials, it’s recommended to change your password. The new password should be a minimum of six characters long.
          </p>

          <div class="content">
            <pre class="mainLink">POST /v2/password/change</pre>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
        </div>

        <div class="content">

          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>old_password</td>
                <td>Current password</td>
              </tr>
              <tr>
                <td>password</td>
                <td>New password</td>
              </tr>
              <tr>
                <td>confirm_password</td>
                <td>Confirm the new password</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h4 class="content unbold">Response properties</h4>
          <br />
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>message</td>
                <td>Confirmation that password has been changed</td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class=" theCode">
          <pre>
          <span class="grayChar"># example request</span><br /><br />
        {langCode== 'js' ? 
          <>
            <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/password/change" , {'{'}<br />
            {'       '}method: "POST",<br />
            {'       '}headers: {'{'}"Content-Type": "application/json",<br />
            {'      '}"Authorization": "Bearer access_token"{'}'}<br />
            {'       '}body: '{'{'}"old_password": "oldpass",<br />
            {'      '} "password": "newpass", "confirm_password": "newpass"{'}'}' <br />
            {'     '}{'}'})<br />
          </>
          : langCode == "python" ?  
          <><span class="grayChar">$ </span>requests.post("https://pva.pixyle.ai/v2/password/change", <br />
            {'       '}data= '{'{'}"old_password": "oldpass", <br />
            {'       '}"password": "newpass", "confirm_password": "newpass"{'}'}', <br />
            {'       '}headers= {'{'}"Content-Type": "application/json", <br />
            {'       '}"Authorization": "Bearer access_token"  {'}'}<br />
            {'     '})<br /></>
          : 
          <>
            <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br/>
            {'       '}-H 'Authorization: Bearer access_token' \<br/>
            {'       '}--data '{'{'}"old_password": "oldpass", \<br/>
            {'       '}"password": "newpass", "confirm_password": "newpass"{'}'}' \<br/>
            {'       '}-X POST https://pva.pixyle.ai/v2/password/change<br/><br/>
          </>
          }
            <br />
            <span class="grayChar"># example JSON response</span><br/><br/>

            <span class="redChar">{'{'}</span><br/>
            {'  '}<span class="orangeChar">"status"</span>: <span class="yellChar">"OK"</span>,<br/>
            {'  '}<span class="orangeChar">"method"</span>: <span class="yellChar">"password/change"</span>,<br/>
            {'  '}<span class="orangeChar">"message"</span>: <span class="yellChar">"Password has been changed"</span>,<br/>
            <span class="redChar">{'}'}</span></pre>
        </div>
      </div>
    </main>
  </div>

  )
}

export default ChangePassword