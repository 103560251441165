import React from "react";
import { useStore } from "react-context-hook";
const ChangePassword = () => {
  const [langCode, ] = useStore("langCode",'');
  
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content">
        <div class="content"><br/>
          <h3 class="content" id="change_password">
            Change password
          </h3>
          <br />
          <p class="content">
            After we provide you with your user credentials, it’s recommended to change your password. The new password should be a minimum of six characters long.
          </p>
          <div class="content">
            <pre class="mainLink">POST /v4/users/password/change</pre>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
        </div>
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>old_password</td>
                <td>Current password</td>
              </tr>
              <tr>
                <td>new_password</td>
                <td>New password</td>
              </tr>
              <tr>
                <td>confirm_new_password</td>
                <td>Confirm the new password</td>
              </tr>
            </tbody>
          </table>
        </div>
        {langCode== 'js' ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/users/password/change"</span>, {'{'}<br />
              {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
              {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>: <span className="txt">"application/json"</span>,<br />
              {'                  '}<span className="txt">"Authorization"</span>: <span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
              {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>({'{'} <span className="txt">"old_password"</span>:<span className="txt"> "oldpass"</span>,<br />
              {'                  '}<span className="txt">"new_password"</span>:<span className="txt"> "newpass"</span>,<span className="txt"> "confirm_new_password"</span>: <span className="txt">"newpass"</span> {'}'}) <br />
              {'     '}{'}'})<br />
            </pre>
          </div> 
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Content-Type"</span>: <span className="txt">"application/json"</span>,<br />
            {'               '}<span className="txt">"Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
            {'  '}data = {'{'} <span className="txt">"old_password"</span>:<span className="txt"> "oldpass"</span>,<br />
            {'           '}<span className="txt">"new_password"</span>: <span className="txt">"newpass"</span>,<span className="txt"> "confirm_new_password"</span>: <span className="txt">"newpass" </span>{'}'}<br />
            {'  '}requests.post( <span className="txt">"https://pva.pixyle.ai/v4/users/password/change"</span>, <br />
            {'       '}<span className="blue">headers</span> = headers, < br/>
            {'       '}<span className="blue">data</span> = json.dumps(data)<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
              {'      '} -H 'Authorization: Bearer access_token' \<br />
              {'      '} --data '{'{'}"old_password": "oldpass", \<br />
              {'      '} "new_password": "newpass", "confirm_new_password": "newpass"{'}'}' \<br />
              {'      '} -X POST https://pva.pixyle.ai/v4/users/password/change
            </pre>
          </div>
          }
      
       
        <h4 class="content unbold">Response properties</h4>
        <br />
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>message</td>
                <td>Confirmation that password has been changed</td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
        <div class=" theCode">
          <pre><span class="grayChar"># example JSON response</span><br /><br />
            <span class="redChar">{'{'}</span><br />
            {'    '}<span class="orangeChar">"status"</span>: <span class="yellChar">"OK"</span>,<br />
            {'    '}<span class="orangeChar">"method"</span>: <span class="yellChar">"password/change"</span>,<br />
            {'    '}<span class="orangeChar">"message"</span>: <span class="yellChar">"Password has been changed"</span><br />
            <span class="redChar">{'}'}</span></pre>
        </div>
      </div>
    </main>
  </div>

  )
}

export default ChangePassword