import React from "react";
import { useStore } from "react-context-hook";

const DeleteProduct = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
      <h3 class="content" id="delete_product">Delete Product</h3>
        <div class="content">
          <div class="content">
            <pre class="mainLink">DELETE /v4/datasets/<i>{'{dataset_id}'}</i>/products/<i>{'{product_id}'}</i></pre>
          </div>
          <p class="content">
            When Pixyle is finished downloading and processing a data file, you can delete a product from it. In that way that dataset will no longer contain that product. If a product exists in multiple solutions and is deleted then it can no longer be used in any of them.
          </p>
          <p class="content">
            After the product is removed, the API returns verification about the process. In case there are no more products in the products category, the category is removed too.
          </p>
        </div>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, where the uploading process is finished</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>A valid id of one of your datasets products, you want to delete</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
             <pre>
               <span class="grayChar"># example request</span><br /><br />
               <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
            {'       '}/products/product2_id"</span>, {'{'}<br />
               {'       '}<span className="blue">method</span>:<span className="txt"> "DELETE"</span>,<br />
               {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
               {'     '}{'}'})<br /><br />
             </pre>
         </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'} <span className="txt">"Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>"</span></span> {'}'}<br />
            {'  '}requests.delete( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
            {'       '}/products/product2_id"</span>, <br />
            {'       '}<span className="blue">headers</span> = headers <br/>
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'      '} -X DELETE https://pva.pixyle.ai/v4/datasets/1570624498775133\<br />
            {'      '} /products/product2_id</pre></div>
          }

        <p class="content">
          Please note that if you upload a data file without categories, Pixyle will change the products ids. You can check the correct id of a product after it’s initialized with any of the solutions.
        </p>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"autotag/product/delete"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: </span> <span class="whiteChar">1570624498775133,</span><br />
              {'  '}"product_id"<span class="whiteChar">: </span><span class="yellChar">"product1_id"</span><span class="whiteChar">,</span><br />
              {'  '}"meta"<span class="whiteChat">: </span><span class="redChar">{'{'}</span><br />
              {'    '}"collection_name"<span class="whiteChar">: </span><span class="yellChar">"images.csv"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span>
            </span>
          </pre>
        </div>
      </div>
    </main>
  </div>

  )
}

export default DeleteProduct