import React from "react";

const IntegratePixyleSolutions = () => {
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
        <h2 class="content" id="integrate_pixyle_solutions">
          Integrate Pixyle solutions
        </h2>
        <h3 class="content" >API Integration</h3>
        <p class="content" id="content">
          You can directly integrate our various solutions using APIs. You can find the detailed API specifications in the next chapters.
        </p><br/><br/><br/>
      </div>
    </main>
  </div>
  )
}

export default IntegratePixyleSolutions;