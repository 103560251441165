import react from "react";
const TaxonomyChanges = () => {
    return (
        <div className="gridContainer">
        <main className="mainContainer">
        <div className=" js-toc-content js-toc-content notes">
           <h2 className="content" id="all_changes">
                All taxonomy changes
            </h2>
            <br/>
            {/* NewCategories */}
            <h3 className="content notes" style={{fontWeight:"600"}}>
                1. New Categories:
            </h3>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;nightwear-top;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;nightwear-bottoms;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;dungarees;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;tunic;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;overclothes-sleeveless;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;loafers;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;hoodie;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;sweater;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;polo-shirt;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;t-shirt;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;tank-top;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;swimwear;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;underwear.
            </li>

            <br/><br/>
            
            {/* 3. Renamed categories: */}
            <h3 className="content notes" style={{fontWeight:"600"}}>
                2. Removed categories:
            </h3>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;brassiere;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;panties.
            </li>
            {/* <li className="content notes li">
                &#x2022; &nbsp;&nbsp;underwear.
            </li> */}
            <br/><br/>
         {/* 3. Renamed categories: */}
            <h3 className="content notes" style={{fontWeight:"600"}}>
                3. Renamed categories:
            </h3>
            <br/>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;trousers to trousers_and_chinos;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;kaftan to kaftan_and_kimono.
            </li>
            <br/><br/>


            {/* 4. New attributes: */}
            <h3 className="content notes" style={{fontWeight:"600"}}>
                4. New attributes:
            </h3>
            <br/>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;mint;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;copper;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;canyon-rose;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;pastel.
            </li>
            <br/>
            {/* 5. Removed attributes: */}
            <h3 class="content notes" style={{fontWeight:"600"}}>
                5. Removed attributes:
            </h3>
            <br/>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;breathable-holes;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;espadrilles.
            </li>
            <br/>
            {/* 6. Renamed attributes: */}

            <h3 className="content notes" style={{fontWeight:"600"}}>
                6. Renamed attributes: 
            </h3>
            <br/>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;underwear-bottoms to panties;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;underwear-top to bra;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;sleeved-top to regular-top;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;romper to above-knee (in bottoms_length group).
            </li>
            <br/>
            {/* 7. Changes in attributes groups: */}
            


            <h3 className="content notes" style={{fontWeight:"600"}}>
                7. Changes in attributes groups:
            </h3>
            <br/>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;skirt_fit renamed to skirt_type;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;bottoms_length renamed to jumpsuit_length at jumpsuit;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;high-neck added to blazer_neckline_type;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;vests, waistcoat, kimono removed from overclothes_type;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;sleeve_length replaced with underarm_sleeve_length at blazer, jacket, coat, suits;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;sleeve_type removed from suits;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;sleeve_length, neckline_type, waist_type added at sportswear;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;tops_type removed from tops;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;tops_sleeve_length replaced with sleeve_length at tops;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;kaftan_type removed;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;running-shorts added to sportswear;
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;swimwear-shorts, sport-shorts, running-shorts removed from shorts_type (regrouped to swimwear_type and sportswear_type);
            </li>
            <li className="content notes li">
                &#x2022; &nbsp;&nbsp;sweater removed from sweatshirt_type (sweater is new category).
            </li>
            <br/><br/><br/>

            </div>
        </main>
    </div>
    )

}
export default TaxonomyChanges