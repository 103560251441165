import React from "react";
import { HashLink } from 'react-router-hash-link';
import { useStore } from "react-context-hook";

const AutomaticTagging = () => {
  const [langCode, ] = useStore("langCode",'');

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br />
        <h2 class="content" id="solutions">
          Solutions
        </h2>
        <h4 class="content">For your business</h4>

        <h3 class="content" id="automatic_tagging">
          2. Automatic Tagging
        </h3>

        <p class="content">
          Pixyle's automatic tagging solution automates and improves the catalogue management process. You can use it by simply uploading your image collection and wait for Pixyle to do the tagging. After a short amount of time you will get a JSON or CSV as a response, filled with accurate and rich tags associated with your images.
        </p>

        <div class="content">
          <pre class="mainLink">GET /v2/autotag/{'{dataset_id}'}</pre>
        </div>

        <p class="content">
          When this endpoint is called, Pixyle starts to tag the specified dataset. There are some limitations on the images in that dataset:
        </p>

        <div class="content">
          <p class="content">- For optimal and accurate results, we recommend images with good
            resolution, therefore images should not be extremely small (50x50)
            or extremely big (bigger than 8192x8192).</p>

        </div>

        <h4 class="content unbold">Request Parameters</h4>

        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your dataset</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/1570624498775133", {'{'}<br />
              {'       '}method: "GET",<br />
              {'       '}headers: {'{'}"Authorization": "Bearer access_token"{'}'}<br />
              {'     '}{'}'})<br /> <br/>
          </pre>
        </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.get("https://pva.pixyle.ai/v2/autotag/1570624498775133",<br/>
            {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />

            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v2/autotag/1570624498775133</pre>
            
        </div>
          }
        
        
        <br />
        <h4 class="content unbold">Response properties</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>user</td>
              <td>The user that made the request</td>
            </tr>
            <tr>
              <td>start_time</td>
              <td>Time when the auto tagging has started</td>
            </tr>

          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"autotag"</span><br />
              {'  '}"message"<span class="whiteChar">:</span> <span class="yellChar">"Automatic tagging has started, please check status of tagging process on /v3/autotag/status/{'{dataset_id}'}</span><br />
              {'  '}"meta"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'    '}"user"<span class="whiteChar">:</span> <span class="yellChar">"FrontEnd"</span><span class="whiteChar">,</span><br />
              {'    '}"start_time"<span class="whiteChar">: </span> <span class="yellChar">"Tue, 27 Dec 2019 09:23:36"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <p class="content">
          The user will get this response immediately, and in the backend Pixyle starts tagging the dataset. Depending on the number of images, this may take some time, so you can check the autotag status on the following endpoint.
        </p>
        <div class="content">
          <pre class="mainLink">GET /v2/autotag/status/{'{dataset_id}'}</pre>
        </div>

        <h4 class="content unbold">Request Parameters</h4>
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A valid id of one of your dataset</td>
              </tr>
            </tbody>
          </table>
        </div>
        {langCode== 'js' ? 
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span><br /><br />
                  <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/status/1570624498775133" , {'{'}<br />
                  {'       '}method: "GET",<br />
                  {'       '}headers: {'{'}"Authorization": "Bearer access_token"{'}'}<br />
                  {'     '}{'}'})<br /> <br/>
              </pre>
            </div>
          : langCode == "python" ?  
              <div class=" theCode">
                <pre><span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span>requests.get("https://pva.pixyle.ai/v2/autotag/status/1570624498775133",<br/>
                {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
                {'     '})<br />
                </pre>
              </div>
          :
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
                {'       '}-X GET https://pva.pixyle.ai/v2/autotag/status/1570624498775133</pre>
            </div> 
          }
        <p class="content">
          Pixyle will return the amount and percentage state of tagged products at the time the request has been made.          </p>
        <br />
        <h4 class="content unbold">Response properties</h4>
        <br />
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>An id of the dataset which is being tagged</td>
              </tr>
              <tr>
                <td>number_of_products</td>
                <td>Total number of products</td>
              </tr>
              <tr>
                <td>tagged_products</td>
                <td>Number of products successfully tagged</td>
              </tr>
              <tr>
                <td>treated_images_percentage</td>
                <td>
                  Percentage value of the amount of treated image, over the
                  total number of images.
                </td>
              </tr>
              <tr>
                <td>dataset_count</td>
                <td>Number of datasets by the user</td>
              </tr>
              <tr>
                <td>collection_name</td>
                <td>Name of the collection</td>
              </tr>
              <tr>
                <td>verified_products_percentage</td>
                <td>Percentage value of the amount of validated products, over the total number of products</td>
              </tr>
            </tbody>
          </table>

          <p class="content">
            When Pixyle finishes with the autotag process, you can get the general
            result about the categories in this dataset, on both of these
            endpoints. You can get the autotag result, only after autotag
            process is finished. The JSON format is the same.
          </p>
        </div>

        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"autotag/status"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">:</span> <span class="whiteChar">1570624498775133</span><span class="whiteChar">,</span><br />
              {'  '}"meta"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'    '}"number_of_products"<span class="whiteChar">:</span><span class="whiteChar"> 1000</span><span class="whiteChar">,</span><br />
              {'    '}"tagged_products"<span class="whiteChar">:</span><span class="whiteChar"> 100</span><span class="whiteChar">,</span><br />
              {'    '}"treated_images_percentage"<span class="whiteChar">:</span><span class="whiteChar"> 10.0</span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_count"<span class="whiteChar">:</span> <span class="whiteChar"> 2,</span><br />
              {'  '}"collection_name"<span class="whiteChar">:</span> <span class="yellChar">"images.csv"</span><span class="whiteChar">,</span><br />
              {'  '}"verified_products_percentage"<span class="whiteChar">:</span> <span class="whiteChar"> 0.0</span><br />
              <span class="redChar">{'}'}</span></span></pre></div>
        <br />
        <h4 class="content unbold">Response properties</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>An id of the dataset which is tagged</td>
            </tr>
            <tr>
              <td>no_detections</td>
              <td>
                A list of objects containing information about images where
                Pixyle did not detect anything
              </td>
            </tr>
            <tr>
              <td>errors</td>
              <td>
                A list of objects containing information about failed images
              </td>
            </tr>
            <tr>
              <td>detected_categories</td>
              <td>
                An object that contains all detected categories from the dataset. Each object consists of corresponding category and their products
              </td>
            </tr>
            <tr>
              <td>category</td>
              <td>
                An object that contains products and information for a category
              </td>
            </tr>
            <tr>
              <td>products</td>
              <td>
                An object that contains all products from the category
              </td>
            </tr>
            <tr>
              <td>number_of_products</td>
              <td>Number of products in a specific category</td>
            </tr>
            <tr>
              <td>verified_products</td>
              <td>Number of verified products in a specific category</td>
            </tr>
            <tr>
              <td>verified</td>
              <td>Shows if a product is verified</td>
            </tr>
            <tr>
              <td>number_of_products_all</td>
              <td>Total number of products in a dataset</td>
            </tr>
            <tr>
              <td>verified_products_all</td>
              <td>Total number of verified products in a dataset</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"autotag/status"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">:</span> <span class="whiteChar">1570624498775133</span><span class="whiteChar">,</span><br />
              {'  '}"result":<span class="redChar">{'{'}</span><br />
              {'    '}"no_detections":<span class="redChar">[]</span><span class="whiteChar">,</span><br />
              {'    '}"errors": <span class="redChar">[</span><span class="whiteChar">...</span><span class="redChar">]</span><br />
              {'    '}"detected_categories":<span class="redChar">{'{'}</span><br />
              {'      '}"skirts":<span class="redChar">{'{'}</span><br />
              {'        '}"products":<span class="redChar">{'{'}</span><br />
              {'          '}"product1_id":<span class="redChar">{'{'}</span><br />
              {'            '}"category": <span class="yellChar">"skirts"</span><span class="whiteChar">,</span><br />
              {'            '}"primary_image":<span class="yellChar">"https://image1.jpg"</span><span class="whiteChar">,</span><br />
              {'            '}"extra_images": <span class="redChar">[</span><span class="whiteChar">...</span><span class="redChar">]</span><span class="whiteChar">,</span><br />
              {'            '}"verified": <span class="yellChar">false</span><br />
              {'          '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'          '}<span class="redChar">...</span><br />
              {'        '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'        '}"number_of_products":<span class="whiteChar"> 70,</span><br />
              {'        '}"verified_products":<span class="whiteChar"> 0</span><br />
              {'      '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"jeans":<span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><br />
              {'      '}<span class="redChar">{'}'}</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}"number_of_products_all":<span class="whiteChar"> 998,</span><br />
              {'    '}"verified_products_all":<span class="whiteChar"> 0</span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}"meta"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'    '}"number_of_products"<span class="whiteChar">:</span><span class="whiteChar"> 1000</span><span class="whiteChar">,</span><br />
              {'    '}"tagged_products"<span class="whiteChar">:</span><span class="whiteChar"> 998</span><span class="whiteChar">,</span><br />
              {'    '}"treated_images_percentage"<span class="whiteChar">:</span><span class="whiteChar"> 100.0</span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_count"<span class="whiteChar">:</span> <span class="whiteChar"> 2,</span><br />
              {'  '}"collection_name"<span class="whiteChar">:</span> <span class="yellChar">"images.csv"</span><span class="whiteChar">,</span><br />
              {'  '}"verified_products_percentage"<span class="whiteChar">:</span> <span class="whiteChar"> 0.0</span><br />
              <span class="redChar">{'}'}</span></span></pre></div>
        <div class="content">

          <HashLink to={'/v2-documentation#automatic-tagging_get_categories'}><h4 class="content" id='automatic-tagging_get_categories'>Get Categories</h4></HashLink>

          <pre class="mainLink" style={{ paddingRight: '10px' }}>GET /v2/autotag/{'{dataset_id}'}/categories</pre>
          <p class="content">
            Тo make the process easier to manage, you can get access to all detected categories on this endpoint. Later, this can help you to see tags for each category and it’s products.
          </p>
        </div>

        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your datasets</td>
            </tr>

          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/1570624498775133/categories" , {'{'}<br />
              {'       '}method: "GET",<br />
              {'       '}headers: {'{'}"Authorization": "Bearer access_token"{'}'}<br />
              {'     '}{'}'})<br /> <br/>
          </pre>
        </div>
        : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.get("https://pva.pixyle.ai/v2/autotag/1570624498775133/categories",<br/>
            {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
            {'     '})<br />
            </pre>
          </div>
        : 
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v2/autotag/1570624498775133/categories</pre>
        </div>
        }
        <h4 class="content unbold">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>detected_categories</td>
              <td>An object that contains all detected categories from the dataset. Each object consists of a corresponding category and it's products</td>
            </tr>
            <tr>
              <td>category</td>
              <td>An object that contains information for a category</td>
            </tr>
            <tr>
              <td>products</td>
              <td>An object that contains a single product from a category</td>
            </tr>
            <tr>
              <td>primary_image</td>
              <td>Image that represents the category</td>
            </tr>
            <tr>
              <td>number_of_products</td>
              <td>Number of products in a specific category</td>
            </tr>
            <tr>
              <td>verified_products</td>
              <td>Number of verified products in a specific category</td>
            </tr>
            <tr>
              <td>collection_name</td>
              <td>The original name of the collection</td>
            </tr>
            <tr>
              <td>verified_products_percentage</td>
              <td>Percentage value of the amount of validated products, over the total number of products</td>
            </tr>
          </tbody>
        </table>

        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"detected_categories":<span class="redChar">{'{'}</span><br />
              {'    '}"skirts":<span class="redChar">{'{'}</span><br />
              {'      '}"products":<span class="redChar">{'{'}</span><br />
              {'        '}"product1_id":<span class="redChar">{'{'}</span><br />
              {'          '}"category": <span class="yellChar">"skirts"</span><span class="whiteChar">,</span><br />
              {'          '}"primary_image":<span class="yellChar">"https://image1.jpg"</span><span class="whiteChar">,</span><br />
              {'        '}<span class="redChar">{'}'}</span><br />
              {'      '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"number_of_products":<span class="whiteChar"> 70,</span><br />
              {'      '}"verified_products":<span class="whiteChar"> 0</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}"jeans":<span class="redChar">{'{'}</span><br />
              {'      '}"products":<span class="redChar">{'{'}</span><br />
              {'        '}"product1_id":<span class="redChar">{'{'}</span><br />
              {'          '}"category": <span class="yellChar">"jeans"</span><span class="whiteChar">,</span><br />
              {'          '}"primary_image":<span class="yellChar">"https://image2.jpg"</span><span class="whiteChar">,</span><br />
              {'        '}<span class="redChar">{'}'}</span><br />
              {'      '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"number_of_products":<span class="whiteChar"> 150,</span><br />
              {'      '}"verified_products":<span class="whiteChar"> 0</span><br />
              {'    '}<span class="redChar">{'}'}</span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}"collection_name"<span class="whiteChar">:</span> <span class="yellChar">"images.csv"</span><span class="whiteChar">,</span><br />
              {'  '}"verified_products_percentage"<span class="whiteChar">:</span> <span class="whiteChar"> 0.0</span><br />
              <span class="redChar">{'}'}</span></span></pre></div>



        <div class="content">

          <HashLink to={'/v2-documentation#automatic-tagging_get_category_products'}><h4 class="content" id='automatic-tagging_get_category_products'>Get Category Products</h4></HashLink>

          <pre class="mainLink" style={{ paddingRight: "10px" }}>GET /v2/autotag/{'{dataset_id}'}/categories/{'{category}'}/products</pre>
          <p class="content">
            You can get access to all detected products in a specific category on this endpoint.
          </p>
        </div>

        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your datasets</td>
            </tr>
            <tr>
              <td>category</td>
              <td>A valid category</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/1570624498775133<br/>
              {'       '}/categories/jeans/products" , {'{'}<br />
              {'       '}method: "GET",<br />
              {'       '}headers: {'{'}"Authorization": "Bearer access_token"{'}'}<br />
              {'     '}{'}'})<br /> <br/>
          </pre>
        </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.get("https://pva.pixyle.ai/v2/autotag/1570624498775133<br/>
            {'       '}/categories/jeans/products",<br/>
            {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v2/autotag/1570624498775133/categories/jeans/products</pre>
        </div>
          }
        
        
        <h4 class="content unbold">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>products</td>
              <td>An object that contains all products from the category</td>
            </tr>
            <tr>
              <td>primary_image</td>
              <td>Primary image of the product</td>
            </tr>
            <tr>
              <td>verified</td>
              <td>Boolean field that represents if product is verified or not</td>
            </tr>
            <tr>
              <td>verified_products</td>
              <td>Number of verified products in a specific category</td>
            </tr>
            <tr>
              <td>number_of_products</td>
              <td>Number of products in a specific category</td>
            </tr>
          </tbody>
        </table>

        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"products":<span class="redChar">{'{'}</span><br />
              {'    '}"product1_id":<span class="redChar">{'{'}</span><br />
              {'      '}"category": <span class="yellChar">"jeans"</span><span class="whiteChar">,</span><br />
              {'      '}"primary_image":<span class="yellChar">"https://image2.jpg"</span><span class="whiteChar">,</span><br />
              {'      '}"verified": <span class="yellChar">false</span><br />
              {'      '}<span class="redChar">{'}'}</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}<span class="yellChar">...</span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}"number_of_products":<span class="whiteChar"> 150,</span><br />
              {'  '}"verified_products":<span class="whiteChar"> 0,</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method<span class="whiteChar">:</span> <span class="yellChar">"autotag/category"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id:<span class="whiteChar"> 1570624498775133</span><br />
              <span class="redChar">{'}'}</span></span></pre></div>

        <div class="content">

          <HashLink to={'/v2-documentation#automatic-tagging_get_product_tags'}><h4 class="content" id='automatic-tagging_get_product_tags'>Get Product Tags</h4></HashLink>


          <pre class="mainLink" style={{ paddingRight: "10px" }}>GET /v2/autotag/{'{dataset_id}'}/products/{'{product_id}'}</pre>
        </div>

        <p class="content">
          After the auto tagging is finished, you can use this endpoint to get detected tags for one product.
        </p>

        <h4 class="content unbold">Request Parameters</h4>

        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your dataset</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>
                A valid id of one of dataset’s products, you want to get tags
                for
              </td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
            <div class=" theCode">
            <pre>
              <span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/1570624498775133/products/product1_id", {'{'}<br />
                {'       '}method: "GET",<br />
                {'       '}headers: {'{'}"Authorization": "Bearer access_token"{'}'}<br />
                {'     '}{'}'})<br /> <br/>
            </pre>
          </div>
          : langCode == "python" ?  
            <div class=" theCode">
              <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>requests.get('https://pva.pixyle.ai/v2/autotag/1570624498775133<br/>
              {'       '}/products/product1_id',<br/>
              {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
              {'     '})<br />
              </pre>
            </div>
          : 
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
              {'       '}-X GET https://pva.pixyle.ai/v2/autotag/1570624498775133/products/product1_id</pre>
          </div>
          }
        
      
        <br />
        <h4 class="content unbold">Response properties</h4>
        <br />
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>category</td>
                <td>Category of the product</td>
              </tr>
              <tr>
                <td>attributes</td>
                <td>Object that contains all detected attributes for this product</td>
              </tr>
              <tr>
                <td>attribute_type_name</td>
                <td>
                  Category specific attribute type
                </td>
              </tr>
              <tr>
                <td>attribute_name</td>
                <td>Name of attribute as key, and the confidence score as value</td>
              </tr>
              <tr>
                <td>category_probability</td>
                <td>Probability score of the detected category</td>
              </tr>
              <tr>
                <td>product_coordinates</td>
                <td>Coordinates of the box around the detected product</td>
              </tr>
              <tr>
                <td>verified</td>
                <td>Boolean value, shows if the product is verified</td>
              </tr>

            </tbody>
          </table>


        </div>

        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"autotag/products/get"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">:</span> <span class="whiteChar">1570624498775133,</span><br />
              {'  '}"result"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'    '}"category"<span class="whiteChar">:</span> <span class="yellChar">"jeans"</span><br />
              {'    '}"attributes"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'      '}"waist"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'        '}"correct"<span class="whiteChar">:</span> <span class="redChar">[</span><br />
              {'          '}<span class="redChar">{'{'}</span><br />
              {'            '}"high-waist"<span class="whiteChar">:</span> <span class="whiteChar">0.4818</span><br />
              {'          '}<span class="redChar">{'}'}</span><br />
              {'        '}<span class="redChar">]</span><span class="whiteChar">,</span><br />
              {'        '}"others"<span class="whiteChar">:</span> <span class="redChar">[</span><br />
              {'          '}<span class="redChar">{'{'}</span><br />
              {'            '}"mid-waist"<span class="whiteChar">:</span> <span class="whiteChar">0.1049</span><br />
              {'          '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'          '}<span class="redChar">{'{'}</span><br />
              {'            '}"low-waist"<span class="whiteChar">:</span> <span class="whiteChar">0.0054</span><br />
              {'          '}<span class="redChar">{'}'}</span><br />
              {'        '}<span class="redChar">]</span><br />
              {'      '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"bottoms-length"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'        '}"correct"<span class="whiteChar">:</span> <span class="redChar">[</span><br />
              {'          '}<span class="redChar">{'{'}</span><br />
              {'            '}"full-length"<span class="whiteChar">:</span> <span class="whiteChar">0.3624</span><br />
              {'          '}<span class="redChar">{'}'}</span><br />
              {'        '}<span class="redChar">]</span><span class="whiteChar">,</span><br />
              {'        '}"others"<span class="whiteChar">:</span> <span class="redChar">[</span><br />
              {'          '}<span class="redChar">{'{'}</span><br />
              {'            '}"3/4-length"<span class="whiteChar">:</span> <span class="whiteChar">0.0977</span><br />
              {'          '}<span class="redChar">{'}'}</span><br />
              {'          '}<span class="whiteChar">...</span><br />
              {'        '}<span class="redChar">]</span><br />
              {'      '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"fit"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"wash"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"color"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'        '}"correct"<span class="whiteChar">:</span> <span class="redChar">[</span><br />
              {'          '}<span class="redChar">{'{'}</span><br />
              {'            '}"gray"<span class="whiteChar">:</span> <span class="whiteChar">0.6041,</span><br />
              {'          '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'          '}<span class="redChar">{'{'}</span><br />
              {'            '}"white"<span class="whiteChar">:</span> <span class="whiteChar">0.4720</span><br />
              {'          '}<span class="redChar">{'}'}</span><br />
              {'        '}<span class="redChar">]</span><span class="whiteChar">,</span><br />
              {'        '}"others"<span class="whiteChar">:</span> <span class="redChar">[</span><span class="whiteChar">...</span><span class="redChar">]</span><br />
              {'      '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"pattern"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"material"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"more_attributes"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}<span class="redChar">{'}'}</span><br />
              {'    '}"category_probability"<span class="whiteChar">: 0.92,</span><br />
              {'    '}"product_coordinates"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'      '}"x_min"<span class="whiteChar">: 516.9120483398438,</span><br />
              {'      '}"y_min"<span class="whiteChar">: 569.861083984375,</span><br />
              {'      '}"x_max"<span class="whiteChar">: 1063.6641845703125,</span><br />
              {'      '}"y_max"<span class="whiteChar">: 1695.62939453125</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}"primary_image"<span class="whiteChar">:</span><span class="yellChar"> "https://image2.jpg"</span><span class="whiteChar">,</span><br />
              {'    '}"extra_images"<span class="whiteChar">:</span> <span class="redChar">[</span><span class="whiteChar">...</span><span class="redChar">]</span><br />
              {'    '}"verified"<span class="whiteChar">:</span> <span class="yellChar">false</span><span class="whiteChar">,</span><br />
              {'    '}"product_id"<span class="whiteChar">:</span><span class="yellChar">"product2_id"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span>
            </span></pre></div>

        <div class="content">
          <HashLink to={'/v2-documentation#automatic-tagging_update_validate_product'}><h4 class="content" id="automatic-tagging_update_validate_product">
            Update/Validate Product
          </h4></HashLink>
        </div>
        <div class="content">
          <pre class="mainLink">PUT /v2/autotag/update</pre>
        </div>
        <p class="content">
          This endpoint can be used to validate or update your products. During the automatic tagging, some tags can be mistaken, so validation is good to do.
        </p>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid dataset_id</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>A valid product_id to be validated</td>
            </tr>
            <tr>
              <td>category</td>
              <td>The category of the product, which is validated</td>
            </tr>
            <tr>
              <td>attributes</td>
              <td>An object that contains corrected attributes. Or an empty object if no update is needed</td>
            </tr>
            <tr>
              <td>verified</td>
              <td>Boolean field, shows if the product is verified</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
             <div class=" theCode">
              <pre><span class="grayChar"># example request without changes</span><br /><br />
                <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/update",{'{'}<br />
                {'       '}method: "PUT",<br />
                {'       '}headers: {'{'}"Content-Type": "application/json",<br />
                {'       '}"Authorization": "Bearer access_token"{'}'}<br />
                {'       '}body:'{'{'}"dataset_id": 1570624498775133, "product_id": "product2_id", \<br />
                {'       '}"category": "jeans", "attributes": {'{}'}, "verified": true{'}'}'<br />
                {'     '}{'}'})
              </pre>
              <br/>
              <pre>
               <span class="grayChar"># example request with changes</span><br /><br />
                <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/update",{'{'}<br />
                {'       '}method: "PUT",<br />
                {'       '}headers: {'{'}"Content-Type": "application/json",<br />
                {'       '}"Authorization": "Bearer access_token"{'}'}<br />
                {'       '}body:'{'{'}"dataset_id": 1577455993004142, "product_id": "product2_id",<br />
                {'       '}"category": "jeans", "attributes": {'{'}"rise": <br />
                {'       '}{'{'}"correct": [{'{'}"mid_rise": 0.14135{'}'}], <br />
                {'       '}"other": [{'{'}"high_rise": 0.21002, "low_rise": 0.02111{'}'}] {'}}'}, \<br />
                {'       '}"verified": true{'}'}'<br />
                {'     '}{'}'})<br />
              </pre>
            </div>
          : langCode == "python" ?  
             <div class=" theCode"><pre>
              <span class="grayChar"># example request without changes</span><br /><br />
              <span class="grayChar">$ </span>
                files =json.dumps({'{'}"dataset_id": 1570624498775133, "product_id": "product2_id",<br />
                {'       '}"category": "jeans", "attributes": {'{}'}, "verified": true{'}'})<br />
                {'       '}response = requests.put<br/>
                {'       '}('https://pva.pixyle.ai/v2/autotag/update',<br/>
                {'       '}headers= {'{'}"Content-Type": "application/json", <br />
                {'       '}"Authorization": "Bearer access_token"{'}'}, <br />
                {'       '}data=files<br/>
                {'      '})<br />
                <span class="grayChar"># example request with changes</span><br /><br />
              <span class="grayChar">$ </span>
                files =json.dumps({'{'}"dataset_id": 1577455993004142, "product_id": "product2_id",<br />
                {'       '}"category": "jeans", "attributes": {'{'}"rise": <br />
                {'       '}{'{'}"correct": [{'{'}"mid_rise": 0.14135{'}'}], <br />
                {'       '}"other": [{'{'}"high_rise": 0.21002, "low_rise": 0.02111{'}'}] {'}}'},<br />
                {'       '}"verified": true{'}'})<br />
                {'       '}response = requests.put<br/>
                {'       '}('https://pva.pixyle.ai/v2/autotag/update',<br/>
                {'       '}headers= {'{'}"Content-Type": "application/json", <br />
                {'       '}"Authorization": "Bearer access_token"{'}'}, <br />
                {'       '}data=files<br/>
                {'      '}) <br />
              </pre>
            </div>   
          : <>
          <div class=" theCode">
          <pre>
          <span class="grayChar"># example request without changes</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
            {'       '}-H 'Authorization: Bearer access_token' \<br />
            {'       '}--data '{'{'}"dataset_id": 1570624498775133, "product_id": "product2_id", \<br />
            {'       '}"category": "jeans", "attributes": {'{}'}, "verified": true{'}'}' \<br />
            {'       '}-X PUT https://pva.pixyle.ai/v2/autotag/update </pre> <br /> 
            <pre> <span class="grayChar"># example request with changes</span><br /><br />
            
           < span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
            {'       '}-H 'Authorization: Bearer access_token' \<br />
            {'       '}--data '{'{'}"dataset_id": 1577455993004142, "product_id": "product2_id", \<br />
            {'       '}"category": "jeans", "attributes": {'{'}"rise": \<br />
            {'       '}{'{'}"correct": [{'{'}"mid_rise": 0.14135{'}'}], \ <br />
            {'       '}"other": [{'{'}"high_rise": 0.21002, "low_rise": 0.02111{'}'}] {'}}'}, \<br />
            {'       '}"verified": true{'}'}' \<br />
            {'       '}-X PUT https://pva.pixyle.ai/v2/autotag/update </pre>
        </div></>
          }
      
        <p class="content">
          After the product is updated, the API returns the next product for verification of the same category in the response. In case there are no more products to be verified from that category, the next_product field will be empty.
        </p>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response if there are unverified products</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span> <br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"autotag/update"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">:</span> <span class="whiteChar">1570624498775133,</span><br />
              {'  '}"next_product"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'    '}"category"<span class="whiteChar">:</span> <span class="yellChar">"jeans"</span><br />
              {'    '}"attributes"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'      '}<span class="yellChar">...</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}"category_probability"<span class="whiteChar">: 0.92,</span><br />
              {'    '}"product_coordinates"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><br />
              {'    '}"primary_image"<span class="whiteChar">:</span><span class="yellChar">"https://image2.jpg"</span><span class="whiteChar">,</span><br />
              {'    '}"extra_images"<span class="whiteChar">:</span> <span class="redChar">[</span><span class="whiteChar">...</span><span class="redChar">]</span><br />
              {'    '}"verified"<span class="whiteChar">:</span> <span class="yellChar">false</span><span class="whiteChar">,</span><br />
              {'    '}"product_id"<span class="whiteChar">:</span><span class="yellChar">"product3_id"</span><span class="whiteChar">,</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span><br /><br />

              <span class="grayChar"># example JSON response if there are no more unverified products</span><br /><br />
              <span class="orangeChar">
                <span class="redChar">{'{'}</span> <br />
                {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
                {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"autotag/update"</span><span class="whiteChar">,</span><br />
                {'  '}"dataset_id"<span class="whiteChar">:</span> <span class="whiteChar">1570624498775133,</span><br />
                {'  '}"result"<span class="whiteChar">:</span> <span class="redChar">{'{}'}</span><br />
                <span class="redChar">{'}'}</span></span></span></pre>
        </div>
        <div class="content">
          <HashLink to={'/v2-documentation#automatic-tagging_remove_product'}><h4 class="content" id="automatic-tagging_remove_product">
            Remove Product
          </h4></HashLink>
        </div>
        <div class="content">
          <pre class="mainLink">DELETE /v2/autotag/{'{dataset_id}'}/categories/{'{category}'}/products/{'{product_id}'}</pre>
        </div>
        <p class="content">
          When validating the products, you can remove a product from a category. In that way that category will no longer contain that product.
        </p>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your datasets</td>
            </tr>
            <tr>
              <td>category</td>
              <td>Category of the product</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>A valid product_id</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/1570624498775133<br/>
              {'       '}/categories/jeans/products/product2_id" , {'{'}<br />
              {'       '}method: "DELETE",<br />
              {'       '}headers: {'{'}"Authorization": "Bearer access_token"{'}'}<br />
              {'     '}{'}'})<br /> <br/>
          </pre>
        </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.delete("https://pva.pixyle.ai/v2/autotag/1570624498775133<br/>
            {'       '}/categories/jeans/products/product2_id',<br/>
            {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X DELETE https://pva.pixyle.ai/v2/autotag/1570624498775133/categories/jeans/products/product2_id</pre>
        </div>
          }
        <p class="content">
          After the product is removed, the API returns verification about the process. In case there are no more products in the category, the category is removed too.
        </p>

        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span> <br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"autotag/product/delete"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">:</span> <span class="whiteChar">1570624498775133,</span><br />
              {'  '}"result"<span class="whiteChar">:</span> <span class="yellChar"> true</span><span class="whiteChar">,</span><br />
              {'  '}"product_id"<span class="whiteChar">:</span><span class="yellChar">"product1_id"</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <div class="content">
          <HashLink to={'/v2-documentation#automatic-tagging_tag-one-image'}><h4 class="content" id="automatic-tagging_tag-one-image">
            Tag One Image
          </h4></HashLink>
        </div>
        <div class="content">
          <pre class="mainLink">POST /v2/autotag/image</pre>
        </div>
        <p class="content">
          Pixyle also offers tagging of only one image. In this case, there is no need for you to create a dataset. You can simply upload a photo on this endpoint, and you will get the tags as a response.
        </p>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>image</td>
              <td>A valid image file or a valid image URL as string</td>
            </tr>
          </tbody>
        </table>

        {langCode== 'js' ? 
           <div class=" theCode">
           <pre>
           <span class="grayChar"># example request without changes</span><br /><br />
             <span class="grayChar">$ </span> let files = '@image1.jpg'<br />
             {'       '}const formData = new FormData();<br />
             {'       '}formData.append("image", files);<br />
             {'       '}fetch("https://pva.pixyle.ai/v2/autotag/image", {'{'}<br />
             {'       '}method: "POST",<br />
             {'       '}headers: {'{'}"Content-Type": "multipart/form-data"<br />
             {'       '}"Authorization": "Bearer access_token" {'}'}<br />
             {'       '}body: formData <br />
             {'     '}{'}'})<br />
          
           <br />
           <span class="grayChar"># example request with changes</span><br /><br />
           <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/image", {'{'}<br />
             {'       '}method: "POST",<br />
             {'       '}headers: {'{'}"Content-Type": "multipart/form-data"<br />
             {'       '}"Authorization": "Bearer access_token" {'}'}<br />
             {'       '}body:JSON.stringify({'{'}'https://store.image1.jpg'{'}'})<br/>
             {'     '}{'}'})<br />
             </pre>  
           </div> : langCode == "python" ?  
          <div class=" theCode">
          <pre><span class="grayChar"># example request with image file</span><br /><br />
          <span class="grayChar">$ </span>
          files = '@image1.jpg'<br />
          {'       '}response = requests.post<br/>
          {'       '}('https://pva.pixyle.ai/v2/autotag/image',<br />
          {'       '}headers= {'{'}"Content-Type": "multipart/form-data", <br />
          {'       '}"Authorization": "Bearer access_token"{'}'}, <br />
          {'       '}files=files<br/>
          {'      '}) <br /><br />
          <span class="grayChar"># example request with changes</span><br /><br />
          <span class="grayChar">$ </span>
          files =json.dumps({'{'}'image':'https://store.image1.jpg'{'}'})<br />
          {'       '}response = requests.post<br/>
          {'       '}('https://pva.pixyle.ai/v2/autotag/image',<br/>
          {'       '}headers= {'{'}"Content-Type": "multipart/form-data", <br />
          {'       '}"Authorization": "Bearer access_token"{'}'}, <br />
          {'       '}files=files<br/>
          {'      '}) <br />
          </pre>
        </div>
          : 
          <div class=" theCode">
          <pre>
          <span class="grayChar"># example request without changes</span><br /><br />
          <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
            {'       '}-H 'Authorization: Bearer access_token' \<br />
            {'       '}-F 'image=@image1.jpg' \<br />
            {'       '}-X POST https://pva.pixyle.ai/v2/autotag/image<br /><br />

            <span class="grayChar"># example request with changes</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
            {'       '}-H 'Authorization: Bearer access_token' \<br />
            {'       '}-F 'image=https://store.image1.jpg' \<br />
            {'       '}-X POST https://pva.pixyle.ai/v2/autotag/image</pre><br />
        </div>
          }
          
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>result</td>
              <td>
                An array of detected objects
              </td>
            </tr>
            <tr>
              <td>category</td>
              <td>
                Category of the detected fashion item
              </td>
            </tr>
            <tr>
              <td>score</td>
              <td>
                Prediction score of the fashion item being detected
              </td>
            </tr>
            <tr>
              <td>coordinates</td>
              <td>Detected item coordinates (top-left corner and bottom-right)</td>
            </tr>
            <tr>
              <td>attributes</td>
              <td>An array of the detected attributes for that category</td>
            </tr>
            <tr>
              <td>attribute_type</td>
              <td>Category specific attribute type
              </td>
            </tr>
            <tr>
              <td>attribute</td>
              <td>
                Name of the detected attribute
              </td>
            </tr>
            <tr>
              <td>category_count</td>
              <td>
                Number of detected items in image
              </td>
            </tr>
          </tbody>
        </table>

        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span> <br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"autotag/image"</span><span class="whiteChar">,</span><br />
              {'  '}"result"<span class="whiteChar">:</span> <span class="redChar">[</span> <br />
              {'    '}<span class="redChar">{'{'}</span> <br />
              {'      '}"category"<span class="whiteChar">:</span> <span class="yellChar">"jeans"</span><span class="whiteChar">,</span><br />
              {'      '}"score"<span class="whiteChar">: 0.802</span><span class="whiteChar">,</span><br />
              {'      '}"coordinates"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'        '}"x_min"<span class="whiteChar">: 516,</span><br />
              {'        '}"y_min"<span class="whiteChar">: 569,</span><br />
              {'        '}"x_max"<span class="whiteChar">: 1063,</span><br />
              {'        '}"y_max"<span class="whiteChar">: 1695</span><br />
              {'      '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}"attributes"<span class="whiteChar">:</span> <span class="redChar">[</span><br />
              {'        '}<span class="redChar">{'{'}</span> <br />
              {'          '}"attribute_type"<span class="whiteChar">:</span><span class="yellChar"> "waist_type"</span><span class="whiteChar">,</span><br />
              {'          '}"attribute"<span class="whiteChar">:</span><span class="yellChar"> "high-waist"</span><span class="whiteChar">,</span><br />
              {'          '}"score"<span class="whiteChar">: 0.482</span><br />
              {'        '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'        '}<span class="redChar">{'{'}</span> <br />
              {'          '}"attribute_type"<span class="whiteChar">:</span><span class="yellChar"> "bottoms_length"</span><span class="whiteChar">,</span><br />
              {'          '}"attribute"<span class="whiteChar">:</span><span class="yellChar"> "full-length"</span><span class="whiteChar">,</span><br />
              {'          '}"score"<span class="whiteChar">: 0.362</span><br />
              {'        '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'        '}<span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'        '}<span class="redChar">{'{'}</span> <br />
              {'          '}"attribute_type"<span class="whiteChar">:</span><span class="yellChar"> "material"</span><span class="whiteChar">,</span><br />
              {'          '}"attribute"<span class="whiteChar">:</span><span class="yellChar"> "denim"</span><span class="whiteChar">,</span><br />
              {'          '}"score"<span class="whiteChar">: 0.547</span><br />
              {'        '}<span class="redChar">{'}'}</span><br />
              {'      '}<span class="redChar">]</span><br />
              {'    '}<span class="redChar">{'}'}</span><br />
              {'  '}<span class="redChar">]</span><span class="whiteChar">,</span><br />
              {'  '}"meta"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br />
              {'    '}"category_count"<span class="whiteChar">: 1</span>   <br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <div class="content">
          <HashLink to={'/v2-documentation#automatic-tagging_download_file'}><h4 class="content" id="automatic-tagging_download_file">
            Download File
          </h4></HashLink>
          <pre class="mainLink">GET /v2/autotag/{'{dataset_id}'}/files/{'{file_type}'}</pre>
        </div>
        <p class="content">
          Taking into account the number of products in a dataset, the best way for you to get detected tags for each one of them is to use this endpoint. The response you will get here is a file, which can be either JSON or CSV.
        </p>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your datasets</td>
            </tr>
            <tr>
              <td>file_type</td>
              <td>String that represents the type of file you want to get the results in</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre>
              <span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/1570624498775133/files/csv" , {'{'}<br />
                {'       '}method: "GET",<br />
                {'       '}headers: {'{'}"Authorization": "Bearer access_token"{'}'}<br />
                {'     '}{'}'})<br /> <br/>
            </pre>
          </div>
          : langCode == "python" ?  
            <div class=" theCode">
              <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>requests.get('https://pva.pixyle.ai/v2/autotag/1570624498775133/files/csv',<br/>
              {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
              {'     '})<br />
              </pre>
            </div>
          :
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
              {'       '}-X GET https://pva.pixyle.ai/v2/autotag/1570624498775133/files/csv</pre>
          </div> 
          }
        
        <p class="content" style={{ marginBottom: '50px' }}>You can download the file from the response.</p><br /><br />
      </div>
    </main>
  </div>

  )
}

export default AutomaticTagging