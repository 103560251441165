import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {useHistory} from 'react-router-dom';

import at from "../../assets/at-diagram.png"
import atLiveProcessing from "../../assets/atLiveProcessing.jpg"
const Modal = (props) => {
    const goBack = () => {
        // setDatasetId(null);
        let id
        if(props.img =='at') id="automatic_tagging"
        else id="tag-one-product"
        const el = document.getElementById(id); 
        el.scrollIntoView();
       
      setTimeout(() => {
        document.body.style.overflowY = "visible";
        props.setOpenModal(false) 
      }, 550);
      
    }
    
    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";
    },[])

    return ReactDOM.createPortal(
        <div onClick={() => goBack()} className="ui dimmer modals visible active">
            <div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active modal-class basicc" style={{minWidth:"30%"}}>
               
                <div className="content modal-wrapper wrapper-override" style={{padding:"0px"}}>
                {/* <div  onClick={() => goBack()} className="close-div">
                        <i className="close icon icon-modal"
                        style={{fontSize:"2rem"}}>
                        </i>
                    </div> */}
                    
                <div  style={{padding:"2rem"}}>
                    <img style={{
                        maxHeight:"95vh"
                    }} src={props.img =='at'? at : atLiveProcessing}/> 
                <div className="modal-delete-buttons">
                 
                </div> 
                </div>
                
                </div>
               
            </div>
        </div>, document.getElementById("modal")

    )
}

export default Modal;

