import React from "react";
import { useStore } from "react-context-hook";
import { HashLink } from 'react-router-hash-link';

const LabelRecognition = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br />
       
       
        <h3 class="content" id="label_recognition">6. Label Recognition</h3>
        <div class="content">
          {/* <HashLink to={'/v4-documentation/label_recognition#startSolution'}>
            <h4 class="content" id="startSolution">Start Solution</h4></HashLink><br /> */}
          <p class="content">
          Our clients will be able to utilise the Image Recognition, by connecting to our API. The
          available endpoint for this solution is “Image Recognition on One Product”. All images for one
          product can be sent as request parameters to this endpoint, and our system as a response will
          return a JSON with information if there is a clothing label and if yes, what data is present on it.
          </p>
          <div class="content">
            <pre class="mainLink">POST /v1/solutions/label-recognition/product</pre>
          </div>
          <p class="content">
          To use this solution you can simply upload the product images and any product metadata using this endpoint, and you will get the label image detections as a response.
          </p>
        </div>
        <br />
        <h4 class="content unbold">Request Parameters</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>id</td>
              <td>A unique identifier</td>
            </tr>
            <tr>
              <td>image_url_1</td>
              <td>A valid image URL as a string</td>
            </tr>
          </tbody>
        </table><h4 class="content unbold">Recommended fields</h4>
        <br />
        <p class="content">
        This list of fields in the schema below is not necessary, but to provide more comfort and functionalities during solution development, they are recommended.
          </p>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>image_url_2..8</td>
              <td>More image files or image URLs of a product (max 7)</td>
            </tr>
            <tr>
              <td>product_url</td>
              <td>URL of a product</td>
            </tr>
            <tr>
              <td>product_title</td>
              <td>Title of a product</td>
            </tr>
            <tr>
              <td>category</td>
              <td>Category name of a product</td>
            </tr>
            <tr>
              <td>description</td>
              <td>Semantic representation of a product</td>
            </tr>
            <tr>
              <td>gender</td>
              <td>Gender type of product</td>
            </tr>
            <tr>
              <td>age_group</td>
              <td>The age group of a product</td>
            </tr>
            <tr>
              <td>size</td>
              <td>Available sizes for a product</td>
            </tr>
            <tr>
              <td>color</td>
              <td>Color of a product</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
            <span className="letBlue">let</span> <span className="blue">bodyContent</span> = [{'{'} <span className="txt">"id"</span>: <span className="txt">"productid1"</span>,<br />
                {'       '}<span className="txt">"image_url_1"</span>: <span className="txt">"https://store.image1.jpg"</span>, <br />
                {'       '}<span className="txt">"image_url_2"</span>: <span className="txt">"https://store.image2.jpg"</span>, <br />
                {'       '}<span className="txt">"image_url_3"</span>: <span className="txt">"https://store.image3.jpg"</span>, <br />
               
                {'       '}<span className="txt">"product_title"</span>:<span className="txt"> "Womens Jeans"</span>, <br />
                {'       '}<span className="txt">"category"</span>: <span className="txt">""</span>,  <br />
                {'       '}<span className="txt">"description"</span>: <span className="txt">"Description specified for this product." </span>{'}'}]<br />
                {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai//v1/solutions/label-recognition/product"</span>, {'{'}<br />
                {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
                {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
                {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>(<span className="blue">bodyContent</span>)<br/> 
                {'     '}{'}'})
          </pre>
        </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
              headers = {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                {'              '}<span className="txt">"Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>"</span> {'}'}<br />
                {'  '}data =  [{'{'}<span className="txt">"id"</span>:<span className="txt"> "productid1"</span>, <br />
                {'            '}<span className="txt">"image_url_1"</span>:<span className="txt"> "https://store.image1.jpg"</span>,<br />
                {'            '}<span className="txt">"image_url_2"</span>: <span className="txt">"https://store.image2.jpg"</span>, <br />
                {'            '}<span className="txt">"image_url_3"</span>:<span className="txt"> "https://store.image3.jpg"</span>, <br />
                
                {'            '}<span className="txt">"product_title"</span>: <span className="txt">"Womens Jeans"</span>,<br />
                {'            '}<span className="txt">"category"</span>:<span className="txt"> ""</span>,  <br />
                {'            '}<span className="txt">"description"</span>:<span className="txt"> "Description specified for this product."</span> {'}'}]<br />
                {'  '}response = requests.post<br/>
                {'       '}( <span className="txt">"https://pva.pixyle.ai//v1/solutions/label-recognition/product"</span>,<br/>
                {'       '}<span className="blue">headers</span> = headers,<br />
                {'       '}<span className="blue">data</span> = json.dumps(data) <br />
                {'      '}) <br /><br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre>
            <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
            {'       '}-H 'Authorization: Bearer access_token' \<br />
            {'       '}--data '[{'{'}"id": "productid1", \<br />
            {'       '}"image_url_1": "https://store.image1.jpg", \<br />
            {'       '}"image_url_2": "https://store.image2.jpg", \<br />
            {'       '}"image_url_3": "https://store.image3.jpg", \<br />
          
            {'       '}"product_title": "Womens Jeans", \<br />
            {'       '}"category": "", \ <br />
            {'       '}"description": "Description specified for this product. "{'}'}]'  \<br />
            {'       '}-X POST https://pva.pixyle.ai//v1/solutions/label-recognition/product<br /><br />
            </pre></div>
          }
        <h4 class="content unbold">Response Properties</h4>
        <br />
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>result</td>
              <td>An object that contains the detected information</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>The unique product identifier</td>
            </tr>
            <tr>
              <td>detected_attributes_types</td>
              <td>An array of objects for all sections that the solution can recognize and detect</td>
            </tr>
            <tr>
              <td>attribute_type</td>
              <td>Section type</td>
            </tr>
            <tr>
              <td>attributes</td>
              <td>An array of objects for all detected information in the relevant section</td>
            </tr>
            <tr>
              <td>attribute_name</td>
              <td>A detected information in the relevant section</td>
            </tr>
            <tr>
              <td>metadata</td>
              <td>An array of objects containing additional information generated from the label(s) present in the product.</td>
            </tr>
            
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br/><br/>
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br/>
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br/>
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"product-label-detect/product"</span><span class="whiteChar">,</span><br/>
              {'  '}"product_id"<span class="whiteChar">:</span> <span class="yellChar">"productid1"</span><span class="whiteChar">,</span><br/>
              {'  '}"result"<span class="whiteChar">:</span> <span class="redChar">{'{'}</span><br/>
              {'      '}"labels_present"<span class="whiteChar">:</span><span class="yellChar">true</span><span class="whiteChar">,</span><br/>
            
              {'       '}"detected_attribute_types"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute_type"<span class="whiteChar">:</span> <span class="yellChar">"brand"</span><span class="whiteChar">,</span><br/>
              {'            '}"attributes"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'               '}<span class="redChar">{'{'}</span><br/>
              {'               '}"attribute_name"<span class="whiteChar">:</span> <span class="yellChar">"Zara"</span><span class="whiteChar">,</span><br/>
              {'               '}"metadata":<span class="whiteChar">:</span> <span class="redChar">[]</span><br/>
              {'               '}<span class="redChar">{'}'}</span><br/>
              {'              '}<span class="redChar">]</span><br/>
              {'           '}<span class="redChar">{'}'}</span>,<br/>
              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute_type"<span class="whiteChar">:</span> <span class="yellChar">"made_in"</span><span class="whiteChar">,</span><br/>
              {'            '}"attributes"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'               '}<span class="redChar">{'{'}</span><br/>
              {'               '}"attribute_name"<span class="whiteChar">:</span> <span class="yellChar">"Guatemala"</span><span class="whiteChar">,</span><br/>
              {'               '}"metadata":<span class="whiteChar">:</span> <span class="redChar">[]</span><br/>
              {'               '}<span class="redChar">{'}'}</span><br/>
              {'              '}<span class="redChar">]</span><br/>
              {'           '}<span class="redChar">{'}'}</span>,<br/>

              {'          '}<span class="redChar">{'{'}</span><br/>
              {'            '}"attribute_type"<span class="whiteChar">:</span> <span class="yellChar">"material"</span><span class="whiteChar">,</span><br/>
              {'            '}"attributes"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'               '}<span class="redChar">{'{'}</span><br/>
              {'               '}"attribute_name"<span class="whiteChar">:</span> <span class="yellChar">"polyester"</span><span class="whiteChar">,</span><br/>
              {'               '}"metadata"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'                  '}<span class="redChar">{'{'}</span><br/>
              {'                  '}"meta_key"<span class="whiteChar">:</span> <span class="yellChar">"material_percentage"</span><span class="whiteChar">,</span><br/>
              {'                  '}"meta_value"<span class="whiteChar">:</span> <span class="yellChar">"57"</span><span class="whiteChar">,</span><br/>
              {'                  '}<span class="redChar">{'}'}</span><br/>
              {'                '}<span class="redChar">]</span><br/>
              {'               '}<span class="redChar">{'}'}</span>,<br/>
              {'               '}<span class="redChar">{'{'}</span><br/>
              {'               '}"attribute_name"<span class="whiteChar">:</span> <span class="yellChar">"viscose"</span><span class="whiteChar">,</span><br/>
              {'               '}"metadata"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'                  '}<span class="redChar">{'{'}</span><br/>
              {'                  '}"meta_key"<span class="whiteChar">:</span> <span class="yellChar">"material_percentage"</span><span class="whiteChar">,</span><br/>
              {'                  '}"meta_value"<span class="whiteChar">:</span> <span class="yellChar">"41"</span><span class="whiteChar">,</span><br/>
              {'                  '}<span class="redChar">{'}'}</span><br/>
              {'                '}<span class="redChar">]</span><br/>
              {'               '}<span class="redChar">{'}'}</span>,<br/>

            
              {'               '}<span class="redChar">{'{'}</span><br/>
              {'               '}"attribute_name"<span class="whiteChar">:</span> <span class="yellChar">"cotton"</span><span class="whiteChar">,</span><br/>
              {'               '}"metadata"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'                  '}<span class="redChar">{'{'}</span><br/>
              {'                  '}"meta_key"<span class="whiteChar">:</span> <span class="yellChar">"material_percentage"</span><span class="whiteChar">,</span><br/>
              {'                  '}"meta_value"<span class="whiteChar">:</span> <span class="yellChar">"2"</span><span class="whiteChar">,</span><br/>
              {'                  '}<span class="redChar">{'}'}</span>,<br/>
              {'                '}<span class="redChar">]</span><br/>
              {'               '}<span class="redChar">{'}'}</span><br/>
              {'              '}<span class="redChar">]</span><br/>
              {'           '}<span class="redChar">{'}'}</span>,<br/>

              {'            '}"attribute_type"<span class="whiteChar">:</span> <span class="yellChar">"size"</span><span class="whiteChar">,</span><br/>
              {'            '}"attributes"<span class="whiteChar">:</span> <span class="redChar">[</span><br/>
              {'               '}<span class="redChar">{'{'}</span><br/>
              {'               '}"attribute_name"<span class="whiteChar">:</span> <span class="yellChar">"S"</span><span class="whiteChar">,</span><br/>
              {'               '}"metadata":<span class="whiteChar">:</span> <span class="redChar">[]</span><br/>
              {'               '}<span class="redChar">{'}'}</span><br/>
              {'              '}<span class="redChar">]</span><br/>
              {'           '}<span class="redChar">{'}'}</span>,<br/>

              {'         '}<span class="redChar">]</span><br/>
              {'      '}<span class="redChar">{'}'}</span><br/>
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar"></span><br/>
             
             
            </span></pre></div><br/><br/><br/>
      
        <p class="content">
          <HashLink to={'/v4-documentation/labelRecognition#end-notes'}>
          <h4 class="content" id="solutionStatus">End notes</h4></HashLink><br />
          This is a first version (v1.0) of the Label Recognition solution. We expect that with client feedback and usage, we’ll improve the detection accuracy in our next releases.
        </p>
        <p className="content">As the next steps for this solution, we envision the inclusion of different sections, as needed by the fashion industry. Some of them are:</p>
        <p class="content">
            &emsp;1.&nbsp; Care instructions;
          </p>
          <p class="content">
            &emsp;2.&nbsp; Color;
          </p>
          <p class="content">
            &emsp;3.&nbsp; Fit;
          </p>
          <p class="content">
            &emsp;4.&nbsp; Website;
          </p>
          <p class="content">
            &emsp;5.&nbsp; Category;
          </p> 
           <p class="content">
            &emsp;6.&nbsp; Price;
          </p>
          <p class="content" style={{marginBottom:"3rem"}}>
            &emsp;7.&nbsp; Brand from logo.
          </p>

      </div>
    </main>
  </div>

  )
}

export default LabelRecognition