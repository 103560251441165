import React from 'react'
import { HashLink } from 'react-router-hash-link';
import { useStore } from "react-context-hook";

const AutomaticRescale = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content">
        <h3 class="content" id="automatic_rescale">
          3. Automatic Rescale
        </h3>
        <div class="content">
          <p class="content">
            Pixyle's scaling images solution detects fashion items in the image, and scales the image proportionally so that the garments inside fit proportionally to the human body. This solution works with garments on a plain background, without a model wearing them.
            <br /><br />
            You can use it by simply uploading your image collection. Pixyle will then localize the fashion item, detect the category and scale the images proportionally to the human body. After the process is finished you will get a JSON as a response, filled with the rescaling data information. This information will allow you to perform resizing and cutting of the fashion item only for your catalogue images.
          </p>

          <div class="content">
            <pre class="mainLink">GET /v3/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale</pre>
          </div>
          <p>
            When this endpoint is called, Pixyle starts to process the images in the specified dataset. There are some limitations on the images in that dataset:
          </p>
          <li>
            - For optimal and accurate results, we recommend images with good resolution, therefore images should not be extremely small (50x50) or extremely big (bigger than 8192x8192).
          </li>
        </div>
        <h4 class="content unbold">Request Parameters</h4>


        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your dataset</td>
            </tr>

          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span>fetch(" https://pva.pixyle.ai/v3/datasets/1570624498775133<br />
                {'       '}/solutions/auto-scale", <br />
                {'       '}method: "GET",<br />
                {'       '}headers: {'{'}"Authorization": "Bearer access_token" {'}'}<br />
                {'     '}{'}'})<br />
              </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.get(" https://pva.pixyle.ai/v3/datasets/1570624498775133<br />
                {'       '}/solutions/auto-scale',<br/>
            {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />

              <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
              {'       '}-X GET https://pva.pixyle.ai/v3/datasets/1570624498775133/solutions/auto-scale</pre>
          </div>
          }
        
        

        <h4 class="content unbold">Response Properties</h4>


        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>user</td>
              <td>User that made the request</td>
            </tr>
            <tr>
              <td>start_time</td>
              <td>Time when the automatic scaling has started</td>
            </tr>


          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"auto-scale"</span><span class="whiteChar">,</span><br />
              {'  '}"message"<span class="whiteChar">:</span> <span class="yellChar">"Automatic scaling process has started, please check status."</span><span class="whiteChar">,</span><br />
              {'  '}"meta"<span class="whiteChar">:</span><span class="redChar">{'{'}</span><br />
              {'    '}"user"<span class="whiteChar">:</span><span class="yellChar">"FrontEnd"</span><span class="whiteChar">,</span><br />
              {'    '}"start_time"<span class="whiteChar">:</span><span class="yellChar">"Tue, 27 Dec 2019 09:23:36"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>

        <p class="content">The user will get this response immediately, and in the backend Pixyle starts processing the dataset. Depending on the number of images, this may take some time, so you can check the scaling process status on the following endpoint.</p>

        <div class="content">
          <pre class="mainLink">GET /v3/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale/status</pre>
        </div>
        <h4 class="content unbold">Request Parameters</h4>


        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your dataset</td>
            </tr>

          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span>fetch(" https://pva.pixyle.ai/v3/datasets/1570624498775133<br />
                {'       '} /solutions/auto-scale/status", <br />
                {'       '}method: "GET",<br />
                {'       '}headers: {'{'}"Authorization": "Bearer access_token" {'}'}<br />
                {'     '}{'}'})<br />
              </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
          <span class="grayChar">$ </span>requests.get(" https://pva.pixyle.ai/v3/datasets/1570624498775133<br />
                {'       '}/solutions/auto-scale/status',<br/>
          {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
          {'     '})<br />
          </pre>
        </div>
          :
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />

            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v3/datasets/1570624498775133 \<br />
            {'       '}/solutions/auto-scale/status</pre>
        </div> 
          }

        
        
        <p class="content">Pixyle will return the amount and percentage state of scaled products at the time the request has been made. </p>
        <h4 class="content unbold">Response Properties</h4>


        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>An id of the dataset which is being scaled</td>
            </tr>
            <tr>
              <td>number_of_products</td>
              <td>Total number of products</td>
            </tr>
            <tr>
              <td>scaled_products</td>
              <td>Number of products successfully scaled</td>
            </tr>
            <tr>
              <td>treated_images_percentage</td>
              <td>Percentage value of the amount of treated images, over the total number of images</td>
            </tr>
            <tr>
              <td>dataset_count</td>
              <td>Number of datasets by the user</td>
            </tr>
            <tr>
              <td>collection_name</td>
              <td>Name of the collection</td>
            </tr>


          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"auto-scale/status"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"meta"<span class="whiteChar">:</span><span class="redChar">{'{'}</span><br />
              {'    '}"number_of_products"<span class="whiteChar">: 1000,</span><br />
              {'    '}"scaled_products"<span class="whiteChar">: 100,</span><br />
              {'    '}"treated_images_percentage"<span class="whiteChar">: 10.0</span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_count"<span class="whiteChar">: 2,</span><br />
              {'  '}"collection_name"<span class="whiteChar">:</span> <span class="yellChar">"images.csv"</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>

        <p class="content">When Pixyle finishes with the autoscale process, you can get the general result about the categories in this dataset, or the result for one product. You can get the autotag result, only after the autoscale process is finished.</p>

        <div class="content">
          <HashLink to={'/v3-documentation#automatic-rescale-get-categories'}><h4 class="content" id="automatic-rescale-get-categories">
            Get Categories
          </h4></HashLink>
          <div class="content">
            <pre class="mainLink">GET /v3/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale/categories</pre>
          </div>
          <p class="content">
            Тo make the process easier to manage, you can get access to all detected categories on this endpoint. Later, this can help you to see scale information for each product.
          </p>

        </div>
        <h4 class="content unbold">Request Parameters</h4>


        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your dataset</td>
            </tr>

          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span>fetch(" https://pva.pixyle.ai/v3/datasets/1570624498775133<br />
                {'       '}/solutions/auto-scale/categories", <br />
                {'       '}method: "GET",<br />
                {'       '}headers: {'{'}"Authorization": "Bearer access_token" {'}'}<br />
                {'     '}{'}'})<br />
              </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.get("https://pva.pixyle.ai/v3/datasets/1570624498775133<br />
            {'       '}/solutions/auto-scale/categories',<br/>
            {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />

              <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
              {'       '}-X GET https://pva.pixyle.ai/v3/datasets/1570624498775133 \<br />
              {'       '}/solutions/auto-scale/categories</pre>
          </div>
          }
        
        
        <h4 class="content unbold">Response Properties</h4>


        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>detected_categories</td>
              <td>An array that contains all detected categories from the dataset. Each object consists of a corresponding category and it's information</td>
            </tr>
            <tr>
              <td>category</td>
              <td>Detected category name</td>
            </tr>
            <tr>
              <td>primary_image</td>
              <td>A product primary image representing the category</td>
            </tr>
            <tr>
              <td>number_of_category_products</td>
              <td>Number of products in a specific category</td>
            </tr>
            <tr>
              <td>collection_name</td>
              <td>The original name of the collection</td>
            </tr>
            <tr>
              <td>number_of_products</td>
              <td>Number of products in a the whole dataset</td>
            </tr>


          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"detected_categories"<span class="whiteChar">:</span> <span class="redChar">[</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}"category"<span class="whiteChar">:</span><span class="yellChar"> "skirts"</span><span class="whiteChar">,</span><br />
              {'      '}"number_of_category_products"<span class="whiteChar">: 70,</span><br />
              {'      '}"primary_image"<span class="whiteChar">:</span><span class="yellChar"> "https://image1.jpg"</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}"category"<span class="whiteChar">:</span><span class="yellChar"> "jeans"</span><span class="whiteChar">,</span><br />
              {'      '}"number_of_category_products"<span class="whiteChar">: 150,</span><br />
              {'      '}"primary_image"<span class="whiteChar">:</span><span class="yellChar"> "https://image2.jpg"</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}<span class="whiteChar">...</span><br />
              {'  '}<span class="redChar">]</span><span class="whiteChar">,</span><br />
              {'  '}"collection_name"<span class="whiteChar">:</span> <span class="yellChar">"images.csv"</span><span class="whiteChar">,</span><br />
              {'  '}"number_of_products"<span class="whiteChar">: 1000.0</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>

        <div class="content">
          <HashLink to={'/v3-documentation#automatic-rescale-get-category-products'}><h4 class="content" id="automatic-rescale-get-category-products">
            Get Category Products
          </h4></HashLink>
        </div>
        <div class="content">
          <pre class="mainLink">GET /v3/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale/categories/<i>{'{category}'}</i>/products</pre>
        </div>
        <p class="content">
          You can get access to all detected products in a specific category on this endpoint.
        </p>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your datasets</td>
            </tr>
            <tr>
              <td>category</td>
              <td>A valid category</td>
            </tr>

          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span>fetch(" https://pva.pixyle.ai/v3/datasets/1570624498775133<br />
                {'       '}/solutions/auto-scale/categories/jeans/products", <br />
                {'       '}method: "GET",<br />
                {'       '}headers: {'{'}"Authorization": "Bearer access_token" {'}'}<br />
                {'     '}{'}'})<br />
              </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.get("https://pva.pixyle.ai/v3/datasets/1570624498775133<br/>
            {'       '}/solutions/auto-scale/categories/jeans/products',<br/>
            {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />

            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v3/datasets/1570624498775133 \ <br />
            {'       '}/solutions/auto-scale/categories/jeans/products</pre>
        </div>
          }
        
        
        <h4 class="content unbold">Response Properties</h4>


        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>products</td>
              <td>An object that contains all products from requested category</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>An id of a product</td>
            </tr>
            <tr>
              <td>primary_image</td>
              <td>Primary image of the product</td>
            </tr>
            <tr>
              <td>title</td>
              <td>A title of the product</td>
            </tr>
            <tr>
              <td>scale_information</td>
              <td>An object that contains the scaling information</td>
            </tr>
            <tr>
              <td>number_of_products</td>
              <td>Number of products in the requested category</td>
            </tr>
          </tbody>
        </table>


        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span> <br />
              {'  '}"status"<span class="whiteChar">:</span><span class="yellChar"> "OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span><span class="yellChar"> "auto-scale/category/products"</span><span class="whiteChar">,</span><br />
              {'  '}"collection_name"<span class="whiteChar">:</span><span class="yellChar"> "images.csv"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"category"<span class="whiteChar">:</span><span class="yellChar"> "jeans"</span><span class="whiteChar">,</span><br />
              {'  '}"number_of_products"<span class="whiteChar">: 150,</span><br />
              {'  '}"products"<span class="whiteChar">:</span> <span class="redChar">[</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}"product_id"<span class="whiteChar">:</span><span class="yellChar"> "product2_id"</span><span class="whiteChar">,</span><br />
              {'      '}"title"<span class="whiteChar">:</span><span class="yellChar"> "title2"</span><span class="whiteChar">,</span><br />
              {'      '}"primary_image"<span class="whiteChar">:</span><span class="yellChar"> "https://image2.jpg"</span><span class="whiteChar">,</span><br />
              {'      '}"scale_information"<span class="whiteChar">: </span><span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}<span class="whiteChar">...</span><br />
              {'  '}<span class="redChar">]</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>

        <div class="content">
          <HashLink to={'/v3-documentation#automatic-rescale-get-product'}><h4 class="content" id="automatic-rescale-get-product">
            Get Product
          </h4></HashLink>
        </div>
        <div class="content">
          <pre class="mainLink">GET /v3/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale/products/<i>{'{product_id}'}</i></pre>
        </div>
        <p class="content">
          After the rescaling process finished, you can use this endpoint to get scaled data for one product.
        </p>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your dataset</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>A valid id of one of dataset’s products, you want to get tags for</td>
            </tr>

          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span>fetch(" https://pva.pixyle.ai/v3/datasets/1570624498775133<br />
                {'       '}/solutions/auto-scale/products/product2_id", <br />
                {'       '}method: "GET",<br />
                {'       '}headers: {'{'}"Authorization": "Bearer access_token" {'}'}<br />
                {'     '}{'}'})<br />
              </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.get(" https://pva.pixyle.ai/v3/datasets/1570624498775133<br/>
            {'       '}/solutions/auto-scale/products/product2_id',<br/>
            {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />

            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v3/datasets/1570624498775133 \ <br />
            {'       '}/solutions/auto-scale/products/product2_id</pre>
        </div>
          }
      
        <h4 class="content unbold">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>category</td>
              <td>Category of the product</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>An id of a product</td>
            </tr>
            <tr>
              <td>primary_image</td>
              <td>Primary image of the product</td>
            </tr>
            <tr>
              <td>title</td>
              <td>A title of a product</td>
            </tr>
            <tr>
              <td>scale_factor</td>
              <td>A number that show how much the image should be rescaled</td>
            </tr>
            <tr>
              <td>product_coordinates</td>
              <td>Coordinates of the box around the detected product</td>
            </tr>
          </tbody>
        </table>


        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"auto-scale/products/get"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"result"<span class="whiteChar">:</span><br />
              {'  '}<span class="redChar">{'{'}</span><br />
              {'    '}"product_id"<span class="whiteChar">:</span> <span class="yellChar">"product1_id"</span><span class="whiteChar">,</span><br />
              {'    '}"title"<span class="whiteChar">:</span> <span class="yellChar">"product1_title"</span><span class="whiteChar">,</span><br />
              {'    '}"category"<span class="whiteChar">:</span> <span class="yellChar">"jeans"</span><span class="whiteChar">,</span><br />
              {'    '}"scale_factor"<span class="whiteChar">:</span> <span class="whiteChar"> 0.75,</span><br />
              {'    '}"product_coordinates"<span class="whiteChar">:</span><span class="redChar">{'{'}</span><br />
              {'      '}"x_min"<span class="whiteChar">: 516,</span><br />
              {'      '}"y_min"<span class="whiteChar">: 569,</span><br />
              {'      '}"x_max"<span class="whiteChar">: 1063,</span><br />
              {'      '}"y_max"<span class="whiteChar">: 1695</span><br />
              {'    '}<span class="redChar">{'}'}</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <div class="content">
          <HashLink to={'/v3-documentation#automatic-rescale-scale-one-image'}><h4 class="content" id="automatic-rescale-scale-one-image">
            Scale One Image
          </h4></HashLink>
        </div>
        <p class="content">
          Pixyle can also scale only one image. In this case, there is no need for you to create a dataset. You can simply upload a photo on this endpoint. After a short amount of time you will get a  JSON object as a response, containing the detected category, a scale factor and the bounding box coordinates of the localized fashion item.
        </p>
        <br />
        <p class="content">
          An optional category field is also available on this endpoint. For more accurate results you can assign the category of the image in the request.
        </p>
        <br />
        <p class="content">
          If no category is sent with the image, Pixyle will detect each product, but will scale only the biggest one.
        </p>
        <div class="content">
          <pre class="mainLink">POST /v3/solutions/auto-scale/image</pre>
        </div>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>image</td>
              <td>A valid image file or a valid image URL as string</td>
            </tr>
            <tr>
              <td>category</td>
              <td>Category name of a product</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
           <div class=" theCode">
           <pre>
             <span class="grayChar"># example request with image file</span><br /><br />
             <span class="grayChar">$ </span>let files = '@image1.jpg'<br />
             {'   '}const formData = new FormData();<br />
             {'       '}formData.append("image", files);<br />
             {'       '}fetch("https://pva.pixyle.ai/v3/solutions/auto-scale/image", {'{'}<br />
             {'       '}method: "POST",<br />
             {'       '}headers: {'{'}"Content-Type": "multipart/form-data"<br />
             {'       '}"Authorization": "Bearer access_token" {'}'},<br />
             {'       '}body: formData <br />
             {'     '}{'}'})<br />
          
           <br />
           <span class="grayChar"># example request with image URL</span><br /><br />
           <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v3/solutions/auto-scale/image", {'{'}<br />
             {'       '}method: "POST",<br />
             {'       '}headers: {'{'}"Content-Type": "multipart/form-data"<br />
             {'       '}"Authorization": "Bearer access_token" {'}'},<br />
             {'       '}body:JSON.stringify({'{'}'image' :'https://store.image1.jpg'{'}'},<br />
             {'       '}{'{'}'category': 'jeans'{'}'})<br/>
             {'     '}{'}'})<br />
             </pre>  
           </div>
          : langCode == "python" ?  
          <div class=" theCode">
          <pre><span class="grayChar"># example request with image file</span><br /><br />
          {/* <span class="grayChar">$ </span> */}
          files ={'{'}
                'image': ('image1.jpg', open('image1.jpg', 'rb')),
                {'}'}<br/>
          headers = {'{'}
              'Authorization': 'Bearer access_token',
              {'}'} <br/>
          response = requests.post<br/>
          {'       '}('https://pva.pixyle.ai/v3/solutions/auto-scale/image',<br/>
          {'       '}headers=headers,, <br />
          {'       '}files=files<br/>
          {'      '})<br /><br />
          <span class="grayChar"># example request with image url</span><br /><br />
          {/* <span class="grayChar">$ </span> */}
          files = {'{'}
                'image': (None, 'https://store.image1.jpg'),<br/>
                {'       '}'category': (None, 'jeans'),
                {'}'}<br/>
          headers = {'{'}
              'Authorization': 'Bearer access_token',
              {'}'} <br/>
          response = requests.post<br/>
          {'       '}('https://pva.pixyle.ai/v3/solutions/auto-scale/image',<br/>
          {'       '}headers=headers,, <br />
          {'       '}files=files<br/>
          {'      '})<br />
          </pre>
        </div>
          : 
          <div class=" theCode">
            <pre><span class="grayChar"># example request with image file</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
              {'       '}-H 'Authorization: Bearer access_token' \<br />
              {'       '}-F 'image=@image1.jpg' \<br />
              {'       '}-X POST https://pva.pixyle.ai/v3/solutions/auto-scale/image<br /><br />

              <span class="grayChar"># example request with image url</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
              {'       '}-H 'Authorization: Bearer access_token' \<br />
              {'       '}-F 'image=https://store.image1.jpg' \<br />
              {'       '}-F 'category=jeans' \<br />
              {'       '}-X POST https://pva.pixyle.ai/v3/solutions/auto-scale/image

            </pre>
          </div>
          }

        <h4 class="content unbold">Response Properties</h4>


        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>category</td>
              <td>Category of the product on the image</td>
            </tr>
            <tr>
              <td>scale_factor</td>
              <td>Number that indicates how much the image needs to be scaled</td>
            </tr>
            <tr>
              <td>product_coordinates</td>
              <td>Coordinates of the box that should be cropped</td>
            </tr>
          </tbody>
        </table>


        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"auto-scale/images"</span><span class="whiteChar">,</span><br />
              {'  '}"img_name"<span class="whiteChar">:</span> <span class="yellChar">"image1.jpg"</span><span class="whiteChar">,</span><br />
              {'  '}"category"<span class="whiteChar">:</span> <span class="yellChar">"jeans"</span><span class="whiteChar">,</span><br />
              {'  '}"scale_factor"<span class="whiteChar">:</span> <span class="whiteChar"> 0.75,</span><br />
              {'  '}"product_coordinates"<span class="whiteChar">:</span><span class="redChar">{'{'}</span><br />
              {'    '}"x_min"<span class="whiteChar">: 516,</span><br />
              {'    '}"y_min"<span class="whiteChar">: 569,</span><br />
              {'    '}"x_max"<span class="whiteChar">: 1063,</span><br />
              {'    '}"y_max"<span class="whiteChar">: 1695</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>

        <div class="content">
          <HashLink to={'/v3-documentation#automatic-rescale-download-file'}><h4 class="content" id="automatic-rescale-download-file">
            Download File
          </h4></HashLink>
        </div>
        <div class="content">
          <pre class="mainLink">POST /v3/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale/files/<i>{'{file_type}'}</i></pre>
        </div>
        <p class="content">
          Taking into account the number of products in a dataset, the best way for you to get the scale data for each one of them is to use this endpoint.If the request body is empty the returned file will consist of data only from the auto-scale solution.<br /><br />
          You can use additional optional parameters to get the data from the other solutions in the same file.To get the result data from another solution, just add the solution name followed with “true” value in the request body.<br /><br />
          The response you will get here is a file, which can be either JSON or CSV.
        </p>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A valid id of one of your dataset</td>
            </tr>
            <tr>
              <td>file_type</td>
              <td>String that represents the type of file you want to get the result file in</td>
            </tr>
            <tr>
              <td>solution_name</td>
              <td>A name of a solution, which result data will be added in the file.<br />
                Possible solution_name =[“auto-tag”]</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
             <pre>
               <span class="grayChar"># example request</span><br /><br />
               <span class="grayChar">$ </span>fetch(" https://pva.pixyle.ai/v3/datasets/1570624498775133/solutions/auto-scale/files/csv", <br />
               {'       '}method: "POST",<br />
               {'       '}headers: {'{'}"Authorization": "Bearer access_token" {'}'},<br />
               {'       '}body:  '{'{'}"auto-tag": "true"{'}'}',<br />
               {'     '}{'}'})<br />
             </pre>
         </div>
          : langCode == "python" ?  
            <div class=" theCode">
              <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>requests.post("https://pva.pixyle.ai/v3/datasets/1570624498775133<br/>
              {'       '}/solutions/auto-scale/files/csv',<br/>
              {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
              {'       '}data= '{'{'}"auto-tag": "true"{'}'}'<br />
              {'     '})<br />
              </pre>
          </div>
          : 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />

              <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
              {'      '}--data '{'{'}"auto-tag": true{'}'}' \<br />
              {'      '}-X POST https://pva.pixyle.ai/v3/datasets/1570624498775133 \<br />
              {'      '}/solutions/auto-scale/files/csv
            </pre>
          </div>
            }
        <p class="content" style={{ marginBottom: '40px' }}>You can download the file from the response.</p>
      </div>
    </main>
  </div>
  )
}

export default AutomaticRescale