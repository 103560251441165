import React from "react";
import { useStore } from "react-context-hook";

const RenameFile = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
      <h3 class="content" id="rename_file">
          Rename File
        </h3>
        <div class="content">
          <div class="content">
            <pre class="mainLink">PUT /v4/datasets/<i>{'{dataset_id}'}</i>/rename</pre>
          </div>
          <p class="content">
            You can use this endpoint to rename your dataset. Please provide the API endpoint with the correct dataset id and the new name.
          </p>
          <br />
          <h4 class="content unbold">Request Parameters</h4>
        </div>
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>title</td>
                <td>The new title of the dataset</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h4 class="content unbold">Response properties</h4>
        </div>
        {langCode== 'js' ? 
               <div class=" theCode">
               <pre><span class="grayChar"># example request</span><br /><br />
                 <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133/rename"</span>, {'{'}<br />
                 {'       '}<span className="blue">method</span>: <span className="txt">"PUT"</span>,<br />
                 {'       '}<span className="blue">headers</span>: {'{'} <span className="blue">Accept</span>: <span className="txt">"application/json"</span>,<br />
                 {'                  '}<span className="txt">"Content-Type"</span>:<span className="txt"> "application/json"</span>,<br />
                 {'                  '}<span className="txt">"Authorization"</span>: <span className="txt">`Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
                 {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>({'{'} <span className="txt">"title"</span>:<span className="txt">"changed_name.csv"</span> {'}'})<br />
                 {'     '}{'}'})<br />
               </pre>
             </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'} <span className="txt">"Content-Type"</span>: <span className="txt">"application/json"</span>,<br />
            {'              '}<span className="txt">"Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
            {'  '}data = {'{'} <span className="txt">"title"</span>:<span className="txt">"changed_name.csv"</span> {'}'}<br/>
            {'  '}requests.put( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133/rename"</span>, <br />
            {'       '}<span className="blue">headers</span> = headers, <br/>
            {'       '}<span className="blue">data</span> = json.dumps(data)<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
            {'      '} -H 'Authorization: Bearer access_token' \<br />
            {'      '} --data '{'{'}"title":"changed_name.csv"{'}'}' \<br />
            {'      '} -X PUT https://pva.pixyle.ai/v4/datasets/1570624498775133/rename
          </pre></div>
          }
        
     
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for the data file you renamed</td>
              </tr>
              <tr>
                <td>new_dataset_title</td>
                <td>The new name of the dataset</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"rename"</span><span class="whiteChar">,</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"new_dataset_title"<span class="whiteChar">: <span class="yellChar">"changed_name.csv"</span></span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
      </div>
    </main>
  </div>
  )
}

export default RenameFile