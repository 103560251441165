import react,{useState, useEffect} from "react";
import { useStore } from "react-context-hook";

const DropDown = ()=>{
  const [langCode, setLangCode] = useStore("langCode",'');

    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
  useEffect(()=>{
    if(langCode === 'js')
    setSelectedOption(1)
    else if(langCode === 'python')
    setSelectedOption(2)
    else  setSelectedOption(0)
  },[langCode])
    const optionsList = [
      "curl",
      "JavaScript",
      "Python3.X",
      
    ];
    const toggleOptions = () => {
      setIsOptionsOpen(!isOptionsOpen);
    };
  
    const setSelectedThenCloseDropdown = (index) => {
      setSelectedOption(index);
     
      if(optionsList[index] === 'JavaScript')
      setLangCode('js')
      else if (optionsList[index] === 'Python3.X')
      setLangCode('python')
      else setLangCode('curl')
      setIsOptionsOpen(false);
    };
  
    return (
        
      <div className="wrapper-dropdown">
        <div className="container-dropdown">
          <button
            type="button"
            aria-haspopup="listbox"
            aria-expanded={isOptionsOpen}
            className={isOptionsOpen ? "expanded btn-dropdown" : "btn-dropdown"}
            onClick={toggleOptions}
          >
            {optionsList[selectedOption]}
            <i id='chevron' className={isOptionsOpen ? "fas fa-chevron-up i-dropdown" : "fas fa-chevron-down i-dropdown"}></i>
          </button>
          <ul
            className={`options ${isOptionsOpen ? "show" : ""}`}
            role="listbox"
            aria-activedescendant={optionsList[selectedOption]}
            tabIndex={-1}
            >
              {optionsList.map((option, index) => (
                <li
                  id={option}
                  role="option"
                  aria-selected={selectedOption == index}
                  tabIndex={0}
                  onClick={() => {setSelectedThenCloseDropdown(index)}}
                >
                  {option}
                </li>
            ))}
          </ul>
      </div>
    </div>
    
    )






}
export default DropDown;