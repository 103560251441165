import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import Search from '../Search';
import MobileSearch from '../MobileSearch';
import { HashLink } from 'react-router-hash-link';

import logo from '../../assets/logo.svg';
import '../../style/style.css'

const Navbar = () => {
  const [showAPI, setShowAPI] = useState(false)
  const [showIndex, setShowIndex] = useState(false)
  const [showSolutions, setShowSolutions] = useState(false)
  const [click, setClick] = useState(false);
  const [current, setCurrent] = useState('')

  const handleShowApi = () => setShowAPI(!showAPI)
  const handleShowIndex = () => setShowIndex(!showIndex)
  const handleShowSolutions = () => setShowSolutions(!showSolutions)
  const handleClick = () => setClick(!click);
  const ref = useRef()
  const history = useHistory()

  // useEffect(() => {
  //   const onBodyClick = event => {
  //     if (ref.current.contains(event.target)) {
  //       return
  //     }
  //     // else close the dropdown
  //     setShowAPI(false)
  //     setShowIndex(false)
  //     setShowSolutions(false)
  //   }
  //   document.body.addEventListener("click", onBodyClick)
  //   return () => {
  //     document.body.removeEventListener("click", onBodyClick)
  //   }
  // }, [])





  function scrollToTargetAdjusted(element) {
    var headerOffset = 60;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }




  const currentRoute = history.location.pathname.toLowerCase();

  const getColor = (curr) => {
    if (history.location.hash === curr) {
      return '#52489c'
    }
  }

  return (
    <div ref={ref} class="gridContainer">
      <header class="header">
        <Search />
      </header>
      <div id="sidenav" className="sidenav">
        <a id="logolink" href="https://www.pixyle.ai/">
          <img src={logo} className="logo" alt="Pixyle" />
        </a>
        <MobileSearch />
        <nav className="nav">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="whats_new">
              <div className={history.location.hash.includes("#whats_new") ? "left-navbar-selected-item" : ""}></div>
              <HashLink
                scroll={(el) => scrollToTargetAdjusted(el)}
                onClick={() => { handleClick(); setShowAPI(false); setShowSolutions(false); setShowIndex(false) }}
                to={'/v4_release-notes#whats_new'}
                style={{ color: getColor('#whats_new') }}
              >What's New?</HashLink></li>
            <li className="imporvements">
              <div className={history.location.hash.includes("#imporvements") ? "left-navbar-selected-item" : ""}></div>
              <HashLink
                scroll={(el) => scrollToTargetAdjusted(el)}
                onClick={() => { handleClick(); setShowAPI(false); setShowSolutions(false); setShowIndex(false) }}
                to={'/v4_release-notes#imporvements'}
                style={{ color: getColor('#imporvements') }}
              >Improvements</HashLink></li>
             <li className="all_changes">
              <div className={history.location.hash.includes("#all_changes") ? "left-navbar-selected-item" : ""}></div>
              <HashLink
                scroll={(el) => scrollToTargetAdjusted(el)}
                onClick={() => { handleClick(); setShowAPI(false); setShowSolutions(false); setShowIndex(false) }}
                to={'/v4_release-notes#all_changes'}
                style={{ color: getColor('#all_changes') }}
              >All taxonomy changes</HashLink></li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </nav>

      
      </div>
    </div>
  )
}
export default Navbar;