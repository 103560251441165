import React from "react";
import { useStore } from "react-context-hook";
const DeleteFile = () => {
  const [langCode, ] = useStore("langCode",'');

  return (
  <div class="gridContainer">
    <main class="mainContainer">
        <div class="mainContent js-toc-content"><br/>
            <h3 class="content" id="delete_error_object">
              Delete Error Object
            </h3>
            <div class="content">
              <pre class="mainLink">DELETE /v4/datasets/<i>{'{dataset_id}'}</i>/errors</pre>
            </div>
            <div class="content">
                <p class="content">
                When a dataset is created and Pixyle is finished downloading the images in the data file, 
                you will get a response regarding the status of all images including an errors field. 
                The errors field represents an array of objects containing information about failed images in a 
                specific dataset.<br/><br/>
                This endpoint serves to remove an error object from the errors array for specific dataset.
                </p>
                <br/> 
                <br/>
                <h4 class="content unbold">Request Parameters</h4>
                <br />
                <div class="content">
                  <table class="content">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                      </tr>
                      <tr>
                        <td>product_id</td>
                        <td>A valid product_id from the error object that you want to delete</td>
                      </tr>
                      <tr>
                        <td>drop_all</td>
                        <td>A boolean flag that gives an opportunity (if set to True) to remove everything from the errors array</td>
                      </tr>
                    
                    </tbody>
                  </table>
                </div>
            </div>


          <div class=" theCode"><pre>
              <span class="grayChar"># example request</span><br /><br />

              {langCode== 'js' ? 
                <div>
                    <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/{'{dataset_id}'}/errors?product_id=1"</span>, {'{'}<br />
                    {'       '}<span className="blue">method</span>:<span className="txt"> "DELETE"</span>,<br />
                    {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>: <span className="txt">"application/json"</span>,<br />
              {'                  '}<span className="txt">"Authorization"</span>: <span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
                    {'     '}{'}'})<br /><br />
                </div>
              : langCode == "python" ?  
                  <div>
                      <span class="grayChar">$ </span>
                      headers = {'{'}<span className="txt"> "Content-Type"</span>: <span className="txt">"application/json"</span>,<br />
            {'              '}<span className="txt">"Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
                      {'  '}requests.delete( <span className="txt">"https://pva.pixyle.ai/v4/datasets/{'{dataset_id}'}/errors?product_id=1"</span>,<br/>
                      {'       '}<span className="blue">headers</span> = headers <br/>
                      {'     '})<br />
                  </div> 
            : 
                <div>
                  <br/>
                    <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
                    {'       '}-H 'Authorization: Bearer access_token' \<br />
                    {'       '}-X DELETE https://pva.pixyle.ai/v4/datasets/{'{dataset_id}'}/errors?product_id=1<br /><br />

                  </div>
              }
              </pre>
           </div>
          
          <div class=" theCode">
            <pre>
            
              {/* Response */}
              <span class="grayChar"># example JSON response</span><br /><br />
              <span class="orangeChar">
                <span class="redChar">{'{'}</span><br />
                {'   '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
                {'   '}"method"<span class="whiteChar">:</span> <span class="yellChar">"dataset/errors/delete"</span><span class="whiteChar">,</span><br />
                {'   '}"dataset_id"<span class="whiteChar">: 1570624498775133</span><br />
                {'   '}"meta"<span class="whiteChat">: </span><span class="redChar">{'{'}</span><br />
                {'     '}"collection_name"<span class="whiteChar">: </span><span class="yellChar">"Summer collection"</span><br />
                {'   '}<span class="redChar">{'}'}</span><br />
                <span class="redChar">{'}'}</span></span></pre></div>
        </div>
    </main>
  </div>

  )
}

export default DeleteFile