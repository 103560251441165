import React from 'react';
import ReactDOM from 'react-dom';
import AppV4 from './AppV4';
import AppV3 from './AppV3';
import AppV2 from './AppV2';
import AppV4ReleaseNotes from './AppV4ReleaseNotes';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import NavbarV4 from './components/Version-4/Navbar-v4';
import NavbarV3 from './components/Version-3/Navbar-v3';
import NavbarV2 from './components/Version-2/Navbar-v2';

ReactDOM.render(
  <BrowserRouter>
    {(() => {
      if (window.location.pathname === '/v2-documentation') {
        return (
          <div id="content">
            <NavbarV2 />
            <AppV2 />
          </div>)
      } else if (window.location.pathname === '/v3-documentation') {
        return (
          <div id="content">
            <NavbarV3 />
            <AppV3 />
          </div>)
      } else if (window.location.pathname === '/v4_release-notes') {
        return (
          <div id="content">
           
            <AppV4ReleaseNotes />
          </div>)
      }else {
        return (
          <div id="content">
            {/* <NavbarV4 /> */}
            <AppV4 />
          </div>
        )
      }
    })()}
  </BrowserRouter>
  , document.getElementById('root')
);

reportWebVitals();
