import React from "react";
import { HashLink } from 'react-router-hash-link';
import {useStore} from 'react-context-hook'

const DataFeed = () => {
  const [langCode, setLangCode] = useStore("langCode",'');
  const textStyle = {
    textDecoration: 'underline',
    fontSize: '15px',
    fontFamily: 'Avenir',
    padding: '8px'
  }

  const schemaTextStyle = {
    textDecoration: 'underline',
    fontSize: '14px',
    fontFamily: 'Avenir',
    padding: '8px'
  }

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content firstContent">
        <h1 class="contentt" id="upload_data_feed">
          Pixyle API Documentation
        </h1>
        <div className="codeLang">
          <div className="positionCodeLang">
          
            {!/v2-documentation/.test(window.location.href) && !/v3-documentation/.test(window.location.href)  ? 
              <>
              <div id="lang" className={langCode=='' || langCode=="curl" ?`activeLang theCode lang` : ` theCode lang`}
                onClick={()=>{setLangCode('')}}
              >curl</div>
              <div id="lang1" className={langCode=='js'?`activeLang theCode lang` : ` theCode lang`}
                onClick={()=>{setLangCode('js')}}
              >JavaScript</div>
              <div id="lang2" className={langCode=='python'?`activeLang theCode lang` : ` theCode lang`}
                onClick={()=>{setLangCode('python')}}
              >Python3.X</div><br/>
              </> 
              :<div id="lang" className=' theCode lang'>curl</div> 

            }
          </div>
          {langCode=="python"?
          <div className="imports"><pre><span className=" theCode">import requests, json</span></pre> </div>:null}
        </div>
        
        <p class="content">
          Welcome to Pixyle’s official API Documentation. <br />
          This serves as a guide
          for developers who wish to integrate Pixyle’s visual AI technology into
          their digital assets. <br />
          To explore and implement Pixyle’s artificial
          intelligence-powered e-commerce solutions, you will need to be a
          verified developer. <br />
          Please contact us to get your user credentials.
        </p>
        <h2 class="content" id='upload-data-feed'>
          Upload your Data Feed
        </h2>
        <div class="content">
          <HashLink to={'/v4-documentation/#general_directions'}>
            <h4 class="content" id='general_directions'>
              General directions
            </h4>
          </HashLink>
        </div>
        <p class="content">
          For Pixyle's API to be able to perform reliable and accurate visual processing,
          please provide it with your product data feed in a ZIP, CSV or JSON format.
          The file should be UTF-8 coded. You can upload your file by using our
          Data API to insert, update or remove your data files. You can upload
          multiple files, where each of them will represent a dataset of its
          own.
          <br /> <br />
          <span class="noteText">
            * Please note that we don’t support upload of raw image files as a
            folder yet. You need to provide us with the images as a ZIP or URLs
            (under a field called image_url) in your data file. These image URLs
            must be publicly accessible so our servers can download them for
            processing.</span>
        </p>
        <div class="content">
          <HashLink to={'/v4-documentation/#organize_shema_fields'}>
            <h4 class="content" id='organize_shema_fields'>
              Organize schema fields
            </h4>
          </HashLink>
        </div>

        <h4 class="content" >
          ZIP
        </h4>

        <p class="content">
          Pixyle can work with ZIP files extremely easily. You can just zip any folder structure of image files, and upload it directly to the<a style={textStyle} href="#upload_file_create">create</a>endpoint. No additional requirements are needed. <br /> <br />
          You can skip the CSV/ JSON section if you are using a ZIP file.
        </p>

        <h4 class="content">
          CSV/JSON
        </h4>

        <p class="content">
          We recommend that you organize your data’s schema before uploading a
          file. A “schema” refers to the specific set of fields you will use
          to describe the collection of images. The schema organization is
          a must-have, so that you can upload your file. Please refer to the<a style={schemaTextStyle} href="#schema_fields_requirement">schema
            fields requirement</a>section for a detailed explanation.

          When you upload a file, our system will automatically identify its fields and start indexing your data.
        </p>
        <p class="content" >
          Templates for the data formats are avaliable here: <a style={textStyle} href="https://docs.google.com/spreadsheets/d/1RsH6Y_4HZFklK07GwwcXlN6HL0KCCVIH8-d9RbitqXQ/edit#gid=0">CSV</a>or <a style={textStyle} href="https://docs.google.com/document/d/1ikHnj5zOfXB6X0flCr1q2J3pWgx3sU73VOGFrXHXjgA/edit?usp=sharing">JSON</a>.

          <br /> <br />
          <span class="noteText"> *  Please note that the CSV file should be <b> semicolon </b>separeted. </span>
          <br /> <br />
        </p>


        <h4 class="content" >
          Schema fields requirements
        </h4>

        <p class="content">
          Before preparing your data feed for organizing the CSV/ JSON schema and upload, please read through the field requirements.
          Here are the necessary fields:
        </p>

        <table class="content" >
          <tbody>
            <tr>
              <th>Field</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>id</td>
              <td>string</td>
              <td>A unique identifier</td>
            </tr>
            <tr>
              <td>image_url_1</td>
              <td>string</td>
              <td>URL of the primary image of a product</td>
            </tr>
            <tr>
              <td>image_url_2..8</td>
              <td>string</td>
              <td>More image URLs of a product (max 7)</td>
            </tr>
          </tbody>
        </table>
        <p class="content noteText">
          * Note that image_url_2..8 fields are required but you can leave them with an empty value..
        </p>

        <h4 class="content">Recommended fields</h4>
        <p class="content">
          This list of fields in the schema below are not
          necessary, but in order to provide more comfort and functionalities
          during solution development, they are recommended.
        </p>
        <table class="content">
          <tbody>
            <tr>
              <th>Field</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>product_url</td>
              <td>string</td>
              <td>URL of a product</td>
            </tr>
            <tr>
              <td>product_title</td>
              <td>string</td>
              <td>Title of product</td>
            </tr>
            <tr>
              <td>category*</td>
              <td>string</td>
              <td>Category name of product</td>
            </tr>
            <tr>
              <td>category_id*</td>
              <td>int</td>
              <td>Category's ID</td>
            </tr>
            <tr>
              <td>description</td>
              <td>string</td>
              <td>Semantic representation of product</td>
            </tr>
            <tr>
              <td>gender</td>
              <td>string</td>
              <td>Gender type of product</td>
            </tr>
            <tr>
              <td>age_group</td>
              <td>string</td>
              <td>Age group of product</td>
            </tr>
            <tr>
              <td>size</td>
              <td>float/string</td>
              <td>Available sizes for product</td>
            </tr>
            <tr>
              <td>color</td>
              <td>string</td>
              <td>Color of product</td>
            </tr>
          </tbody>
        </table>

        <p class="content noteText">
          * Note that the category field is highly recommended. If no category is assigned, Pixyle will detect every fashion item present on the image alongside its category.
        </p>

        <h4 class="content">Customized fields</h4>

        <p class="content">
          Once the data feed is indexed, the API response can return all
          metadata provided per item. That means you are allowed to define
          customized schema fields in your file with additional data that you
          would like to receive. There is no limit to the number of fields you
          can add to the file and the supported field types are named below.
        </p>

        <table class="content">
          <tbody>
            <tr>
              <th>Type</th>
              <th>Explanation</th>
            </tr>
            <tr>
              <td>string</td>
              <td>
                A set of characters that can also contain spaces and numbers
              </td>
            </tr>
            <tr>
              <td>int</td>
              <td>Integer number, e.g. 0, 9, 100</td>
            </tr>
            <tr>
              <td>float</td>
              <td>Floating point number, e.g. 0.99, 99.99</td>
            </tr>
          </tbody>
        </table>
        <h4 class="content">Schema fields requirements - constraints</h4>

        <p class="content">
          There are format requirements for the schema fields' name, value and image.
        </p>

        <div class="content">
          <ul>
            <li>- image_url_1 should be an URL string for each product.</li>
            <li>
              - Field names can contain only letters from the English alphabet (A-Z, a-z), numbers (0-9), and underscore (_).
            </li>
            <li>- Field names are case sensitive.</li>
            <li>
              - Customized field name length must be within 32 characters.
            </li>
            <li>
              - Field value length must be within 5000 characters.
            </li>
            <li>
              - The supported image formats include: .bmp, .dib, .jpeg, .jpg,
              .jpe, .jp2, .png, .webp, .pbm, .pgm, .ppm, .sr, .ras, .tiff, .tif,
              .gif.
            </li>
          </ul>
        </div><br /><br /><br /><br /><br />
      </div>
    </main>
  </div>
  )

}

export default DataFeed