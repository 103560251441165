import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Search from '../Search';
import MobileSearch from '../MobileSearch';

import logo from '../../assets/logo.svg';
import '../../style/style.css'

const Navbar = () => {
  const [showAPI, setShowAPI] = useState(false)
  const [showIndex, setShowIndex] = useState(false)
  const [showSolutions, setShowSolutions] = useState(false)
  const [click, setClick] = useState(false);
  const [current, setCurrent] = useState('')

  const handleShowApi = () => setShowAPI(!showAPI)
  const handleShowIndex = () => setShowIndex(!showIndex)
  const handleShowSolutions = () => setShowSolutions(!showSolutions)
  const handleClick = () => setClick(!click);
  const ref = useRef()
  const history = useHistory()

  // useEffect(() => {
  //   const onBodyClick = event => {
  //     if (ref.current.contains(event.target)) {
  //       return
  //     }
  //     // else close the dropdown
  //     setShowAPI(false)
  //     setShowIndex(false)
  //     setShowSolutions(false)
  //   }
  //   document.body.addEventListener("click", onBodyClick)
  //   return () => {
  //     document.body.removeEventListener("click", onBodyClick)
  //   }
  // }, [])

  const sections = document.querySelectorAll('#upload-data-feed, #integrate_pixyle_solutions, h3')
  // console.log(sections, 123)
  const navLi = document.querySelectorAll('nav ul li')
  //console.log(navLi)

  window.addEventListener('scroll', () => {
    // console.log(window.pageYOffset, sections)
    sections.forEach(section => {
      // console.log(window.pageYOffset, section.offsetTop, section.getAttribute('id'))
      const sectionTop = section.offsetTop - 100 // possition for each section
      // const sectionHeight = section.clientHeight // the height of each section
      if (window.pageYOffset >= sectionTop &&
        (window.pageYOffset + 100) - sectionTop < 200 && current !== section.getAttribute('id')) {
        setCurrent(section.getAttribute('id'))
        //  window.location.hash = section.getAttribute('id')
      }
    })
  })

  function scrollToTargetAdjusted(element) {
    var headerOffset = 60;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  useEffect(() => {

    if (current === 'login' || current === 'errors') {
      setShowAPI(true)
      setShowIndex(false)
      setShowSolutions(false)
    } else if (current === 'upload_file_create' || current === 'dashboard') {
      setShowAPI(false)
      setShowIndex(true)
      setShowSolutions(false)
    } else if (current === 'automatic_tagging') {
      setShowAPI(false)
      setShowIndex(false)
      setShowSolutions(true)
    } else if (current === 'integrate_pixyle_solutions' || current === 'upload-data-feed') {
      setShowAPI(false)
      setShowIndex(false)
      setShowSolutions(false)
    }

    navLi.forEach(li => {
      li.classList.remove('test')
      if (li.classList.contains(current)) {
        li.classList.add('test')
      }
    })
  }, [current])



  const currentRoute = history.location.pathname.toLowerCase();

  const getColor = (curr) => {
    if (history.location.hash === curr) {
      return '#52489c'
    }
  }

  return (
    <div ref={ref} class="gridContainer">
      <header class="header">
        <Search />
      </header>
      <div id="sidenav" className="sidenav">
        <a id="logolink" href="https://www.pixyle.ai/">
          <img src={logo} className="logo" alt="Pixyle" />
        </a>
        <MobileSearch />
        <nav className="nav">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="upload-data-feed">
              <div className={history.location.hash.includes("#upload_data_feed") ? "left-navbar-selected-item" : ""}></div>
              <HashLink
                scroll={(el) => scrollToTargetAdjusted(el)}
                onClick={() => { handleClick(); setShowAPI(false); setShowSolutions(false); setShowIndex(false) }}
                to={'/v2-documentation#upload_data_feed'}
                style={{ color: getColor('#upload_data_feed') }}
              >Upload your data feed</HashLink></li>
            <li className="integrate_pixyle_solutions">
              <div className={history.location.hash.includes("#integrate_pixyle_solutions") ? "left-navbar-selected-item" : ""}></div>
              <HashLink
                scroll={(el) => scrollToTargetAdjusted(el)}
                onClick={() => { handleClick(); setShowAPI(false); setShowSolutions(false); setShowIndex(false) }}
                to={'/v2-documentation#integrate_pixyle_solutions'}
                style={{ color: getColor('#integrate_pixyle_solutions') }}
              >Integrate Pixyle Solutions</HashLink></li>
            <li className="nav-item">
              <div className={history.location.hash.includes("#api_overview") ? "left-navbar-selected-item" : ""}></div>
              <HashLink to={'/v2-documentation#api_overview'}
                onClick={() => { handleShowApi(); setShowIndex(false); setShowSolutions(false) }}
                style={{ color: getColor('#api_overview') }}
              >API Overview
                <i class={showAPI ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
              </HashLink>
              <ul className={showAPI ? "nav__submenu-close" : 'nav__submenu'}>
                <li className="login">
                  <div className={history.location.hash.includes("#login") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                    scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#login'}
                    style={{ color: getColor('#login') }}
                  >Login</HashLink></li>
                <li className="change_password">
                  <div className={history.location.hash.includes("#change_password") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                    scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#change_password'}
                    style={{ color: getColor('#change_password') }}
                  >Change Password</HashLink></li>
                <li className="authentication">
                  <div className={history.location.hash.includes("#authentication") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                    scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#authentication'}
                    style={{ color: getColor('#authentication') }}
                  >Authentication</HashLink></li>
                <li className="errors">
                  <div className={history.location.hash.includes("#errors") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                    scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#errors'}
                    style={{ color: getColor('#errors') }}
                  >Errors</HashLink></li>
              </ul>
            </li>
            <li className="nav-item">
              <div className={history.location.hash.includes("#index_your_data") ? "left-navbar-selected-item" : ""}></div>
              <HashLink to={'/v2-documentation#index_your_data'}
                onClick={() => { handleShowIndex(); setShowAPI(false); setShowSolutions(false) }}
                style={{ color: getColor('#index_your_data') }}
              >Index your data API
                <i class={showIndex ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
              </HashLink>
              <ul className={showIndex ? "nav__submenu-close" : 'nav__submenu'}>
                <li className="upload_file_create">
                  <div className={history.location.hash.includes("#upload_file_create") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                    scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#upload_file_create'}
                    style={{ color: getColor('#upload_file_create') }}
                  >Upload file - Create</HashLink></li>
                <li className="check_create_status">
                  <div className={history.location.hash.includes("#check_create_status") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                   scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#check_create_status'}
                    style={{ color: getColor('#check_create_status') }}
                  >Check Create Status</HashLink></li>
                <li className="delete_file">
                  <div className={history.location.hash.includes("#delete_file") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                    scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#delete_file'}
                    style={{ color: getColor('#delete_file') }}
                  >Delete File</HashLink></li>
                <li className="rename_file">
                  <div className={history.location.hash.includes("#rename_file") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                    scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#rename_file'}
                    style={{ color: getColor('#rename_file') }}
                  >Rename File</HashLink></li>
                <li className="dashboard">
                  <div className={history.location.hash.includes("#dashboard") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                    scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#dashboard'}
                    style={{ color: getColor('#dashboard') }}
                  >Dashboard</HashLink></li>
              </ul>
            </li>
            <li className="nav-item">
              <div className={history.location.hash.includes("#solutions") ? "left-navbar-selected-item" : ""}></div>
              <HashLink to={'/v2-documentation#solutions'}
                onClick={() => { handleShowSolutions(); setShowAPI(false); setShowIndex(false) }}
                style={{ color: getColor('#solutions') }}
              >Solutions
                <i class={showSolutions ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
              </HashLink>
              <ul className={showSolutions ? "nav__submenu-close" : 'nav__submenu'}>
                <li className="automatic_tagging">
                  <div className={history.location.hash.includes("#automatic_tagging") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                    scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#automatic_tagging'}
                    style={{ color: getColor('#automatic_tagging') }}
                  >1. Automatic Tagging</HashLink></li>
                <li className="scaling_images">
                  <div className={history.location.hash.includes("#scaling_images") ? "left-navbar-selected-subitem" : ""}></div>
                  <HashLink
                    scroll={(el) => scrollToTargetAdjusted(el)}
                    onClick={handleClick}
                    to={'/v2-documentation#scaling_images'}
                    style={{ color: getColor('#scaling_images') }}
                  >2. Scaling Images</HashLink></li>
              </ul>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </nav>

        <div id="versionDiv">
          <a id="v2Documentation4" href="/v4-documentation" className={currentRoute.includes("v4-documentation") ? "bold" : ""}>v4.0</a>
          <a id="v2Documentation3" href="/v3-documentation" className={currentRoute.includes("v3-documentation") ? "bold" : ""}>v3.0</a>
          <a id="v2Documentation" href="/v2-documentation" className={currentRoute.includes("v2-documentation") ? "bold" : ""}>v2.0</a>
        </div>
      </div>
    </div>
  )
}
export default Navbar;