import React from "react";
import { useStore } from "react-context-hook";
const Login = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content">
        <div class="content"><br/>
          <h2 class="content" id="api_overview">
            API Overview
          </h2><br/>
          <h3 class="content" id = ' '>
            Introduction
          </h3><br/>
          <p class="content">
            Pixyle’s API is accurate, powerful and integrates seamlessly with any tech stack. It provides endpoints that allow developers to index their data and use our solutions efficiently. Here you can find detailed guidelines on how to use Pixyle's API. We are continuously improving the accuracy of our Visual AI technology and all solutions, and releasing new versions twice a year. At any release, the API documentation will be updated.
          </p><br/>
          <h3 class="content" id="login">
            Login
          </h3>
          <br />
          <p class="content">
            After we give you your username and password, you can use them to log in on this endpoint. As a response you will get access_token and refresh_token, so later you can use them on other endpoints.
          </p>
          <div class="content">
            <pre class="mainLink">POST /v4/users/login</pre>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
        </div>
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>username</td>
                <td>The username you are provided with</td>
              </tr>
              <tr>
                <td>password</td>
                <td>The password you are provided with</td>
              </tr>
            </tbody>
          </table>
        </div>
        {langCode== 'js' ? 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/users/login"</span>, {'{'}<br />
              {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
              {'       '}<span className="blue">headers</span>: {'{'}<span className="txt"> "Content-Type"</span>: <span className="txt">"application/json" </span>{'}'},<br />
              {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>({'{'} <span className="txt">"username"</span>: <span className="txt">"FrontEnd"</span>, <span className="txt">"password"</span>: <span className="txt">"password"</span> {'}'})<br />
              {'     '}{'}'})<br />
            </pre>
          </div>
        : langCode == "python" ?
        <>
          <div class=" theCode mob-import">
            <div className="mob-import"><pre><span className="">import requests, json</span></pre> </div>
          </div>

          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
             <span class="grayChar">$ </span>
             headers = {'{'}<span className="txt"> "Content-Type"</span>: <span className="txt">"application/json"</span> {'}'}<br />
             {'  '}data = {'{'} <span className="txt">"username"</span>:<span className="txt"> "FrontEnd"</span>, <span className="txt">"password"</span>: <span className="txt">"password"</span> {'}'}<br/>
             {'  '}requests.post( <span className="txt">"https://pva.pixyle.ai/v4/users/login"</span>, <br />
             {'       '}<span className="blue">headers</span> = headers,<br />
             {'       '}<span className="blue">data</span> = json.dumps(data)<br />
             {'     '})<br />
            </pre>
          </div>
        </>
        : <div class=" theCode">
          <pre><span class="grayChar"># example request</span> <br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \  <br />
            {'      '} --data '{'{'}"username":"FrontEnd","password":"password"{'}'}' \ <br />
            {'      '} -X POST https://pva.pixyle.ai/v4/users/login
          </pre>
        </div>}
        
        
        
        <br />
        <h4 class="content unbold">Response properties</h4>
        <br />
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>access_token</td>
                <td>A unique token for the user, to use for authorization</td>
              </tr>
              <tr>
                <td>expires_in</td>
                <td>Number of seconds until token expires </td>
              </tr>
              <tr>
                <td>refresh_token</td>
                <td>A refresh token, to get new access token, when the old one is expired</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" theCode">
          <pre><span class="grayChar"># example JSON response</span><br /><br />
            <span class="redChar">{'{'}</span><br />
            {'    '}<span class="orangeChar">"access_token"</span>: <span class="yellChar">"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE...5Kxsf8txew3c"</span>,<br />
            {'    '}<span class="orangeChar">"expires_in"</span><span class="whiteChar">:</span> <span class="whiteChar">86400</span><br />
            {'    '}<span class="orangeChar">"refresh_token"</span>: <span class="yellChar">"eyJ0eXAiOiJKV1QiLCbGciOiJIUzI1NiJ9.eyJpYXQiOj...6cmfmjFbu6mHE2g"</span><br />
            <span class="redChar">{'}'}</span></pre>
        </div>
        <h4 class="content" id="refresh-token">
          Refresh Token
        </h4>
        <div class="content">
          <div class="content">
            <pre class="mainLink">GET /v4/users/refresh_token</pre>
          </div>
          <p class="content">
            A regular access token has a TTL of 86400 seconds. Once expired, you can log in the system again to get a new pair of tokens or you can use this endpoint to refresh your access token.  An access token can be refreshed by using the refresh token that came with the access token. This can be done before or after the access token expires.
            <br />
            <br />
            As a response you will get a new access_token, so later you can use it on other endpoints.
            <br />
            <br />
            Please note that a refresh token has a TTL of 10 days.
            <br />
          </p>
        </div>
        <h4 class="content unbold">Request Parameters</h4>
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>refresh_token</td>
                <td>A unique refresh token, that you were provided with</td>
              </tr>
            </tbody>
          </table>
        </div>
        
        {langCode== 'js' ? 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
                <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/users/refresh_token"</span>, {'{'}<br />
                {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
                {'       '}<span className="blue">headers</span>: {'{'}<span className="txt"> "Content-Type"</span>: <span className="txt">"application/json"</span>,<br />
                {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>refresh_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
                {'     '}{'}'})<br />
            </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Content-Type"</span>: <span className="txt">"application/json"</span>,<br />
            {'              '}<span className="txt">"Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>refresh_token</span><span className='letBlue'>{'}'}</span><span className='txt'>"</span> {'}'}<br />
            {'  '}requests.post( <span className="txt">"https://pva.pixyle.ai/v4/users/refresh_token"</span>, <br />
            {'       '}<span className="blue">headers</span> = headers <br/>
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
              {'      '} -H 'Authorization: Bearer refresh_token' \<br />
              {'      '} -X POST https://pva.pixyle.ai/v4/users/refresh_token</pre>
            </div>
          }
        
    
        <h4 class="content unbold">Response Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>access_token</td>
              <td>
                A unique access token for the user, to use for authorization
              </td>
            </tr>
            <tr>
              <td>expires_in</td>
              <td>Number of seconds until token expires </td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"refresh token"</span><span class="whiteChar">,</span><br />
              {'  '}"access_token"<span class="whiteChar">:</span> <span class="yellChar">"eJbGciOiJIUzeyJ0eXAiOiJKV1QiLCI1NiJ9.eyJpYXQiOjE...5Kxsf8txew3c"</span><span class="whiteChar">,</span><br />
              {'  '}"expires_in"<span class="whiteChar">:</span> <span class="whiteChar">86400</span><br />
              <span class="redChar">{'}'}</span>
            </span></pre></div>
        <h4 class="content" id="permanent-token">
          Permanent Token
        </h4>
        <div class="content">
          <div class="content">
            <pre class="mainLink">POST /v4/users/login?permanent=true</pre>
          </div>
          <p class="content">
            If you don’t want to use the authentication flow everytime a token expires, you can use this endpoint to get a permanent token. This means once you get the permanent token, there will be no need for you to log in again. You can use this token, in each of the other endpoints in a server-side request.
            <br />
            <br />
            To get the permanent token, please use the same credentials for the login.
            <br />
            <br />
          </p>
        </div>
        <h4 class="content unbold">Request Parameters</h4>
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>username</td>
                <td>The username you are provided with</td>
              </tr>
              <tr>
                <td>password</td>
                <td>The password you are provided with</td>
              </tr>
            </tbody>
          </table>
        </div>
        {langCode== 'js' ? 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
               <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/users/login?permanent=true"</span>, {'{'}<br />
               {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
               {'       '}<span className="blue">headers</span>: {'{'}<span className="txt"> "Content-Type"</span>: <span className="txt">"application/json"</span> {'}'},<br />
               {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>({'{'} <span className="txt">"username"</span>: <span className="txt">"FrontEnd"</span>, <span className="txt">"password"</span>: <span className="txt">"password"</span> {'}'})<br/>
               {'     '}{'}'})<br />
           </pre>
         </div>
          : langCode == "python" ?  
          <div class=" theCode">
            
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
              headers = {'{'} <span className="txt">"Content-Type"</span>: <span className="txt">"application/json"</span> {'}'}<br />
             {'  '}data = {'{'} <span className="txt">"username"</span>: <span className="txt">"FrontEnd"</span>, <span className="txt">"password"</span>: <span className="txt">"password"</span> {'}'}<br/>
             {'  '}requests.post( <span className="txt">"https://pva.pixyle.ai/v4/users/login?permanent=true"</span>, <br />
             {'       '}<span className="blue">headers</span> = headers,<br />
             {'       '}<span className="blue">data</span> = json.dumps(data)<br />
             {'     '})<br />
            </pre>
          </div>
          : 
            <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br />
              {'      '} --data '{'{'}"username":"FrontEnd","password":"password"{'}'}' \<br />
              {'      '} -X POST https://pva.pixyle.ai/v4/users/login?permanent=true</pre>
          </div>
          }
     
  
     
        <h4 class="content unbold">Response Parameters</h4>
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>access_token</td>
                <td>
                  A unique permanent token for the user, to use for authorization
                </td>
              </tr>
              <tr>
                <td>expires_in</td>
                <td>Number of seconds until token expires / Boolean field that shows if the token will expire</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"access_token"<span class="whiteChar">:</span> <span class="yellChar">"eJbGciOiJIUzeyJ0eXAiOiJKV1QiLCI1NiJ9.eyJpYXQiOjE...5Kxsf8txew3c"</span><span class="whiteChar">,</span><br />
              {'  '}"expires_in"<span class="whiteChar">:</span> <span class="yellChar">false</span><br />
              <span class="redChar">{'}'}</span>
            </span></pre></div>
      </div>
    </main>
  </div>

  )
}

export default Login