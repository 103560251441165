import UploadDataFeed from './components/Version-4/UploadDataFeed'
import IntegratePixyleSolutions from './components/Version-4/IntagratePixyleSolutions'
import Login from './components/Version-4/Login';
import ChangePassword from './components/Version-4/ChangePassword';
import Authentication from './components/Version-4/Authentication';
import Errors from './components/Version-4/Errors';
import UploadFile from './components/Version-4/UploadFile';
import RestartUpload from './components/Version-4/ReastartUpload';
import CheckUploadStatus from './components/Version-4/CheckUploadStatus';
import UpgradeDataset from './components/Version-4/UpgradeDataset';
import RenameFile from './components/Version-4/RenameFile';
import DeleteFile from './components/Version-4/DeleteFile';
import DeleteErrorObject from './components/Version-4/DeleteErrorObject'
import DeleteProduct from './components/Version-4/DeleteProduct';
import Dashboard from './components/Version-4/Dashboard';
import VisualSearch from './components/Version-4/VisualSearch';
import AutomaticTagging from './components/Version-4/AutomaticTagging';
import AutomaticRescale from './components/Version-4/AutomaticRescale';
import ShotType from './components/Version-4/ShotTypeDetection';
import SimilarRecoomendations from './components/Version-4/SimilarRecommendations';
import { withStore } from 'react-context-hook';
import NavbarV4 from "./components/Version-4/Navbar-v4";
import BackgroundRemoval from './components/Version-4/BackgroundRemoval';
import LabelRecognition from './components/Version-4/Label Recognition';
function App() {
  return (
    <div id='content'>
      <NavbarV4/>
      <UploadDataFeed/>
      <IntegratePixyleSolutions/>
      <Login/>
      <ChangePassword/>
      <Authentication/>
      <Errors/>
      <UploadFile/>
      <RestartUpload/>
      <CheckUploadStatus/>
      <UpgradeDataset/>
      <RenameFile/>
      <DeleteFile/>
      <DeleteProduct/>
      <DeleteErrorObject/>
      <Dashboard/>
      <VisualSearch/>
      <AutomaticTagging/>
      <AutomaticRescale/>
      <ShotType/>
      <SimilarRecoomendations/>
      <LabelRecognition/>
      <BackgroundRemoval/>
    </div>
  );
}

export default withStore( App);
