import React from "react";
import { useStore } from "react-context-hook";

const DeleteFile = () => {
  const [langCode, ] = useStore("langCode",'');

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
        <h3 class="content" id="delete_file">
          Delete File
        </h3>

        <div class="content">
          <div class="content">
            <pre class="mainLink">DELETE /v2/delete/{'{dataset_id}'}</pre>
          </div>

          <p class="content">
            This API endpoint allows you to delete a certain dataset. When deleting a dataset, all its images are also deleted, so you can not use them anymore. This process may take up to few minutes, depending on the number of images, so please wait for the API response.
          </p>
        </div>

        <div class=" theCode">
          <pre>
          <span class="grayChar"># example request</span><br /><br />
          {langCode== 'js' ? 
          <> 
          <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/delete/1570624498775133" , {'{'}<br />
          {'       '}method: "DELETE",<br />
          {'       '}headers: {'{'}"Authorization": "Bearer access_token"{'}'}<br />
          {'     '}{'}'})<br /> <br/>
         
          </>
          : langCode == "python" ?  
              <div>
                  <span class="grayChar">$ </span>requests.delete("https://pva.pixyle.ai/v2/delete/1570624498775133<br/>
                  {'       '}headers= {'{'}"Authorization": "Bearer access_token"{'}'}<br />
                  {'     '})<br />
              </div> 
          : 
          <>
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br/>
            {'       '}-X DELETE https://pva.pixyle.ai/v2/delete/1570624498775133<br/><br/>
          </>
          }<br />
            <span class="grayChar"># example JSON response</span><br/><br/>
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br/>
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br/>
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"delete"</span><span class="whiteChar">,</span><br/>
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br/>
              <span class="redChar">{'}'}</span></span></pre></div><br/>
      </div>
    </main>
  </div>

  )
}

export default DeleteFile