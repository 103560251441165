import UploadDataFeedV2 from './components/Version-2/UploadDataFeed-v2'
import IntegratePixyleSolutionsV2 from './components/Version-2/IntegratePixyleSolutions-v2'
import LoginV2 from './components/Version-2/Login-v2'
import ChangePasswordV2 from './components/Version-2/ChangePassword-v2';
import AuthenticationV2 from './components/Version-2/Authentication-v2';
import ErrorsV2 from './components/Version-2/Error-v2';
import UploadFileV2 from './components/Version-2/UploadFile-v2';
import CheckCreateStatusV2 from './components/Version-2/CheckCreateStatus';
import DeleteFileV2 from './components/Version-2/DeleteFile-v2';
import RenameFileV2 from './components/Version-2/RenameFile-v2';
import DashboardV2 from './components/Version-2/Dashboard-v2';
import AutomaticTaggingV2 from './components/Version-2/AutomaticTagging-v2';
import ScalingImages from './components/Version-2/ScalingImages';
import { withStore } from 'react-context-hook';
function App() {
  return (
    <div id='content'>
      <UploadDataFeedV2/>
      <IntegratePixyleSolutionsV2/>
      <LoginV2/>
      <ChangePasswordV2/>
      <AuthenticationV2/>
      <ErrorsV2/>
      <UploadFileV2/>
      <CheckCreateStatusV2/>
      <DeleteFileV2/>
      <RenameFileV2/>
      <DashboardV2/>
      <AutomaticTaggingV2/>
      <ScalingImages/>
    </div>
  );
}

export default withStore(App);
