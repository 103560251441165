import React from "react";
import { useStore } from "react-context-hook";
const UpgradeDataset = () => {
  const [langCode, ] = useStore("langCode",'');

  const textStyle = {
    textDecoration: 'underline',
    fontSize: '14px',
    fontFamily: 'Avenir',
    padding: '8px'
  }

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
      <h3 class="content" id="upgrade_dataset">
          Upgrade Dataset
        </h3>
        <div class="content">
          <pre class="mainLink">POST /v4/datasets/<i>{'{dataset_id}'}</i></pre>
        </div>
        <p class="content">
          Once your dataset is created or initialized with a solution, there is an option to upgrade it. Choose the dataset_id you want to upgrade, create and structure a file from the products you want to add, and upload it on this endpoint. This will add the products from the new file to the existing ones in the specified collection. <br />
        </p>
        <div class="content">
          <p class="content">Please note these guidelines:</p>
          <p class="content">
            &emsp;1.&nbsp; Create an additional file - one successfully uploaded or processed collection can only be upgraded with the same file type it was created with (eg. CSV file collection can be upgraded only with a CSV file);
          </p>
          <p class="content">
            &emsp;2.&nbsp; Upload the additional file to the chosen dataset;
          </p>
          <p class="content">
            &emsp;3.&nbsp; Pixyle's API starts to download and add the additional file to the original dataset;
          </p>
          <p class="content">
            &emsp;4.&nbsp; At this point the new images are downloaded by Pixyle but not initialized with any solution;
          </p>
          <p class="content">
            &emsp;5.&nbsp; If the original dataset is already initialized with a solution, you should initialize it again for the new products, so that Pixyle can start processing them.
          </p>
        </div>
        <br />
        <h4 class="content unbold">Request Parameters</h4>
        <br />
        <div class="content" >
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for a certain data file you want to upgrade</td>
              </tr>
              <tr>
                <td>file</td>
                <td>A valid  CSV/JSON file, which follows Pixyle’s<a style={textStyle} href="#schema_fields_requirement">Schema fields requirement</a>or a ZIP file </td>
              </tr>
            </tbody>
          </table>
          <br />
          <h4 class="content unbold">Response properties</h4>
          <br />
        </div>

        {langCode== 'js' ? 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            {/* <span className="letBlue">let</span> <span className="blue">files</span>= <span className="txt">'@images_addition.csv'</span><br /> */}
            <span className="letBlue">const</span> <span className="blue">formData</span> = <span className="letBlue">new</span> <span className="green">FormData()</span>;<br />
            {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>(<span className="txt"> "file"</span>, <span className="blue">file</span> );<br />
            {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133"</span>, {'{'}<br />
            {'       '}<span className="blue">method</span>:<span className="txt"> "POST"</span>,<br />
            {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>: <span className="txt">"multipart/form-data"</span>,<br />
            {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>` </span>{'}'},<br />
            {'       '}<span className="blue">body</span>: <span className="letBlue">FormData</span><br />
            {'     '}{'}'})<br />
          </pre>
        </div>
          : langCode == "python" ?  
            <div class=" theCode">
             <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>
              headers = {'{'}
                  <span className="txt"> "Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>" </span>
                   {'}'} <br/>
              {'  '}files = {'{'} 
              <span className="txt"> "file"</span>: ( <span className="txt">"images_addition.csv"</span>,<br/>
              {'            '}<span className="yellow">open</span>(<span className="txt"> "images_addition.csv"</span>, <span className="txt">"rb" </span>), )
                          {'}'}<br/>
              
              {'  '}response = requests.post( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133"</span>,<br/> 
              {'       '}<span className="blue">headers</span> = headers,<br />
              {'       '}<span className="blue">files</span> = files <br />
              {'       '})
              </pre>
          </div>
          :
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
            {'      '} -H 'Authorization: Bearer access_token' \<br />
            {'      '} -F 'file=@images_addition.csv' \<br />
            {'      '} -X POST https://pva.pixyle.ai/v4/datasets/1570624498775133</pre>
        </div>
          }

        <div class="content" >
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for the data file</td>
              </tr>
              <tr>
                <td>dataset_count</td>
                <td>Number of datasets by this user</td>
              </tr>
              <tr>
                <td>collection_name</td>
                <td>
                  Name of the collection
                </td>
              </tr>
              <tr>
                <td>addition_collection_name</td>
                <td>The name of the added file</td>
              </tr>
              <tr>
                <td>addition_counter_products</td>
                <td>Number of products in the added dataset file</td>
              </tr>
              <tr>
                <td>addition_counter_images</td>
                <td>
                  Number of images in the added dataset file
                </td>
              </tr>
              <tr>
                <td>counter_products</td>
                <td>
                  Number of products in the existing dataset
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="redChar">{'{'}</span><br />
            {'  '}<span class="orangeChar">"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"upgrade"</span><span class="whiteChar">,</span><br />
              {'  '}"message"<span class="whiteChar">: </span> <span class="yellChar">"Addition file uploaded successfully, please check the status on /v4/datasets/&lt;dataset_id&gt;/status"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"dataset_count"<span class="whiteChar">: 2,</span><br />
              {'  '}"meta"<span class="whiteChar">: </span> <span class="redChar">{'{'}</span><br />
              {'    '}"addition_counter_products"<span class="whiteChar">: 100,</span><br />
              {'    '}"addition_counter_images"<span class="whiteChar">: 200,</span><br />
              {'    '}"addition_collection_name"<span class="whiteChar">: </span><span class="yellChar">"images_addition.csv"</span><span class="whiteChar">,</span><br />
              {'    '}"counter_products"<span class="whiteChar">: 1000,</span><br />
              {'    '}"collection_name"<span class="whiteChar">:</span><span class="yellChar"> "images.csv"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span>
            </span>
          </pre>
        </div>
      </div>
    </main>
  </div>

  )
}

export default UpgradeDataset