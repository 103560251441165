import React from "react";
import { useStore } from "react-context-hook";

const UploadFile = () => {
  const [langCode, ] = useStore("langCode",'');

  const textStyle = {
    textDecoration: 'underline',
    fontSize: '14px',
    margin: '0px',
    padding: '0px'
  }

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
      <h2 class="content" id="index_your_data">
          Index your data API
        </h2>
        <p class="content">
          Indexing defines the way we represent images and how we make your images available for processing.Pixyle provides API endpoints for you to be able to manage your data feed (image collections).To add a data file, you call a specific endpoint with a file and a token.You can also remove a certain data file and all its images.Please note, you need to structure your file correctly before uploading it.
        </p>
        <p class="content noteText">
          * Please note how to create and <a style={textStyle} href="#organize_schema_fields">organize your data file</a> before uploading one.
        </p>
        <h3 class="content" id="upload_file_create">Upload file - Create</h3>
        <div class="content">
          <div class="content">
            <pre class="mainLink">POST /v4/datasets</pre>
          </div>
          <p class="content">
            This API endpoint is for uploading a data file to create a new dataset for your image collection.
          </p>
          <br />
          <h4 class="content unbold">Request parameters</h4>
          <br />
          <p class="content">
            The requested parameter for the upload to be successful is a file with images data.It must be in the following format:
          </p>
          <div class="content">
            <pre class="mainLink">{'{key}'} = value</pre>
          </div>
          <p class="content">
            Where key is 'file', and value is the file.
          </p>
        </div>
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>file</td>
                <td>
                  A valid data file, which follows <a style={textStyle} href="#organize_schema_fields">Pixyle’s Schema fields requirement</a>.
                </td>
              </tr>
            </tbody>
          </table>
          <p class="content">
            The maximum length of the file name is 128.
          </p>
          <p class="content">
            The maximum file size is 30MB.
          </p>
          <br />
          <p class="content"> When you create a dataset, Pixyle starts to download it.There is one main limitation about the images in a dataset: <br />
            &nbsp; - &nbsp; For optimal and accurate results, we recommend images with good resolution, therefore images should not be extremely small (50x50) or extremely big (bigger than 8192x8192).
          </p>
          <br />
          <h4 class="content unbold">Response properties</h4>
        </div>
        {langCode== 'js' ? 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>
              {/* <span className="letBlue">let</span> <span className="blue">files</span>= <span className="txt">'@images.csv'</span><br /> */}
              <span className="letBlue">const</span> <span className="blue">formData</span> = <span className="letBlue">new</span> <span className="green">FormData()</span>;<br />
              {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>( <span className="txt">"file"</span>, <span className="blue">file</span> );<br />
              {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets"</span>, {'{'}<br />
              {'       '}<span className="blue">method</span>:<span className="txt"> "POST"</span>,<br />
              {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>: <span className="txt">"application/json"</span>,<br />
              {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>` </span>{'}'},<br />
              {'       '}<span className="blue">body</span>: <span className="letBlue">FormData</span>  <br />
              {'     '}{'}'})<br />
            </pre>
          </div>
          : langCode == "python" ?  
            <div class=" theCode">
              <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span>
              headers = {'{'}
                  <span className="txt"> "Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>" </span>
                  {'}'} <br/>
              {'  '}files = {'{'}
              <span className="txt"> "file"</span>: ( <span className="txt">"images.csv"</span>, <span className="yellow">open</span>( <span className="txt">"images.csv"</span>, <span className="txt">"rb"</span> ), )
                          {'}'}<br/>
              
              {'  '}response = requests.post( <span className="txt">"https://pva.pixyle.ai/v4/datasets"</span>,<br/>
              {'       '}<span className="blue">headers</span> = headers,<br />
              {'       '}<span className="blue">files</span> = files <br />
              {'       '})
              </pre>
            </div>
          :
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
              {'       '}-H 'Authorization: Bearer access_token' \<br />
              {'       '}-F 'file<span class="redChar">=</span>@images.csv' \<br />
              {'       '}-X POST https://pva.pixyle.ai/v4/datasets
            </pre></div>
          }
        <div class="content">
          <table class="content" style={{marginBottom: '10px'}}>
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id *</td>
                <td>
                  A unique identifier for the uploaded and created data file
                </td>
              </tr>
              <tr>
                <td>dataset_count</td>
                <td>
                  Number of datasets by this user
                </td>
              </tr>
              <tr>
                <td>collection_name</td>
                <td>
                  Name of the collection
                </td>
              </tr>
              <tr>
                <td>counter_products</td>
                <td>
                  Number of products in this dataset
                </td>
              </tr>
              <tr>
                <td>counter_images</td>
                <td>
                  Number of images in this dataset
                </td>
              </tr>
            </tbody>
          </table>
          <p class="content noteText">
            * Note the dataset_id, it will be necessary for other endpoints
          </p>
        </div>
        <div class=" theCode">
          <pre><span class="grayChar"># example JSON response</span><br /><br />
            <span class="redChar">{'{'}</span><br />
            {'  '}<span class="orangeChar">"status"</span>: <span class="yellChar">"OK"</span>,<br />
            {'  '}<span class="orangeChar">"method"</span>: <span class="yellChar">"create"</span>,<br />
            {'  '}<span class="orangeChar">"message"</span><span class="whiteChar">: </span> <span class="yellChar">"Dataset created successfully, please check the status of downloading data from the uploaded dataset on /v4/datasets/{'{dataset_id}'}/status"</span><span class="whiteChar">,</span><br />
            {'  '}<span class="orangeChar">"dataset_id"</span>: <span class="whiteChar"> 1570624498775133,</span><br />
            {'  '}<span class="orangeChar">"dataset_count"</span>: 2,<br />
            {'  '}<span class="orangeChar">"meta"</span>: <br />
            {'    '}<span class="redChar">{'{'}</span><br />
            {'     '}<span class="orangeChar">"counter_products"</span>: 1000,<br />
            {'     '}<span class="orangeChar">"counter_images"</span>: 4000,<br />
            {'     '}<span class="orangeChar">"collection_name"</span>: <span class="yellChar">"images.csv"</span><br />
            {'    '}<span class="redChar">{'}'}</span><br />
            <span class="redChar">{'}'}</span></pre></div>
      </div>
    </main>
  </div>

  )
}

export default UploadFile