import React, { useState } from "react";
import Mark from "mark.js";

const Search = () => {
  const [search, setSearch] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentClass = "marked-scroll";

  const jumpTo = (index) => {
    const markedWords = document.querySelectorAll("mark");

    if (markedWords.length) {
      const currentWord = markedWords[index];
      // Remove all classes from all marked words
      markedWords.forEach((markedWord) => {
        markedWord.classList.remove(currentClass);
      });

      if (currentWord) {
        currentWord.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start"
        });
        currentWord.classList.add(currentClass);
      }
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    const element = document.querySelector("#content");
    const markInstance = new Mark(element);
    markInstance.unmark({
      done: () => {
        markInstance.mark(event.target.value);
        jumpTo(0);
        setCurrentIndex(0);
      }
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const markedWords = document.querySelectorAll("mark");
      let nextIndex = currentIndex + 1;
      if (currentIndex > markedWords?.length -2) {
        nextIndex = 0;
      }
      
      jumpTo(nextIndex);
      setCurrentIndex(nextIndex);
    }
  };

  return (
    <input
      className="searchField"
      type="text"
      placeholder="Search"
      aria-label="Search field"
      value={search}
      onChange={handleSearch}
      onKeyPress={handleKeyPress}
    />
  );
};

export default Search;