import React from "react";
const WhatIsNew = () => {
    return (
        
        <div className="gridContainer">
        <main className="mainContainer">
        <div className=" js-toc-content js-toc-content notes">
            
             {/* whats new */}
            <div id="whats_new">
                <h1 className="content notes"  id="title">
                    Pixyle API Release Notes Version 4
                </h1>
                <br/>
                <h2 className="content"  >
                What's New?
                </h2>
            </div>
            <br/>
            <p className="content notes" style={{fontWeight:"300"}}>
                <a href="#all_changes" 
                    style={{textDecoration:"underline", paddingLeft:" 0px !important", fontWeight:"700"}}
                    >Taxonomy changes</a>and
                    <a href="#imporvements" 
                    style={{textDecoration:"underline", paddingLeft:" 0px !important", fontWeight:"700"}}
                    >improvements</a>
                      in the AI model:
                <li className="content notes li">
                    &#x2022; &nbsp;15% improved accuracy on all categories compared to the previous one;
                </li>
                <li className="content notes li">
                    &#x2022; &nbsp;13 new categories, 3 removed categories, 2 renamed categories, <br /> 
                    &nbsp; &nbsp; 4 new attributes, 2 removed attributes, 4 renamed attributes<br />
                    &nbsp; &nbsp; and several regroupings in attributes types.
                </li>
            </p>
            <br/>
            </div>
        </main>
    </div>
           
        
    )
  }
  
  export default WhatIsNew