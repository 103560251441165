import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useStore } from "react-context-hook";
import Modal from "./ImgModal";
const AutomaticTagging = () => {
  const [langCode] = useStore("langCode", "");
  const [openModal, setOpenModal] = useState(false);
  const [img, setImg] = useState("");

  return (
    <div class="gridContainer">
      <main class="mainContainer">
        <div class="mainContent js-toc-content">
          <br />
          <h4 class="content">For your business</h4>
          <h3 class="content" id="automatic_tagging">
            2. Automatic Tagging
          </h3>
          <div className="content" id="at">
            <p>
              Pixyle's automatic tagging solution automates and improves the
              catalogue management process. In order to integrate automatic
              tagging solution you could follow this basic flow.{" "}
            </p>
            <ul>
              <li className="diagram-a">
                <a href="#login"> 1. Login</a>
              </li>
              <li className="diagram-a">
                <a href="#upload_file_create">2. Upload file </a>
              </li>
              <li className="diagram-a">
                <a href="#restart_upload" style={{ marginLeft: "0.8rem" }}>
                  2.1 Restart Upload (if needed){" "}
                </a>
              </li>
              <li className="diagram-a">
                <a href="#check_upload_status">3. Check Upload Status </a>
              </li>
              <li className="diagram-a">
                <a href="#automatic_tagging">
                  4. Automatic Tagging - Start Solution{" "}
                </a>
              </li>
              <li className="diagram-a">
                <a href="#solution-status">
                  5. Automatic Tagging - Solution Status{" "}
                </a>
              </li>
              <li className="diagram-a">
                <a href="#solution-status">
                  6. Automatic Tagging - Solution Status (If verification is
                  used){" "}
                </a>
              </li>
              <li className="diagram-a">
                <a href="#download-file">
                  7. Automatic tagging - Download File{" "}
                </a>
              </li>
            </ul>
            <p>
              The Automatic-tagging sequential diagram can be seen{" "}
              <span
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  setImg("at");
                  setOpenModal(true);
                }}
              >
                here.
              </span>
            </p>
            <p style={{ marginTop: "1rem" }}>
              Below, you can find more details regarding the solution endpoints.
            </p>
          </div>

          <div class="content">
            <h4
              class="content"
              id="taxonomy-versions"
              style={{ paddingTop: "2.5rem" }}
            >
              Taxonomy versions
            </h4>
            <br />
            <p>
              With using different taxonomy versions you will receive the tags
              from different taxonomy trees. You can switch different taxonomy
              versions by providing the X-Taxonomy-Version header in the request
              headers. The possible values for now are:
            </p>
            <ul>
              <li>- 4.0</li>
              <li>- 4.1</li>
              <li>- 4.2</li>
            </ul>
            <p>
              You can find more information about the taxonomies on the
              following{" "}
              <a
                style={{
                  textDecoration: "underline",
                  fontSize: "15px",
                  padding: "0",
                }}
                target="_blank"
                href="https://fashion-taxonomy.pixyle.ai/en"
              >
                link
              </a>
            </p>
          </div>
          <p class="content">
            <HashLink to={"/v4-documentation/automatic-tagging#start-solution"}>
              <h4 class="content" id="start-soluition">
                Start Solution
              </h4>
            </HashLink>
            <br />
            Pixyle's automatic tagging solution automates and improves the
            catalogue management process. You can use it by simply uploading
            your image collection and wait for Pixyle to do the tagging. After a
            short amount of time, you will get a JSON or CSV as a response,
            filled with accurate and rich tags associated with your images.
          </p>
          <div class="content">
            <pre class="mainLink">
              GET /v4/datasets/<i>{"{dataset_id}"}</i>/solutions/auto-tag
            </pre>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
          <div className="content">
            <table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>dataset_id</td>
                  <td>
                    A unique identifier for a certain data file, to start the
                    auto-tag process
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"         "}/solutions/auto-tag"
                </span>
                , {"{"}
                <br />
                {"         "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"GET"</span>,<br />
                {"         "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> ,<br />
                {"             "}
                <span className="txt">"X-Taxonomy-Version"</span>:
                <span className="txt">'4.2'</span> {"}"}
                <br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"}
                <span className="txt"> "Authorization"</span>:
                <span className="letBlue"> f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">
                  {"}"}
                  <span className="txt">" </span>
                </span>
                ,<br />
                {"  "} <span className="txt"> "X-Taxonomy-Version"</span>:{" "}
                <span className="txt">'4.2'</span> {"}"}
                <br />
                {"  "}requests.get({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag"
                </span>
                , <br />
                {"       "}
                <span className="blue">headers</span> = headers
                <br />
                {"     "})<br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Authorization: Bearer
                access_token' \<br />
                {"       "}-H 'X-Taxonomy-Version : 4.2'
                <br />
                {"       "}-X GET
                https://pva.pixyle.ai/v4/datasets/1570624498775133/solutions/auto-tag
              </pre>
            </div>
          )}
          <br />
          <h4 class="content unbold">Response properties</h4>
          <br />
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for the data file</td>
              </tr>
              <tr>
                <td>collection_name</td>
                <td>Name of the collection</td>
              </tr>
              <tr>
                <td>start_time</td>
                <td>The time when the automatic tagging process has started</td>
              </tr>
            </tbody>
          </table>
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"autotag"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"message"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">
                  "Automatic tagging has started, please check status on
                  /v4/datasets/1570624498775133/solutions/auto-tag/status
                </span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"meta"<span class="whiteChar">: </span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"    "}"collection_name"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"images.csv"</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"user"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"FrontEnd"</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"start_time"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"Tue, 27 Dec 2019 09:23:36"</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <br />
                <span class="redChar">{"}"}</span>
              </span>
            </pre>
          </div>
          <p class="content">
            <HashLink
              to={"/v4-documentation/automatic-tagging#solution-status"}
            >
              <h4 class="content" id="solution-status">
                Solution Status
              </h4>
            </HashLink>
            <br />
            The user will get this response immediately, and in the backend,
            Pixyle starts tagging the dataset.Depending on the number of images,
            this may take some time, so you can check the auto-tag status on the
            following endpoint.
          </p>
          <div class="content">
            <pre class="mainLink">
              GET /v4/datasets/<i>{"{dataset_id}"}</i>/solutions/auto-tag/status
            </pre>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
          <div class="content">
            <table class="content">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>dataset_id</td>
                  <td>
                    A unique identifier for a certain data file, where the
                    automatic-tagging process is started or finished
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/status"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"GET"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"}
                <br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"}
                <span className="txt"> "Authorization"</span>:
                <span className="letBlue"> f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">
                  {"}"}
                  <span className="txt">" </span>
                </span>
                {"}"}
                <br />
                {"  "}requests.get({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/status"
                </span>
                , <br />
                {"       "}
                <span className="blue">headers</span> = headers
                <br />
                {"     "})<br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Authorization: Bearer
                access_token' \<br />
                {"       "}-X GET
                https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
                {"       "}/solutions/auto-tag/status
              </pre>
            </div>
          )}
          <p class="content">
            Pixyle will return the amount and percentage state of tagged
            products at the time the request has been made.
          </p>
          <br />
          <h4 class="content unbold">Response properties</h4>
          <br />
          <div class="content unbold">
            <table class="content">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>dataset_id</td>
                  <td>A unique identifier for the data file</td>
                </tr>
                <tr>
                  <td>counter_products</td>
                  <td>Total number of products</td>
                </tr>
                <tr>
                  <td>processed_products</td>
                  <td>Number of products successfully processed</td>
                </tr>
                <tr>
                  <td>processed_products_percentage</td>
                  <td>
                    Percentage value of the number of processed products, over
                    the total number of products.
                  </td>
                </tr>
                <tr>
                  <td>dataset_count</td>
                  <td>Number of datasets by the user</td>
                </tr>
                <tr>
                  <td>collection_name</td>
                  <td>Name of the collection</td>
                </tr>
                <tr>
                  <td>verified_products_percentage</td>
                  <td>
                    Percentage value of the number of validated products, over
                    the total number of products
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"autotag/status"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"dataset_id"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">1570624498775133</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"meta"<span class="whiteChar">: </span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"    "}"collection_name"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"images.csv"</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"counter_products"<span class="whiteChar">: </span>
                <span class="whiteChar"> 1000</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"processed_products"<span class="whiteChar">: </span>
                <span class="whiteChar"> 100</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"processed_products_percentage"
                <span class="whiteChar">: </span>
                <span class="whiteChar"> 10.0</span>
                <br />
                {"  "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"dataset_count"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar"> 2,</span>
                <br />
                {"  "}"verified_products_percentage"
                <span class="whiteChar">: </span>{" "}
                <span class="whiteChar"> 0.0</span>
                <br />
                <span class="redChar">{"}"}</span>
              </span>
            </pre>
          </div>
          <div class="content">
            <HashLink to={"/v4-documentation/automatic-tagging#get-categories"}>
              <h4 class="content" id="get-categories">
                Get Categories
              </h4>
            </HashLink>
            <pre class="mainLink" style={{ paddingRight: "10px" }}>
              GET /v4/datasets/<i>{"{dataset_id}"}</i>
              /solutions/auto-tag/categories
            </pre>
            <p class="content">
              Тo make the process easier to manage, you can get access to all
              detected categories on this endpoint.Later, this can help you to
              see tags for each category and its products.
            </p>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>
                  A unique identifier for a certain data file, where the
                  automatic-tagging process is started or finished
                </td>
              </tr>
            </tbody>
          </table>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/categories"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"GET"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"}
                <br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"}
                <span className="txt"> "Authorization"</span>:
                <span className="letBlue"> f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">
                  {"}"}
                  <span className="txt">" </span>
                </span>
                {"}"}
                <br />
                {"  "}requests.get({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/categories"
                </span>
                , <br />
                {"       "}
                <span className="blue">headers</span> = headers
                <br />
                {"     "})<br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Authorization: Bearer
                access_token' \<br />
                {"       "}-X GET
                https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
                {"       "}/solutions/auto-tag/categories
              </pre>
            </div>
          )}
          <h4 class="content unbold">Response Properties</h4>
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for the data file</td>
              </tr>
              <tr>
                <td>detected_categories</td>
                <td>
                  An array of objects for all detected categories from the
                  dataset.Each object consists of a corresponding category and
                  number of products associated with it
                </td>
              </tr>
              <tr>
                <td>category</td>
                <td>Name of a detected category in the data file</td>
              </tr>
              <tr>
                <td>counter_products</td>
                <td>Number of products in the category</td>
              </tr>
              <tr>
                <td>verified_products</td>
                <td>Number of verified products in the category</td>
              </tr>
              <tr>
                <td>primary_image</td>
                <td>An image that represents the category</td>
              </tr>
              <tr>
                <td>counter_categories</td>
                <td>Number of categories detected in the data file</td>
              </tr>
              <tr>
                <td>collection_name</td>
                <td>Name of the collection</td>
              </tr>
              <tr>
                <td>verified_products_percentage</td>
                <td>
                  The percentage value of the number of validated products, over
                  the total number of products
                </td>
              </tr>
            </tbody>
          </table>
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"dataset_id"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">1570624498775133,</span>
                <br />
                {"  "}"detected_categories": <span class="redChar">[</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"category": <span class="yellChar">"skirts"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"counter_products": <span class="whiteChar">70,</span>
                <br />
                {"      "}"verified_products": <span class="whiteChar">0,</span>
                <br />
                {"      "}"primary_image":{" "}
                <span class="yellChar">"https://image1.jpg"</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"category": <span class="yellChar">"jeans"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"counter_products":{" "}
                <span class="whiteChar">150,</span>
                <br />
                {"      "}"verified_products": <span class="whiteChar">0,</span>
                <br />
                {"      "}"primary_image":{" "}
                <span class="yellChar">"https://image2.jpg"</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">...</span>
                <span class="redChar">{"}"}</span>
                <br />
                {"  "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"meta": <span class="redChar">{"{"}</span>
                <br />
                {"    "}"counter_categories"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">15,</span>
                <br />
                {"    "}"collection_name"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"images.csv"</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"verified_products_percentage"
                <span class="whiteChar">: </span>{" "}
                <span class="whiteChar"> 10.0</span>
                <br />
                {"  "}
                <span class="redChar">{"}"}</span>
                <br />
                <span class="redChar">{"}"}</span>
              </span>
            </pre>
          </div>
          <div class="content">
            <HashLink
              to={"/v4-documentation/automatic-tagging#get-category-products"}
            >
              <h4 class="content" id="get-category-products">
                Get Category Products
              </h4>
            </HashLink>
            <pre class="mainLink" style={{ paddingRight: "10px" }}>
              GET /v4/datasets/<i>{"{dataset_id}"}</i>
              /solutions/auto-tag/categories/<i>{"{category}"}</i>/products
            </pre>
            <p class="content">
              You can get access to all detected products in a specific category
              on this endpoint.
            </p>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>
                  A unique identifier for a certain data file, where the
                  automatic-tagging process is started or finished
                </td>
              </tr>
              <tr>
                <td>category</td>
                <td>A valid detected category in the data file</td>
              </tr>
            </tbody>
          </table>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/categories/jeans/products"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"GET"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"}
                <br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"}
                <span className="txt"> "Authorization"</span>:
                <span className="letBlue"> f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">
                  {"}"}
                  <span className="txt">" </span>
                </span>
                {"}"}
                <br />
                {"  "}requests.get({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/categories/jeans/products"
                </span>
                , <br />
                {"       "}
                <span className="blue">headers</span> = headers
                <br />
                {"     "})<br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Authorization: Bearer
                access_token' \<br />
                {"       "}-X GET
                https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
                {"       "}/solutions/auto-tag/categories/jeans/products
              </pre>
            </div>
          )}
          <h4 class="content unbold">Response Properties</h4>
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for the data file</td>
              </tr>
              <tr>
                <td>category</td>
                <td>Name of the category which products are requested</td>
              </tr>
              <tr>
                <td>result</td>
                <td>
                  An array of objects for all products associated with the
                  category
                </td>
              </tr>
              <tr>
                <td>product_id</td>
                <td>An id of a product</td>
              </tr>
              <tr>
                <td>product_coordinates</td>
                <td>
                  Coordinates of the box around the detected product (top-left
                  corner and bottom-right)
                </td>
              </tr>
              <tr>
                <td>score</td>
                <td>The confidence score for the detected product category</td>
              </tr>
              <tr>
                <td>primary_image</td>
                <td>The primary image of the product</td>
              </tr>
              <tr>
                <td>verified</td>
                <td>
                  A boolean field that represents if the product is verified or
                  not
                </td>
              </tr>
              <tr>
                <td>custom_field</td>
                <td>
                  Information about a certain field.(eg.title, description etc.)
                </td>
              </tr>
              <tr>
                <td>collection_name</td>
                <td>Name of the collection</td>
              </tr>
              <tr>
                <td>verified_products</td>
                <td>Number of verified products in the requested category</td>
              </tr>
              <tr>
                <td>counter_products</td>
                <td>Number of products in the requested category</td>
              </tr>
            </tbody>
          </table>
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"categories/jeans/products"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"dataset_id"
                <span class="whiteChar">: 1570624498775133,</span>
                <br />
                {"  "}"meta": <span class="redChar">{"{"}</span>
                <br />
                {"    "}"counter_products": <span class="whiteChar"> 150,</span>
                <br />
                {"    "}"collection_name"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"images.csv"</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"verified_products": <span class="whiteChar"> 0</span>
                <br />
                {"  "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"result": <span class="redChar">[</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"category"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"jeans"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"score"<span class="whiteChar">: 0.93,</span>
                <br />
                {"      "}"product_id"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"product2_id"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"primary_image"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"https://image1.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"verified"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">false</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"product_coordinates"<span class="whiteChar">: </span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"        "}"x_min": <span class="whiteChar"> 516,</span>
                <br />
                {"        "}"y_min": <span class="whiteChar"> 569,</span>
                <br />
                {"        "}"x_max": <span class="whiteChar"> 1063,</span>
                <br />
                {"        "}"y_max": <span class="whiteChar"> 1695,</span>
                <br />
                {"      "}
                <span class="redChar">{"}"}</span>
                <br />
                {"      "}
                <span class="whiteChar">...</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">...</span>
                <span class="redChar">{"}"}</span>
                <br />
                {"  "}
                <span class="redChar">]</span>
                <br />
                <span class="redChar">{"}"}</span>
              </span>
            </pre>
          </div>
          <div class="content">
            <HashLink to={"/v4-documentation/automatic-tagging#get-product"}>
              <h4 class="content" id="get-product">
                Get Product
              </h4>
            </HashLink>
            <pre class="mainLink" style={{ paddingRight: "10px" }}>
              GET /v4/datasets/<i>{"{dataset_id}"}</i>
              /solutions/auto-tag/products/<i>{"{product_id}"}</i>
            </pre>
          </div>
          <p class="content">
            After the auto-tagging is finished, you can use this endpoint to get
            detected tags for one product.
          </p>
          <h4 class="content unbold">Request Parameters</h4>
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>
                  A unique identifier for a certain data file, where the
                  automatic-tagging process is started or finished
                </td>
              </tr>
              <tr>
                <td>product_id</td>
                <td>
                  A valid id of one of datasets products, you want to get tags
                  for
                </td>
              </tr>
            </tbody>
          </table>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/products/product2_id"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"GET"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"}
                <br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"}
                <span className="txt"> "Authorization"</span>:
                <span className="letBlue"> f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">
                  {"}"}
                  <span className="txt">" </span>
                </span>
                {"}"}
                <br />
                {"  "}requests.get({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/products/product2_id"
                </span>
                , <br />
                {"       "}
                <span className="blue">headers</span> = headers
                <br />
                {"     "})<br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Authorization: Bearer
                access_token' \<br />
                {"       "}-X GET
                https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
                {"       "}/solutions/auto-tag/products/product2_id
              </pre>
            </div>
          )}
          <br />
          <h4 class="content unbold">Response properties</h4>
          <br />
          <div class="content">
            <table class="content">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>dataset_id</td>
                  <td>A unique identifier for the data file</td>
                </tr>
                <tr>
                  <td>result</td>
                  <td>A one object array for the requested product</td>
                </tr>
                <tr>
                  <td>product_id</td>
                  <td>An id of the requested product</td>
                </tr>
                <tr>
                  <td>verified</td>
                  <td>
                    A boolean field that represents if the requested product is
                    verified or not
                  </td>
                </tr>
                <tr>
                  <td>category</td>
                  <td>Category of the requested product</td>
                </tr>
                <tr>
                  <td>score</td>
                  <td>
                    The confidence score for the detected product category
                  </td>
                </tr>
                <tr>
                  <td>product_coordinates</td>
                  <td>
                    Coordinates of the box around the detected product (top-left
                    corner and bottom-right)
                  </td>
                </tr>
                <tr>
                  <td>detected_attributes_types</td>
                  <td>
                    An array of objects for all attributes types associated with
                    the category
                  </td>
                </tr>
                <tr>
                  <td>attribute_type</td>
                  <td>Category-specific attribute type name</td>
                </tr>
                <tr>
                  <td>attributes</td>
                  <td>
                    An object for the attributes associated with the attribute
                    type{" "}
                  </td>
                </tr>
                <tr>
                  <td>correct</td>
                  <td>
                    An array of objects only for the correctly detected
                    attributes
                  </td>
                </tr>
                <tr>
                  <td>all</td>
                  <td>An array of objects for all attributes</td>
                </tr>
                <tr>
                  <td>attribute</td>
                  <td>Detected attribute for the requested product</td>
                </tr>
                <tr>
                  <td>score</td>
                  <td>
                    The confidence score for the detected product attribute
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"autotag/products/get"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"dataset_id"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">1570624498775133,</span>
                <br />
                {"  "}"result"<span class="whiteChar">: </span>{" "}
                <span class="redChar">[</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"category"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"jeans"</span>
                <span class="whiteChar"></span>
                <br />
                {"      "}"score"<span class="whiteChar">: 0.92,</span>
                <br />
                {"      "}"product_id"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"product2_id"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"primary_image"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"https://image2.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"extra_images"<span class="whiteChar">: </span>{" "}
                <span class="redChar">[</span>
                <span class="whiteChar">...</span>
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"verified"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">false</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"product_coordinates"<span class="whiteChar">: </span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"        "}"x_min"<span class="whiteChar">: 516,</span>
                <br />
                {"        "}"y_min"<span class="whiteChar">: 569,</span>
                <br />
                {"        "}"x_max"<span class="whiteChar">: 1063,</span>
                <br />
                {"        "}"y_max"<span class="whiteChar">: 1695</span>
                <br />
                {"      "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"detected_attributes_types"
                <span class="whiteChar">: </span> <span class="redChar">[</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"waist_type"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}"attributes"<span class="whiteChar">: </span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"            "}"correct"<span class="whiteChar">: </span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"mid_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">0.4618</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"            "}"all"<span class="whiteChar">: </span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"mid_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">0.4618</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"high_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">0.1049</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"low_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">0.0054</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar"></span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <br />
                {"          "}
                <span class="redChar">{"}"}</span>
                <br />
                {"        "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"bottoms_length"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}"attributes"<span class="whiteChar">: </span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"            "}"correct"<span class="whiteChar">: </span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"full-length"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">0.3624</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"            "}"all"<span class="whiteChar">: </span>{" "}
                <span class="redChar">[</span>
                <span class="whiteChar">...</span>
                <span class="redChar">]</span>
                <br />
                {"          "}
                <span class="redChar">{"}"}</span>
                <br />
                {"        "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">...</span>
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"pattern"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}
                <span class="whiteChar">...</span>
                <br />
                {"        "}
                <span class="redChar">{"}"}</span>
                <br />
                {"      "}
                <span class="redChar">]</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <br />
                {"  "}
                <span class="redChar">]</span>
                <br />
                <span class="redChar">{"}"}</span>
              </span>
            </pre>
          </div>
          <div class="content">
            <HashLink
              to={"/v4-documentation/automatic-tagging#update-validate-product"}
            >
              <h4 class="content" id="update-validate-product">
                Update/Validate Product
              </h4>
            </HashLink>
          </div>
          <div class="content">
            <pre class="mainLink">
              PUT /v4/datasets/<i>{"{dataset_id}"}</i>
              /solutions/auto-tag/products/<i>{"{product_id}"}</i>
            </pre>
          </div>
          <p class="content">
            This endpoint can be used to validate or update your products.During
            the automatic tagging, some tags can be mistaken, so validation is
            good to do.
          </p>
          <div class="content">
            <p class="content">There are two cases during validation:</p>
            <p class="content">
              &emsp; 1.&nbsp; All the tags are true, no changes needed: &nbsp;
              When this is the case, the API will only tag this product as
              validated.
            </p>
            <p class="content">
              &emsp; 2.&nbsp; There are changes in some of the tags: &nbsp; If
              this is the case, the API will expect the correct data sent by the
              user.
            </p>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>
                  A unique identifier for a certain data file, where the
                  automatic-tagging process is started or finished
                </td>
              </tr>
              <tr>
                <td>product_id</td>
                <td>
                  A valid id of one of your datasets products that you want to
                  update
                </td>
              </tr>
              <tr>
                <td>category</td>
                <td>The category of the product, which is validated</td>
              </tr>
              <tr>
                <td>detected_attributes_types</td>
                <td>Empty array/ An array of objects for the correct data</td>
              </tr>
              <tr>
                <td>verified</td>
                <td>A boolean field to mark the product as verified</td>
              </tr>
            </tbody>
          </table>

          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="letBlue">let</span>{" "}
                <span className="blue">bodyContent</span> = {"{"} <br />
                {"       "}
                <span className="txt">"category"</span>:
                <span className="txt"> "jeans"</span>,<br />
                {"       "}
                <span className="txt">"detected_attributes_types"</span>: [],{" "}
                <span className="txt">"verified"</span>:{" "}
                <span className="letBlue">true</span> {"}"} <br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/products/product2_id"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:
                <span className="txt"> "PUT"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})
              </pre>
              <br />
              <pre>
                <span class="grayChar"># example request with changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="letBlue">let</span>{" "}
                <span className="blue">bodyContent</span> = {"{"} <br />
                {"       "}
                <span className="txt">"category"</span>:{" "}
                <span className="txt">"jeans"</span>,<br />
                {"       "}
                <span className="txt">"detected_attributes_types"</span>: [{"{"}
                <span className="txt"> "attribute_type"</span>:
                <span className="txt"> "waist_type"</span>,<br />
                {"       "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}{" "}
                <span className="txt">"attribute"</span>:
                <span className="txt"> "high-waist"</span> {"}"}]{"}}"},<br />
                {"       "}
                {"{"} <span className="txt">"attribute_type"</span>:{" "}
                <span className="txt">"bottoms_length"</span>,<br />
                {"       "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}{" "}
                <span className="txt">"attribute"</span>:{" "}
                <span className="txt">"3-4-length"</span> {"}"}]{"}}"}],
                <br />
                {"       "}
                <span className="txt">"verified"</span>:{" "}
                <span className="letBlue">true</span> {"}"}
                <br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/products/product2_id"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:
                <span className="txt"> "PUT"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"} <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"              "}
                <span className="txt">"Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">"</span> {"}"}
                <br />
                {"  "}data = {"{"} <span className="txt">"category"</span>:{" "}
                <span className="txt">"jeans"</span>,{" "}
                <span className="txt">"detected_attributes_types"</span>: [],{" "}
                <br />
                {"           "}
                <span className="txt">"verified"</span>:
                <span className="letBlue"> True</span> {"}"}
                <br />
                {"  "}response = requests.put({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets
                  <br />
                  {"       "}
                  /1570624498775133/solutions/auto-tag/products/product2_id"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">data</span> = json.dumps(data)
                <br />
                {"      "})<br />
                <br />
                <span class="grayChar"># example request with changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"} <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"              "}
                <span className="txt">"Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">"</span> {"}"}
                <br />
                {"  "}data = {"{"}
                <span className="txt"> "category"</span>:
                <span className="txt"> "jeans"</span>,<br />
                {"           "}
                <span className="txt">"detected_attributes_types"</span>: [{"{"}{" "}
                <span className="txt">"attribute_type"</span>:{" "}
                <span className="txt">"waist_type"</span>,<br />
                {"           "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}
                <span className="txt"> "attribute"</span>:{" "}
                <span className="txt">"high-waist"</span> {"}"}]{"}}"},<br />
                {"           "}
                {"{"} <span className="txt">"attribute_type"</span>:{" "}
                <span className="txt">"bottoms_length"</span>,<br />
                {"           "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}
                <span className="txt"> "attribute"</span>:{" "}
                <span className="txt">"3-4-length" </span>
                {"}"}]{"}}"}],
                <br />
                {"           "}
                <span className="txt">"verified"</span>:{" "}
                <span className="letBlue">True</span> {"}"}
                <br />
                {"  "}response = requests.put({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets
                  <br />
                  {"       "}
                  /1570624498775133/solutions/auto-tag/products/product2_id"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">data</span> = json.dumps(data)
                <br />
                {"      "}) <br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without changes</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Content-Type:
                application/json' \<br />
                {"       "}-H 'Authorization: Bearer access_token' \<br />
                {"       "}--data '{"{"}"category": "jeans",
                "detected_attributes_types": [], "verified": true{"}"}' \<br />
                {"       "}-X PUT
                https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
                {"       "}/solutions/auto-tag/products/product2_id
                <br />
              </pre>
              <br />
              <br />
              <pre>
                {" "}
                <br />
                <span class="grayChar"># example request with changes</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Content-Type:
                application/json' \<br />
                {"       "}-H 'Authorization: Bearer access_token' \<br />
                {"       "}--data '{"{"} "category": "jeans", \<br />
                {"       "}"detected_attributes_types": [{"{"} "attribute_type":
                "waist_type", \<br />
                {"       "}"attributes": {"{"}"correct": [{"{"} "attribute":
                "high-waist"{"}"}]{"}}"}, \<br />
                {"       "}
                {"{"}"attribute_type": "bottoms_length", \<br />
                {"       "}"attributes": {"{"} "correct": [{"{"} "attribute":
                "3-4-length" {"}"}]{"}}"}], \<br />
                {"       "}"verified": true{"}"}'<br />
                {"       "}-X PUT
                https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
                {"       "}/solutions/auto-tag/products/product2_id
              </pre>
            </div>
          )}
          <p class="content">
            After the product is updated, the API returns the next product for
            verification of the same category in the response.In case there are
            no more products to be verified from that category, the next_product
            field will be empty.
          </p>
          <div class=" theCode">
            <pre>
              <span class="grayChar">
                # example JSON response if there are unverified products
              </span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"autotag/update"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"dataset_id"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">1570624498775133,</span>
                <br />
                {"  "}"next_product"<span class="whiteChar">: </span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"    "}"product_id"<span class="whiteChar">: </span>
                <span class="yellChar">"product3_id"</span>
                <br />
                {"    "}"primary_image"<span class="whiteChar">: </span>
                <span class="yellChar">"https://image3.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"extra_images"<span class="whiteChar">: </span>{" "}
                <span class="redChar">[</span>
                <span class="yellChar">...</span>
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"verified"<span class="whiteChar">: </span>{" "}
                <span class="whiteChar">false,</span>
                <br />
                {"    "}"category"<span class="whiteChar">: </span>{" "}
                <span class="yellChar">"jeans"</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"score"<span class="whiteChar">: 0.92,</span>
                <br />
                {"    "}"product_coordinates"<span class="whiteChar">: </span>{" "}
                <span class="redChar">{"{"}</span>
                <span class="yellChar">...</span>
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"detected_attributes_types"
                <span class="whiteChar">: </span> <span class="redChar">[</span>
                <span class="yellChar">...</span>
                <span class="redChar">]</span>
                <br />
                {"  "}
                <span class="redChar">{"}"}</span>
                <br />
                <span class="redChar">{"}"}</span>
                <br />
                <br />
                <span class="grayChar">
                  # example JSON response if there are no more unverified
                  products
                </span>
                <br />
                <br />
                <span class="orangeChar">
                  <span class="redChar">{"{"}</span>
                  <br />
                  {"  "}"status"<span class="whiteChar">: </span>{" "}
                  <span class="yellChar">"OK"</span>
                  <span class="whiteChar">,</span>
                  <br />
                  {"  "}"method"<span class="whiteChar">: </span>{" "}
                  <span class="yellChar">"autotag/update"</span>
                  <span class="whiteChar">,</span>
                  <br />
                  {"  "}"dataset_id"<span class="whiteChar">: </span>{" "}
                  <span class="whiteChar">1570624498775133,</span>
                  <br />
                  {"  "}"next_product"<span class="whiteChar">: </span>{" "}
                  <span class="redChar">{"{}"}</span>
                  <br />
                  <span class="redChar">{"}"}</span>
                </span>
              </span>
            </pre>
          </div>
          <div class="content">
            <HashLink to={"/v4-documentation/automatic-tagging#download-file"}>
              <h4 class="content" id="download-file">
                Download File
              </h4>
            </HashLink>
          </div>

          <div class="content">
            <pre class="mainLink">
              GET /v4/datasets/<i>{"{dataset_id}"}</i>/solutions/auto-tag/files/
              <i>{"{file_type}"}</i>
            </pre>
          </div>
          <p class="content">
            Taking into account the number of products in a dataset, the best
            way for you to get detected tags for each one of them is to use this
            endpoint. The response you will get here is a file, which can be
            either JSON or CSV.
          </p>
          <h4 class="content unbold">Request Parameters</h4>
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A valid id of one of your datasets</td>
              </tr>
              <tr>
                <td>file_type</td>
                <td>
                  A string that represents the type of file you want to get the
                  results in.
                </td>
              </tr>
            </tbody>
          </table>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/files/csv"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:
                <span className="txt"> "GET"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"}
                <br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"}
                <span className="txt"> "Authorization"</span>:
                <span className="letBlue"> f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">
                  {"}"}
                  <span className="txt">" </span>
                </span>
                {"}"}
                <br />
                {"  "}requests.get({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133
                  <br />
                  {"       "}/solutions/auto-tag/files/csv"
                </span>
                , <br />
                {"       "}
                <span className="blue">headers</span> = headers
                <br />
                {"     "})<br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Authorization: Bearer
                access_token' \<br />
                {"       "}-X GET
                https://pva.pixyle.ai/v4/datasets/1570624498775133/solutions/auto-tag/files/csv
              </pre>
            </div>
          )}
          <p class="content" style={{ marginBottom: "10px" }}>
            You can download the file from the response.
          </p>
          <div class="content">
            <HashLink to={"/v4-documentation/automatic-tagging#tag-one-image"}>
              <h4 class="content" id="tag-one-image">
                Tag One Image
              </h4>
            </HashLink>
            <pre class="mainLink">POST /v4/solutions/auto-tag/image</pre>
            <p class="content">
              Pixyle also offers to tag only one image. In this case, there is
              no need for you to create a dataset. You can simply upload a photo
              on this endpoint, and you will get the tags as a response. <br />
              <br />
              An optional category field is also available on this endpoint. For
              more accurate results you can assign the category of the image in
              the request.
              <br /> <br />
              If no category is assigned Pixyle will detect each fashion item
              and treat it as a product with its own product_id. You can note
              each product_id in the response from this endpoint.
            </p>
          </div>
          <h4 class="content unbold">Request Parameters</h4>

          <div class="content">
            <table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>image</td>
                  <td>A valid image file or a valid image URL as a string</td>
                </tr>
                <tr>
                  <td>category</td>
                  <td>Category name of a product</td>
                </tr>
              </tbody>
            </table>
            <div className="content noteText">
              {" "}
              * More about{" "}
              <a
                href="#taxonomy-versions"
                style={{
                  textDecoration: "underline",
                  padding: "2px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Taxonomy versions
              </a>
            </div>
          </div>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request with image file</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                {/* <span className="blue">files</span>= <span className="txt">'image1.jpg'</span><br /> */}
                <span className="letBlue">let</span>{" "}
                <span className="blue">formData</span> ={" "}
                <span className="letBlue">new</span>{" "}
                <span className="green">FormData()</span>;<br />
                {"  "}
                <span className="blue">formData</span>.
                <span className="yellow">append</span>(
                <span className="txt"> "image"</span>,{" "}
                <span className="blue">file </span>);
                <br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/image"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"POST"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "multipart/form-data"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span>,<br />
                {"                  "}
                <span className="txt">"X-Taxonomy-Version"</span>:
                <span className="txt">'4.2'</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="letBlue">formData</span> <br />
                {"     "}
                {"}"})<br />
                <br />
                <span class="grayChar"># example request with image URL</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="letBlue">let</span>{" "}
                <span className="blue">bodyContent</span> = {"{"}
                <span className="txt"> "image"</span>:{" "}
                <span className="txt">"https://store.image1.jpg"</span>,{" "}
                <span className="txt">"category"</span>:
                <span className="txt">"jeans"</span> {"}"} <br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/image"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"POST"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "multipart/form-data"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span>,<br />
                {"                  "}
                <span className="txt">"X-Taxonomy-Version"</span>:
                <span className="txt">'4.2'</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request with image file</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"}
                <span className="txt"> "Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">" </span>,<br />
                {"              "}
                <span className="txt">"X-Taxonomy-Version"</span>:{" "}
                <span className="txt">'4.2'</span> {"}"}
                <br />
                {"  "}files = {"{"}
                <span className="txt"> "image"</span>: ({" "}
                <span className="txt">"image1.jpg"</span>,{" "}
                <span className="yellow">open</span>({" "}
                <span className="txt">"image1.jpg"</span>,{" "}
                <span className="txt">"rb" </span>), ){"}"}
                <br />
                {"  "}response = requests.post
                <br />
                {"       "}({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/image"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">files</span> = files <br />
                {"      "})<br />
                <br />
                <span class="grayChar"># example request with image URL</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"}
                <span className="txt"> "Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">" </span>,<br />
                {"              "}
                <span className="txt">"X-Taxonomy-Version"</span>:{" "}
                <span className="txt">'4.2'</span> {"}"} <br />
                {"  "}files = {"{"}
                <span className="txt"> "image"</span>: (
                <span className="blue"> None</span>,{" "}
                <span className="txt">"https://store.image1.jpg" </span>),
                <br />
                {"            "}
                <span className="txt">"category"</span>: (
                <span className="blue"> None</span>,{" "}
                <span className="txt">"jeans" </span>){"}"}
                <br />
                {"  "}response = requests.post
                <br />
                {"       "}({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/image"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">files</span> = files <br />
                {"      "})<br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request with image file</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Content-Type:
                multipart/form-data' \<br />
                {"       "}-H 'Authorization: Bearer access_token' \<br />
                {"       "}-H 'X-Taxonomy-Version : 4.2' \<br />
                {"       "}-F 'image=@image1.jpg' \<br />
                {"       "}-X POST
                https://pva.pixyle.ai/v4/solutions/auto-tag/image
                <br />
                <br />
                <span class="grayChar"># example request with image url</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Content-Type:
                multipart/form-data' \<br />
                {"       "}-H 'Authorization: Bearer access_token' \<br />
                {"       "}-H 'X-Taxonomy-Version : 4.2' \<br />
                {"       "}-F 'image=https://store.image1.jpg' \<br />
                {"       "}-F 'category=jeans' \<br />
                {"       "}-X POST
                https://pva.pixyle.ai/v4/solutions/auto-tag/image
              </pre>
            </div>
          )}
          <h4 class="content unbold">Response Parameters</h4>
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>result</td>
                <td>
                  An array of objects for all detected products in the image
                </td>
              </tr>
              <tr>
                <td>product_id</td>
                <td>
                  Pixyle assigned product id for the detected fashion product{" "}
                </td>
              </tr>
              <tr>
                <td>category</td>
                <td>Category of the detected fashion product</td>
              </tr>
              <tr>
                <td>score</td>
                <td>The confidence score for the detected product category</td>
              </tr>
              <tr>
                <td>product_coordinates</td>
                <td>
                  Detected product coordinates (top-left corner and
                  bottom-right)
                </td>
              </tr>
              <tr>
                <td>detected_attributes_types</td>
                <td>
                  An array of objects for all attributes types associated with
                  the detected category{" "}
                </td>
              </tr>
              <tr>
                <td>attribute_type</td>
                <td> Category-specific attribute type name </td>
              </tr>
              <tr>
                <td>attributes</td>
                <td>An object for the attributes in the attribute type</td>
              </tr>
              <tr>
                <td>attribute</td>
                <td>An attribute name for the detected product</td>
              </tr>
              <tr>
                <td>correct</td>
                <td>
                  An array of objects only for the correctly detected attributes
                </td>
              </tr>
              <tr>
                <td>all</td>
                <td>An array of objects for all attributes</td>
              </tr>
              <tr>
                <td>score</td>
                <td>The confidence score for the detected product attribute</td>
              </tr>
              <tr>
                <td>counter_products</td>
                <td>Number of detected products in the image</td>
              </tr>
            </tbody>
          </table>
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"autotag/products/get"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"result"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"category"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"jeans"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"score"<span class="whiteChar">: 0.92,</span>
                <br />
                {"      "}"product_id"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"product2_id"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"primary_image"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"https://store.image1.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"extra_images"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <span class="whiteChar">...</span>
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"verified"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">false</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"product_coordinates"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"        "}"x_min"<span class="whiteChar">: 516,</span>
                <br />
                {"        "}"y_min"<span class="whiteChar">: 569,</span>
                <br />
                {"        "}"x_max"<span class="whiteChar">: 1063,</span>
                <br />
                {"        "}"y_max"<span class="whiteChar">: 1695</span>
                <br />
                {"      "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"detected_attributes_types"
                <span class="whiteChar">:</span> <span class="redChar">[</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"waist_type"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}"attributes"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"            "}"correct"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"mid_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.4618</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"            "}"all"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"mid_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.4618</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"high_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.1049</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"low_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.0054</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar"></span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <br />
                {"          "}
                <span class="redChar">{"}"}</span>
                <br />
                {"        "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"bottoms_length"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}"attributes"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"            "}"correct"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"full-length"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.3624</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"            "}"all"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <span class="whiteChar">...</span>
                <span class="redChar">]</span>
                <br />
                {"          "}
                <span class="redChar">{"}"}</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">...</span>
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"pattern"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}
                <span class="whiteChar">...</span>
                <br />
                {"        "}
                <span class="redChar">{"}"}</span>
                <br />
                {"      "}
                <span class="redChar">]</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"category"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"sneakers"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"score"<span class="whiteChar">: 0.91,</span>
                <br />
                {"      "}"product_id"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"product2_id"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"primary_image"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"https://image2.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}
                <span class="whiteChar">...</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <br />
                {"  "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"meta"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"    "}"counter_products"<span class="whiteChar">: 7</span>
                <br />
                {"  "}
                <span class="redChar">{"}"}</span>
                <br />
                <span class="redChar">{"}"}</span>
              </span>
            </pre>
          </div>
          <div class="content">
            <HashLink
              to={
                "/v4-documentation/automatic-tagging#tag-one-image-get-product"
              }
            >
              <h4 class="content" id="tag-one-image-get-product">
                Tag One Image - Get Product
              </h4>
            </HashLink>
            <pre class="mainLink">
              GET /v4/solutions/auto-tag/image/products/<i>{"{product_id}"}</i>
            </pre>
          </div>
          <p class="content">
            After an image is successfully tagged, you can use this endpoint to
            get detected tags for one product, detected on it.{" "}
          </p>
          <h4 class="content unbold">Request Parameters</h4>
          <div class="content">
            <table class="content">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>product_id</td>
                  <td>
                    A valid id of one of your images products you want to get
                    tags for
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 class="content unbold">Response Parameters</h4>
          </div>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133 <br />
                  {"       "}/solutions/auto-tag/image/products/product2_id"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"GET"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"}
                <br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"}
                <span className="txt"> "Authorization"</span>:
                <span className="letBlue"> f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">
                  {"}"}
                  <span className="txt">" </span>
                </span>
                {"}"}
                <br />
                {"  "}requests.get({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/datasets/1570624498775133 <br />
                  {"       "}/solutions/auto-tag/image/products/product2_id"
                </span>
                , <br />
                {"       "}
                <span className="blue">headers</span> = headers
                <br />
                {"     "})<br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Authorization: Bearer
                access_token' \<br />
                {"       "}-X GET https://pva.pixyle.ai/v4/solutions/auto-tag \
                <br />
                {"       "}/image/products/product2_id
              </pre>
            </div>
          )}
          <div class="content">
            <table class="content">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>result</td>
                  <td>
                    A one object array for the requested product from the image
                  </td>
                </tr>
                <tr>
                  <td>category</td>
                  <td>Category of the requested product from the image</td>
                </tr>
                <tr>
                  <td>score</td>
                  <td>
                    Prediction score of the category of the requested product
                  </td>
                </tr>
                <tr>
                  <td>product_coordinates</td>
                  <td>
                    Requested product coordinates (top-left corner and
                    bottom-right)
                  </td>
                </tr>
                <tr>
                  <td>detected_attributes_types</td>
                  <td>
                    An array of objects for all attributes types associated with
                    the category of the requested product
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"image/products/get"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"result"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"category"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"jeans"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"score"<span class="whiteChar">: 0.92,</span>
                <br />
                {"      "}"product_id"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"product2_id"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"primary_image"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"https://store.image1.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"verified"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">false</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"product_coordinates"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"        "}"x_min"<span class="whiteChar">: 516,</span>
                <br />
                {"        "}"y_min"<span class="whiteChar">: 569,</span>
                <br />
                {"        "}"x_max"<span class="whiteChar">: 1063,</span>
                <br />
                {"        "}"y_max"<span class="whiteChar">: 1695</span>
                <br />
                {"      "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"detected_attributes_types"
                <span class="whiteChar">:</span> <span class="redChar">[</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"waist_type"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}"attributes"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">...</span>
                <span class="redChar">{"}"}</span>
                <br />
                {"        "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">...</span>
                <span class="redChar">{"}"}</span>
                <br />
                {"      "}
                <span class="redChar">]</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <br />
                {"  "}
                <span class="redChar">]</span>
                <br />
                <span class="redChar">{"}"}</span>
              </span>
            </pre>
          </div>
          <div class="content">
            <HashLink
              to={
                "/v4-documentation/automatic-tagging#tag-one-image-update-product"
              }
            >
              <h4 class="content" id="tag-one-image-update-product">
                Tag One Image - Update Product
              </h4>
            </HashLink>
            <pre class="mainLink">
              PUT /v4/solutions/auto-tag/image/products/<i>{"{product_id}"}</i>
            </pre>
          </div>
          <p class="content">
            This endpoint can be used to validate or update the products
            detected in an image. During the automatic tagging, some tags can be
            mistaken, so validation is good to do.{" "}
          </p>
          <h4 class="content unbold">Request Parameters</h4>
          <div class="content">
            <table class="content">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>product_id</td>
                  <td>
                    A valid id of one of your images products you want to
                    validate{" "}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="content">
              <p class="content">There are two cases during validation:</p>
              <p class="content">
                &emsp;1.&nbsp; All the tags are true, no changes needed:&nbsp;
                When this is the case, the API will only tag this product as
                validated.
              </p>
              <p class="content">
                &emsp;2.&nbsp; There are changes in some of the tags:&nbsp; If
                this is the case, the API will expect the correct data sent by
                the user.
              </p>
            </div>
            <br />
            <p class="content">
              After the product is updated, the API returns the next product for
              verification of the same image in the response. In case there are
              no more products to be verified from that image, the next_product
              field will be empty.
            </p>
          </div>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="letBlue">let</span>{" "}
                <span className="blue">bodyContent</span> = {"{"} <br />
                {"       "}
                <span className="txt">"category"</span>:
                <span className="txt"> "jeans"</span>, <br />
                {"       "}
                <span className="txt">"detected_attributes_types"</span>: [],
                <br />
                {"       "}
                <span className="txt">"verified"</span>:
                <span className="letBlue"> true</span> {"}"} <br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/products/product2_id"
                </span>
                ,{"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"PUT"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})
              </pre>
              <br />
              <pre>
                <span class="grayChar"># example request with changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="letBlue">let</span>{" "}
                <span className="blue">bodyContent</span> = {"{"} <br />
                {"        "}
                <span className="txt">"category"</span>:{" "}
                <span className="txt">"jeans"</span>,<br />
                {"        "}
                <span className="txt">"detected_attributes_types"</span>: [{"{"}{" "}
                <span className="txt">"attribute_type"</span>:
                <span className="txt"> "waist_type"</span>,<br />
                {"        "}
                <span className="txt">"attributes"</span>: {"{"}
                <span className="txt"> "correct"</span>: [{"{"}
                <span className="txt"> "attribute"</span>:{" "}
                <span className="txt">"high-waist" </span>
                {"}"}]{"}}"},<br />
                {"        "}
                {"{"}
                <span className="txt"> "attribute_type"</span>:
                <span className="txt"> "bottoms_length"</span>,<br />
                {"        "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}{" "}
                <span className="txt">"attribute"</span>:
                <span className="txt"> "3-4-length"</span> {"}"}]{"}}"}],
                <br />
                {"        "}
                <span className="txt">"verified"</span>:
                <span className="letBlue"> true </span>
                {"}"}
                <br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/products/product2_id"
                </span>
                ,{"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"PUT"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"} <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"              "}
                <span className="txt">"Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">"</span> {"}"}
                <br />
                {"  "}data = {"{"} <span className="txt">"category"</span>:
                <span className="txt"> "jeans"</span>,
                <span className="txt"> "detected_attributes_types"</span>: [],
                <br />
                {"          "}
                <span className="txt"> "verified"</span>:
                <span className="letBlue"> True</span> {"}"}
                <br />
                {"  "}response = requests.put
                <br />
                {"       "}({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/products/product2_id"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">data</span> = json.dumps(data) <br />
                {"      "})<br />
                <br />
                <span class="grayChar"># example request with changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"} <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"              "}
                <span className="txt">"Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">"</span> {"}"}
                <br />
                {"  "}data = {"{"} <span className="txt">"category"</span>:
                <span className="txt"> "jeans"</span>,<br />
                {"          "}
                <span className="txt">"detected_attributes_types"</span>: [{"{"}{" "}
                <span className="txt">"attribute_type"</span>:{" "}
                <span className="txt">"waist_type"</span>,<br />
                {"          "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}{" "}
                <span className="txt">"attribute"</span>:{" "}
                <span className="txt">"high-waist" </span>
                {"}"}]{"}}"}, <br />
                {"          "}
                {"{"} <span className="txt">"attribute_type"</span>:
                <span className="txt"> "bottoms_length"</span>, <br />
                {"          "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}{" "}
                <span className="txt">"attribute"</span>:{" "}
                <span className="txt">"3-4-length"</span> {"}"}]{"}}"}],
                <br />
                {"          "}
                <span className="txt">"verified"</span>:
                <span className="letBlue"> True </span>
                {"}"}
                <br />
                {"  "}response = requests.put
                <br />
                {"       "}({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/products/product2_id"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">data</span> = json.dumps(data) <br />
                {"      "})<br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without changes</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Content-Type:
                application/json' \<br />
                {"        "}-H 'Authorization: Bearer access_token' \<br />
                {"        "}--data '{"{"}"category": "jeans",
                "detected_attributes_types": [], "verified": true{"}"}' \<br />
                {"        "}-X PUT https://pva.pixyle.ai/v4/solutions \<br />
                {"        "}/auto-tag/products/product2_id
              </pre>
              <br />
              <br />
              <pre>
                <span class="grayChar"># example request with changes</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Content-Type:
                application/json' \<br />
                {"        "}-H 'Authorization: Bearer access_token' \<br />
                {"        "}--data '{"{"} "category": "jeans", \<br />
                {"        "}"detected_attributes_types": [{"{"}{" "}
                "attribute_type": "waist_type", \<br />
                {"        "}"attributes": {"{"}"correct": [{"{"} "attribute":
                "high-waist"{"}"}]{"}}"}, \<br />
                {"        "}
                {"{"}"attribute_type": "bottoms_length", \<br />
                {"        "}"attributes": {"{"} "correct": [{"{"} "attribute":
                "3-4-length" {"}"}]{"}}"}], \<br />
                {"        "}"verified": true{"}"}'<br />
                {"        "}-X PUT https://pva.pixyle.ai/v4/solutions \<br />
                {"        "}/auto-tag/products/product2_id
              </pre>
            </div>
          )}
          <h4 class="content unbold">Response Parameters</h4>
          <div class="content">
            <table class="content">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>result</td>
                  <td>
                    A one object array for the requested product from the image
                  </td>
                </tr>
                <tr>
                  <td>category</td>
                  <td>Category of the requested product from the image</td>
                </tr>
                <tr>
                  <td>score</td>
                  <td>
                    Prediction score of the category of the requested product
                  </td>
                </tr>
                <tr>
                  <td>product_coordinates</td>
                  <td>
                    Requested product coordinates (top-left corner and
                    bottom-right)
                  </td>
                </tr>
                <tr>
                  <td>detected_attributes_types</td>
                  <td>
                    An array of objects for all attributes types associated with
                    the category of the requested product
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class=" theCode">
            <pre>
              <span class="grayChar">
                # example JSON response if there are unverified products
              </span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"image/products/update"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"next_product"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"    "}"product_id"<span class="whiteChar">:</span>
                <span class="yellChar">"product3_id"</span>
                <br />
                {"    "}"primary_image"<span class="whiteChar">:</span>
                <span class="yellChar">"https://store.image1.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"verified"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">false,</span>
                <br />
                {"    "}"category"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"jeans"</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"score"<span class="whiteChar">: 0.94,</span>
                <br />
                {"    "}"product_coordinates"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">...</span>
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}"detected_attributes_types"
                <span class="whiteChar">:</span> <span class="redChar">[</span>
                <span class="whiteChar">...</span>
                <span class="redChar">]</span>
                <br />
                {"  "}
                <span class="redChar">{"}"}</span>
                <br />
                <span class="redChar">{"}"}</span>
                <br />
                <br />
                <span class="grayChar">
                  # example JSON response if there are no more unverified
                  products
                </span>
                <br />
                <span class="orangeChar">
                  <br />
                  <span class="redChar">{"{"}</span>
                  <br />
                  {"  "}"status"<span class="whiteChar">:</span>{" "}
                  <span class="yellChar">"OK"</span>
                  <span class="whiteChar">,</span>
                  <br />
                  {"  "}"method"<span class="whiteChar">:</span>{" "}
                  <span class="yellChar">"image/products/update"</span>
                  <span class="whiteChar">,</span>
                  <br />
                  {"  "}"next_product"<span class="whiteChar">:</span>{" "}
                  <span class="redChar">{"{}"}</span>
                  <br />
                  <span class="redChar">{"}"}</span>
                </span>
              </span>
            </pre>
          </div>
          <div class="content">
            <HashLink
              to={
                "/v4-documentation/automatic-tagging#tag-one-image-get-all-products"
              }
            >
              <h4 class="content" id="tag-one-image-get-all-products">
                Tag One Image - Get All Products
              </h4>
            </HashLink>
            <pre class="mainLink">
              GET /v4/solutions/auto-tag/image/products
            </pre>
          </div>
          <p class="content">
            You can use this endpoint to get all images, tagged until now. As a
            response you will get short information about each product detect on
            them.{" "}
          </p>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/image/products"
                </span>{" "}
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"GET"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"}
                <br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"}
                <span className="txt"> "Authorization"</span>:
                <span className="letBlue"> f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">
                  {"}"}
                  <span className="txt">" </span>
                </span>
                {"}"}
                <br />
                {"  "}requests.get({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/image/products"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers
                <br />
                {"     "})<br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Authorization: Bearer
                access_token' \<br />
                {"       "}-X GET https://pva.pixyle.ai/v4/solutions/auto-tag \
                <br />
                {"       "}/image/products
              </pre>
            </div>
          )}
          <h4 class="content unbold">Response Parameters</h4>
          <div class="content">
            <table class="content">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>result</td>
                  <td>
                    An array of objects for all products in the tagged images
                  </td>
                </tr>
                <tr>
                  <td>product_id</td>
                  <td>Pixyle assigned product id for the detected product</td>
                </tr>
                <tr>
                  <td>category</td>
                  <td>Category of the product </td>
                </tr>
                <tr>
                  <td>upload_at</td>
                  <td>Time when the products image was uploaded</td>
                </tr>
                <tr>
                  <td>verified</td>
                  <td>
                    A boolean field to that shows if a product is verified
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"auto-tag/image/products"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"result"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"product_id"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"product1_id"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"category"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"jeans"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"primary_image"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"https://store.image1.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"upload_at"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"Thu, 14 Jan 2021 12:25:46 GMT"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"verified"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">false</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"product_id"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"product2_id"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"category"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"sneakers"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"primary_image"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"https://store.image2.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"upload_at"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"Thu, 14 Jan 2021 12:25:46 GMT"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"verified"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">false</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">...</span>
                <span class="redChar">{"}"}</span>
                <br />
                {"  "}
                <span class="redChar">]</span>
                <br />
                <span class="redChar">{"}"}</span>
              </span>
            </pre>
          </div>

          <div class="content">
            <HashLink
              to={"/v4-documentation/automatic-tagging#tag-one-product"}
            >
              <h4 class="content" id="tag-one-product">
                Tag One Product
              </h4>
            </HashLink>
            <div className="content">
              <p>
                Pixyle also offers to tag one product with multiple images
                related to it. In this case, there is no need for you to create
                a dataset. You can simply upload all the images for the product
                on this endpoint, and you will get the tags as a response.
                Pixyle will process all of the images, extract the best features
                and use them to tag the product. In order to integrate automatic
                tagging solution you could follow this basic flow.
              </p>
              <ul>
                <li className="diagram-a">
                  <a href="#login"> 1. Login</a>
                </li>
                <li className="diagram-a">
                  <a href="#tag-one-product">2. Tag One Product </a>
                </li>

                <li className="diagram-a">
                  <a href="#tag-one-product-your-texonomy">
                    3. Tag One Product - Your Taxonomy{" "}
                  </a>
                </li>
                <li className="diagram-a">
                  <a href="#tag-one-product-validate-product">
                    4. Tag One Product - Validate Product{" "}
                  </a>
                </li>
              </ul>
              <p>
                The sequence diagram can be found{" "}
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => {
                    setOpenModal(true);
                    setImg("atLiveProcessing");
                  }}
                >
                  here.
                </span>
              </p>
              <p style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                Below, you can find more details regarding the solution
                endpoints.
              </p>
            </div>

            <pre class="mainLink">POST /v4/solutions/auto-tag/product</pre>
            <p class="content">
              An optional category field is also available on this endpoint. For
              more accurate results you can assign the category of the product
              in the request.
              <br /> <br />
              If no category is assigned Pixyle will process and detect the
              largest fashion item. The largest item will be treated as a main
              product and the tags in the response will refer to it.
            </p>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
          <div class="content">
            <table class="content">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>id</td>
                  <td>A unique product identifier</td>
                </tr>
                <tr>
                  <td>image_url_1</td>
                  <td>A valid image URL as a string</td>
                </tr>
                <tr>
                  <td>category*</td>
                  <td>Category name of a product</td>
                </tr>
              </tbody>
            </table>
            <div className="content noteText">
              {" "}
              * More about{" "}
              <a
                href="#taxonomy-versions"
                style={{
                  textDecoration: "underline",
                  padding: "2px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Taxonomy versions
              </a>
            </div>
            {/* <div className="content" style={{marginBottom:"1.5rem"}}>
          In case of expecting gender as attribute then you should use taxonomy version 4.1 as in the example. By default it has male, female and unisex values.
          </div> */}
            <p class="noteText">
              * The category field is highly recommended. If no category is
              assigned, Pixyle will tag only the largest fashion item present on
              the image alongside its category.
            </p>
          </div>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without category</span>
                <br />
                <br />
                <span className="letBlue">let</span>{" "}
                <span className="blue">bodyContent</span> = [{"{"}{" "}
                <span className="txt">"id"</span>:{" "}
                <span className="txt">"productid1"</span>,<br />
                {"       "}
                <span className="txt">"image_url_1"</span>:{" "}
                <span className="txt">"https://store.image1.jpg"</span>, <br />
                {"       "}
                <span className="txt">"image_url_2"</span>:{" "}
                <span className="txt">"https://store.image2.jpg"</span>, <br />
                {"       "}
                <span className="txt">"image_url_3"</span>:{" "}
                <span className="txt">"https://store.image3.jpg"</span>, <br />
                {"       "}
                <span className="txt">"product_url"</span>:{" "}
                <span className="txt">""</span>, <br />
                {"       "}
                <span className="txt">"product_title"</span>:
                <span className="txt"> "Womens Jeans"</span>, <br />
                {"       "}
                <span className="txt">"category"</span>:{" "}
                <span className="txt">""</span>, <br />
                {"       "}
                <span className="txt">"description"</span>:{" "}
                <span className="txt">
                  "Description specified for this product."{" "}
                </span>
                {"}"}]<br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/product"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"POST"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span>,<br />
                {"                  "}
                <span className="txt">"X-Taxonomy-Version"</span>:
                <span className="txt">'4.2'</span> {"}"}
                <br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})
              </pre>
              <br />
              <pre>
                <span class="grayChar"># example request with category</span>
                <br />
                <br />
                <span className="letBlue">let</span>{" "}
                <span className="blue">bodyContent</span> = [{"{"}
                <span className="txt"> "id"</span>:{" "}
                <span className="txt">"productid2"</span>, <br />
                {"       "}
                <span className="txt">"image_url_1"</span>:
                <span className="txt"> "https://store.image1.jpg"</span>, <br />
                {"       "}
                <span className="txt">"image_url_2"</span>:
                <span className="txt"> "https://store.image2.jpg"</span>, <br />
                {"       "}
                <span className="txt">"category"</span>:
                <span className="txt"> "jeans"</span>, <br />
                {"       "}
                <span className="txt">"description"</span>:
                <span className="txt">
                  {" "}
                  "Description specified for this product."{" "}
                </span>
                {"}"}]<br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/product"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"POST"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span>,<br />
                {"                  "}
                <span className="txt">"X-Taxonomy-Version"</span>:
                <span className="txt">'4.2'</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without category</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"} <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"              "}
                <span className="txt">"Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">"</span>,<br />
                {"              "}
                <span className="txt">"X-Taxonomy-Version"</span>:{" "}
                <span className="txt">'4.2'</span> {"}"}
                <br />
                {"  "}data = [{"{"} <span className="txt">"id"</span>:
                <span className="txt"> "productid1"</span>, <br />
                {"            "}
                <span className="txt">"image_url_1"</span>:
                <span className="txt"> "https://store.image1.jpg"</span>,<br />
                {"            "}
                <span className="txt">"image_url_2"</span>:{" "}
                <span className="txt">"https://store.image2.jpg"</span>, <br />
                {"            "}
                <span className="txt">"image_url_3"</span>:
                <span className="txt"> "https://store.image3.jpg"</span>, <br />
                {"            "}
                <span className="txt">"product_url"</span>:{" "}
                <span className="txt">""</span>, <br />
                {"            "}
                <span className="txt">"product_title"</span>:{" "}
                <span className="txt">"Womens Jeans"</span>,<br />
                {"            "}
                <span className="txt">"category"</span>:
                <span className="txt"> ""</span>, <br />
                {"            "}
                <span className="txt">"description"</span>:
                <span className="txt">
                  {" "}
                  "Description specified for this product."
                </span>{" "}
                {"}"}]<br />
                {"  "}response = requests.post
                <br />
                {"       "}({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/product"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">data</span> = json.dumps(data) <br />
                {"      "}) <br />
                <br />
                <span class="grayChar"># example request with category</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"} <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"              "}
                <span className="txt">"Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">"</span>,<br />
                {"              "}
                <span className="txt">"X-Taxonomy-Version"</span>:{" "}
                <span className="txt">'4.2'</span> {"}"}
                <br />
                {"  "}data = [{"{"} <span className="txt">"id"</span>:
                <span className="txt"> "productid2"</span>, <br />
                {"            "}
                <span className="txt">"image_url_1"</span>:
                <span className="txt"> "https://store.image1.jpg"</span>, <br />
                {"            "}
                <span className="txt">"image_url_2"</span>:
                <span className="txt"> "https://store.image2.jpg"</span>, <br />
                {"            "}
                <span className="txt">"category"</span>:{" "}
                <span className="txt">"jeans"</span>, <br />
                {"            "}
                <span className="txt">"description"</span>:
                <span className="txt">
                  {" "}
                  "Description specified for this product."
                </span>{" "}
                {"}"}]<br />
                {"  "}response = requests.post
                <br />
                {"       "}({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/product"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">data</span> = json.dumps(data) <br />
                {"      "}) <br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without category</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Content-Type:
                application/json' \<br />
                {"       "}-H 'Authorization: Bearer access_token' \<br />
                {"       "}-H 'X-Taxonomy-Version : 4.2' \<br />
                {"       "}--data '[{"{"}"id": "productid1", \<br />
                {"       "}"image_url_1": "https://store.image1.jpg", \<br />
                {"       "}"image_url_2": "https://store.image2.jpg", \<br />
                {"       "}"image_url_3": "https://store.image3.jpg", \<br />
                {"       "}"product_url": "", \<br />
                {"       "}"product_title": "Womens Jeans", \<br />
                {"       "}"category": "", \ <br />
                {"       "}"description": "Description specified for this
                product. "{"}"}]' \<br />
                {"       "}-X POST
                https://pva.pixyle.ai/v4/solutions/auto-tag/product
                <br />
                <br />
                <span class="grayChar"># example request with category</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Content-Type:
                application/json' \<br />
                {"       "}-H 'Authorization: Bearer access_token' \<br />
                {"       "}-H 'X-Taxonomy-Version : 4.2' \<br />
                {"       "}--data '[{"{"}"id": "productid2", \<br />
                {"       "}"image_url_1": "https://store.image1.jpg", \<br />
                {"       "}"image_url_2": "https://store.image2.jpg", \<br />
                {"       "}"category": "jeans", \ <br />
                {"       "}"description": "Description specified for this
                product. "{"}"}]' \<br />
                {"       "}-X POST
                https://pva.pixyle.ai/v4/solutions/auto-tag/product
              </pre>
            </div>
          )}
          <h4 class="content unbold">Recommended fields</h4>
          <p class="content">
            This list of fields in the schema below are not necessary, but in
            order to provide more comfort and functionalities during processing,
            they are recommended.
          </p>
          <table class="content">
            <tbody>
              <tr>
                <th>Field</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>product_url</td>
                <td>URL of the product</td>
              </tr>
              <tr>
                <td>image_url_2..8</td>
                <td>More image files or a image URLs of a product (max 7)</td>
              </tr>
              <tr>
                <td>product_title</td>
                <td>Title of the product</td>
              </tr>
              <tr>
                <td>description</td>
                <td>Semantic representation of the product</td>
              </tr>
              <tr>
                <td>gender</td>
                <td>Gender type of the product</td>
              </tr>
              <tr>
                <td>age_group</td>
                <td>The age group of the product</td>
              </tr>
              <tr>
                <td>size</td>
                <td>Available sizes for the product</td>
              </tr>
              <tr>
                <td>color</td>
                <td>Color of the product</td>
              </tr>
            </tbody>
          </table>
          <h4 class="content unbold">Response Parameters</h4>
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>result</td>
                <td>
                  An array of objects for all detected products in the image
                </td>
              </tr>
              <tr>
                <td>product_id</td>
                <td>The unique product identifier </td>
              </tr>
              <tr>
                <td>category</td>
                <td>Category of the detected fashion item</td>
              </tr>
              <tr>
                <td>score</td>
                <td>The confidence score for the detected product category</td>
              </tr>
              <tr>
                <td>product_coordinates</td>
                <td>
                  Detected product coordinates (top-left corner and
                  bottom-right)
                </td>
              </tr>
              <tr>
                <td>detected_attributes_types</td>
                <td>
                  An array of objects for all attributes types associated with
                  the detected category{" "}
                </td>
              </tr>
              <tr>
                <td>attribute_type</td>
                <td> Category-specific attribute type name </td>
              </tr>
              <tr>
                <td>attributes</td>
                <td>An object for the attributes in the attribute type</td>
              </tr>
              <tr>
                <td>attribute</td>
                <td>An attribute name for the detected product</td>
              </tr>
              <tr>
                <td>score</td>
                <td>The confidence score for the detected product attribute</td>
              </tr>
              <tr>
                <td>counter_products</td>
                <td>Number of detected items in the image</td>
              </tr>
            </tbody>
          </table>
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"autotag/product"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"result"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"category"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"jeans"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"score"<span class="whiteChar">: 0.95,</span>
                <br />
                {"      "}"product_id"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"product1_id"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"primary_image"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"https://store.image1.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"extra_images"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <span class="whiteChar">...</span>
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"verified"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">false</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"product_coordinates"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"        "}"x_min"<span class="whiteChar">: 516,</span>
                <br />
                {"        "}"y_min"<span class="whiteChar">: 569,</span>
                <br />
                {"        "}"x_max"<span class="whiteChar">: 1063,</span>
                <br />
                {"        "}"y_max"<span class="whiteChar">: 1695</span>
                <br />
                {"      "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"detected_attributes_types"
                <span class="whiteChar">:</span> <span class="redChar">[</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"waist_type"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}"attributes"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"            "}"correct"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"mid_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.4618</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"            "}"all"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"mid_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.4618</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"high_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.1049</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"low_waist"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.0054</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar"></span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <br />
                {"          "}
                <span class="redChar">{"}"}</span>
                <br />
                {"        "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"bottoms_length"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}"attributes"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"            "}"correct"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"full-length"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.3624</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"            "}"all"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <span class="whiteChar">...</span>
                <span class="redChar">]</span>
                <br />
                {"          "}
                <span class="redChar">{"}"}</span>
                <br />
                {"        "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">...</span>
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"pattern"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}
                <span class="whiteChar">...</span>
                <br />
                {"        "}
                <span class="redChar">{"}"}</span>
                <br />
                {"      "}
                <span class="redChar">]</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <br />
                {"  "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"errors"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[]</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"meta"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"    "}"counter_categories"<span class="whiteChar">: 7</span>
                <br />
                {"  "}
                <span class="redChar">{"}"}</span>
                <br />
                <span class="redChar">{"}"}</span>
              </span>
            </pre>
          </div>

          <div class="content">
            <HashLink
              to={
                "/v4-documentation/automatic-tagging#tag-one-product-your-texonomy"
              }
            >
              <h4 class="content" id="tag-one-product-your-texonomy">
                Tag One Product - Your Taxonomy
              </h4>
            </HashLink>
          </div>
          <div class="content">
            <pre class="mainLink">
              POST /v4/solutions/auto-tag/product?pixyle_taxonomy=true
            </pre>
          </div>

          <p class="content">
            Pixyle uses its standard fashion taxonomy for tagging products as
            default taxonomy, but also offers to tag products with your wording
            - taxonomy. To be able to get the tags like they are in your
            taxonomy, a mapping* between Pixyle's taxonomy and your taxonomy
            needs to be done. Once the mapping is done Pixyle will set your
            taxonomy as the default one, and you will get tags only from your
            taxonomy.
            <br /> <br />
            As an addition to your tags, you can use this endpoint to get the
            tags from Pixyle's taxonomy too. You can simply add the query
            parameter to the endpoint and choose whether you want to get tags
            from Pixyle's taxonomy as well. This can help you to enrich the tags
            for your products even more and use both sets of tags in different
            scenarios.
            <br /> <br />
            An optional category field is also available on this endpoint. For
            more accurate results you can assign the category of the product in
            the request. If the mapping is done the category name must be from
            your taxonomy, otherwise, you should send a category from Pixyle's
            taxonomy.
            <br /> <br />
            If no category is assigned Pixyle will process, detect the largest
            fashion item and use the default taxonomy to tag it. The largest
            item will be treated as the main product and the tags in the
            response will refer to it.
          </p>
          <br />
          <p class="noteText">
            * Mapping represents associating one or more elements from your
            taxonomy to one or more elements in Pixyle's taxonomy.
          </p>

          <h4 class="content unbold">Query Parameters</h4>
          <div class="content">
            <table class="content">
              <tbody>
                <tr>
                  <th>Field</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>pixyle_taxonomy</td>
                  <td>
                    A boolean flag that shows whether to add the tags from
                    Pixyle taxonomy
                  </td>
                </tr>
              </tbody>
            </table>

            <h4 class="content unbold">Request Parameters</h4>
            <br />
            <table class="content">
              <tbody>
                <tr>
                  <th>Field</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>id</td>
                  <td>A unique product identifier</td>
                </tr>
                <tr>
                  <td>image_url_1</td>
                  <td>A valid image file or a valid image URL as a string</td>
                </tr>
                <tr>
                  <td>category*</td>
                  <td>Category name of a product</td>
                </tr>
              </tbody>
            </table>
            <p class="noteText">
              * The category field is highly recommended. If no category is
              assigned, Pixyle will tag only the largest fashion item present on
              the image alongside its category.
            </p>
          </div>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without category</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="letBlue">let</span>{" "}
                <span className="blue">bodyContent</span> = [{"{"}{" "}
                <span className="txt">"id"</span>:{" "}
                <span className="txt">"productid1"</span>, <br />
                {"        "}
                <span className="txt">"image_url_1"</span>:
                <span className="txt"> "https://store.image1.jpg"</span>, <br />
                {"        "}
                <span className="txt">"image_url_2"</span>:
                <span className="txt"> "https://store.image2.jpg"</span>, <br />
                {"        "}
                <span className="txt">"image_url_3"</span>:{" "}
                <span className="txt">"https://store.image3.jpg"</span>, <br />
                {"        "}
                <span className="txt">"product_title"</span>:
                <span className="txt"> "Women Jeans"</span>, <br />
                {"        "}
                <span className="txt">"category"</span>:{" "}
                <span className="txt">""</span>,<br />
                {"        "}
                <span className="txt">"description"</span>:{" "}
                <span className="txt">
                  "Description specified for this product."
                </span>{" "}
                {"}"}]<br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag
                  <br />
                  {"       "}/product?pixyle_taxonomy=true"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"POST"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})
              </pre>
              <br />
              <pre>
                <span class="grayChar"># example request with category</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="letBlue">let</span>{" "}
                <span className="blue">bodyContent</span> = {"{"}{" "}
                <span className="txt">"id"</span>:{" "}
                <span className="txt">"productid2"</span>,<br />
                {"       "}
                <span className="txt">"image_url_1"</span>:{" "}
                <span className="txt">"https://store.image1.jpg"</span>,<br />
                {"       "}
                <span className="txt">"image_url_2"</span>:
                <span className="txt"> "https://store.image2.jpg"</span>,<br />
                {"       "}
                <span className="txt">"category"</span>:
                <span className="txt"> "denim-pants"</span> {"}"} <br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag
                  <br />
                  {"       "}/product?pixyle_taxonomy=true"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"POST"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without category</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"} <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"              "}
                <span className="txt">"Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">"</span> {"}"}
                <br />
                {"  "}data = [{"{"} <span className="txt">"id"</span>:
                <span className="txt"> "productid1"</span>,<br />
                {"            "}
                <span className="txt">"image_url_1"</span>:
                <span className="txt"> "https://store.image1.jpg"</span>, <br />
                {"            "}
                <span className="txt">"image_url_2"</span>:
                <span className="txt"> "https://store.image2.jpg"</span>, <br />
                {"            "}
                <span className="txt">"image_url_3"</span>:
                <span className="txt"> "https://store.image3.jpg"</span>, <br />
                {"            "}
                <span className="txt">"product_title"</span>:
                <span className="txt"> "Women Jeans"</span>, <br />
                {"            "}
                <span className="txt">"category"</span>:
                <span className="txt"> ""</span>,<br />
                {"            "}
                <span className="txt">"description"</span>:{" "}
                <span className="txt">
                  "Description specified for this product."
                </span>{" "}
                {"}"}]<br />
                {"  "}response = requests.post({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag
                  <br />
                  {"       "}/product?pixyle_taxonomy=true"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">data</span> = json.dumps(data)
                <br />
                {"      "}) <br />
                <br />
                <span class="grayChar"># example request with category</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"} <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"              "}
                <span className="txt">"Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">"</span> {"}"}
                <br />
                {"  "}data = {"{"} <span className="txt">"id"</span>:
                <span className="txt"> "productid2"</span>,<br />
                {"           "}
                <span className="txt">"image_url_1"</span>:
                <span className="txt"> "https://store.image1.jpg"</span>, <br />
                {"           "}
                <span className="txt">"image_url_2"</span>:
                <span className="txt"> "https://store.image2.jpg"</span>, <br />
                {"           "}
                <span className="txt">"category"</span>:
                <span className="txt"> "denim-pants"</span> {"}"}
                <br />
                {"  "}response = requests.post({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag
                  <br />
                  {"       "}/product?pixyle_taxonomy=true"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">data</span> = json.dumps(data)
                <br />
                {"      "}) <br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                {" "}
                <span class="grayChar"># example request without category</span>
                <br />
                <br />
                <span class="grayChar"> $</span> curl -H 'Content-Type:
                application/json' \<br />
                {"        "}-H 'Authorization: Bearer access_token' \<br />
                {"        "}--data '[{"{"}"id": "productid1", "image_url_1":
                "https://store.image1.jpg", \<br />
                {"        "}"image_url_2": "https://store.image2.jpg", \<br />
                {"        "}"image_url_3": "https://store.image3.jpg", \<br />
                {"        "}"product_title": "Women Jeans", \<br />
                {"        "}"category": "", \<br />
                {"        "}"description": "Description specified for this
                product."{"}"}]'\
                <br />
                {"        "}-X POST
                https://pva.pixyle.ai/v4/solutions/auto-tag/product?pixyle_taxonomy=true
                <br />
                <br />
                <span class="grayChar"># example request with category</span>
                <br />
                <br />
                <span class="grayChar"> $</span> curl -H 'Content-Type:
                application/json' \<br />
                {"        "}-H 'Authorization: Bearer access_token' \<br />
                {"        "}--data '{"{"}"id": "productid2", "image_url_1":
                "https://store.image1.jpg", \<br />
                {"        "}"image_url_2": "https://store.image2.jpg", \<br />
                {"        "}"category": "denim-pants" {"}"} ' \<br />
                {"        "}-X POST
                https://pva.pixyle.ai/v4/solutions/auto-tag/product?pixyle_taxonomy=true
              </pre>
            </div>
          )}
          <h4 class="content unbold">Recommended fields</h4>
          <p class="content">
            This list of fields in the schema below are not necessary, but in
            order to provide more comfort and functionalities during processing,
            they are recommended.
          </p>

          <table class="content">
            <tbody>
              <tr>
                <th>Field</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>image_url_2..8</td>
                <td>More image files or a image URLs of a product (max 7)</td>
              </tr>
              <tr>
                <td>product_url</td>
                <td>URL of a product</td>
              </tr>
              <tr>
                <td>product_title</td>
                <td>Title of a product</td>
              </tr>
              <tr>
                <td>description</td>
                <td>Semantic representation of a product </td>
              </tr>
              <tr>
                <td>gender</td>
                <td>Gender type of a product</td>
              </tr>
              <tr>
                <td>age_group</td>
                <td>The age group of a product</td>
              </tr>
              <tr>
                <td>size</td>
                <td>Available sizes for a product</td>
              </tr>
              <tr>
                <td>color</td>
                <td>Color of a product</td>
              </tr>
            </tbody>
          </table>

          <h4 class="content unbold">Response Parameters</h4>
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>result</td>
                <td>
                  An array of objects for all tags in the default taxonomy
                </td>
              </tr>
              <tr>
                <td>pixyle_result</td>
                <td>An array of objects for all tags in the Pixyle taxonomy</td>
              </tr>
            </tbody>
          </table>

          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"autotag/product"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"result"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"category"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"denim-pants"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"score"<span class="whiteChar">: 0.95,</span>
                <br />
                {"      "}"product_id"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"product1_id"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"primary_image"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"https://store.image1.jpg"</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"verified"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">false</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"product_coordinates"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"        "}"x_min"<span class="whiteChar">: 516,</span>
                <br />
                {"        "}"y_min"<span class="whiteChar">: 569,</span>
                <br />
                {"        "}"x_max"<span class="whiteChar">: 1063,</span>
                <br />
                {"        "}"y_max"<span class="whiteChar">: 1695</span>
                <br />
                {"      "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}"detected_attributes_types"
                <span class="whiteChar">:</span> <span class="redChar">[</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <br />
                {"          "}"attribute_type"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"rise"</span>
                <span class="whiteChar">,</span>
                <br />
                {"          "}"attributes"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"            "}"correct"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"mid_rise"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.4818</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"            "}"all"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"mid_rise"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.4818</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"high_rise"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.1049</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"              "}
                <span class="redChar">{"{"}</span>
                <br />
                {"                "}"attribute"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"low_rise"</span>
                <span class="whiteChar">,</span>
                <br />
                {"                "}"score"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">0.0054</span>
                <br />
                {"              "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar"></span>
                <br />
                {"            "}
                <span class="redChar">]</span>
                <br />
                {"          "}
                <span class="redChar">{"}"}</span>
                <br />
                {"        "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"        "}
                <span class="redChar">{"{"}</span>
                <span class="whiteChar">...</span>
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"      "}
                <span class="redChar">]</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"    "}
                <span class="whiteChar">...</span>
                <br />
                {"  "}
                <span class="redChar">]</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"pixyle_result"<span class="whiteChar">:</span>{" "}
                <span class="redChar">[</span>
                <br />
                {"    "}
                <span class="redChar">{"{"}</span>
                <br />
                {"      "}"category<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"jeans"</span>
                <br />
                {"      "}
                <span class="whiteChar">...</span>
                <br />
                {"    "}
                <span class="redChar">{"}"}</span>
                <br />
                {"  "}
                <span class="redChar">]</span>
                <br />
                {"  "}"errors"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{}"}</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"meta"<span class="whiteChar">:</span>{" "}
                <span class="redChar">{"{"}</span>
                <br />
                {"    "}"counter_categories"<span class="whiteChar">:</span>{" "}
                <span class="whiteChar">1</span>
                <br />
                {"  "}
                <span class="redChar">{"}"}</span>
                <br />
                <span class="redChar">{"}"}</span>
                <br />
              </span>
            </pre>
          </div>

          <div class="content">
            <HashLink
              to={
                "/v4-documentation/automatic-tagging#tag-one-product-validate-product"
              }
            >
              <h4 class="content" id="tag-one-product-validate-product">
                Tag One Product - Validate Product
              </h4>
            </HashLink>
          </div>
          <div class="content">
            <pre class="mainLink">
              PUT /v4/solutions/auto-tag/products/{"{"}product_id{"}"}
            </pre>
          </div>
          <p class="content">
            This endpoint can be used to validate the tags of the products.
            During the automatic tagging, some tags can be mistaken, so
            validation is good to do.
            <br /> <br />
            The correct data must be sent in the default taxonomy.The structure
            of the request body should be the same as the response with the
            changes made.
          </p>
          <p class="content">
            There are two cases during validation:
            <br />
            <br />
            1. All the tags are true, no changes are needed.
            <br />
            When this is the case, the API will only mark this product as
            validated.
            <br />
            <br />
            2. There are changes in some of the tags.
            <br />
            If this is the case, the API will expect the correct data sent by
            the user.
          </p>

          <h4 class="content unbold">Request Parameters</h4>
          <div class="content">
            <table class="content">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>product_id</td>
                  <td>
                    A valid id of one of the products that you want to update
                  </td>
                </tr>
                <tr>
                  <td>category</td>
                  <td>
                    The correct category of the product, which is validated
                  </td>
                </tr>
                <tr>
                  <td>detected_attributes_types</td>
                  <td>Empty array/ An array of objects for the correct data</td>
                </tr>
                <tr>
                  <td>verified</td>
                  <td>A boolean field to mark the product as verified</td>
                </tr>
              </tbody>
            </table>
          </div>
          {langCode == "js" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="letBlue">let </span>
                <span className="blue">bodyContent</span> = {"{"}{" "}
                <span className="txt">"category"</span>:{" "}
                <span className="txt">"denim-pants"</span>,<br />
                {"       "}
                <span className="txt">"detected_attributes_types"</span>: [],
                <span className="txt"> "verified"</span>:{" "}
                <span className="letBlue">true</span> {"}"}
                <br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/products/product2_id"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"PUT"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"                   "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})
              </pre>
              <br />
              <pre>
                <span class="grayChar"># example request with changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                <span className="letBlue">let </span>
                <span className="blue">bodyContent</span> = {"{"}{" "}
                <span className="txt">"category"</span>:{" "}
                <span className="txt">"jeans"</span>,<br />
                {"       "}
                <span className="txt">"detected_attributes_types"</span>: [{"{"}{" "}
                <span className="txt">"attribute_type"</span>:{" "}
                <span className="txt">"rise"</span>,<br />
                {"       "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}{" "}
                <span className="txt">"attribute"</span>:{" "}
                <span className="txt">"high-rise" </span>
                {"}]}}"},<br />
                {"       "}
                {"{"} <span className="txt">"attribute_type"</span>:{" "}
                <span className="txt">"bottoms_length"</span>,<br />
                {"       "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}{" "}
                <span className="txt">"attribute"</span>:{" "}
                <span className="txt">"3-4-long" </span>
                {"}]}}]"},<br />
                {"       "}
                <span className="txt">"verified"</span>:
                <span className="letBlue"> true</span> {"}"} <br />
                {"  "}
                <span className="yellow">fetch</span>({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/products/product2_id"
                </span>
                , {"{"}
                <br />
                {"       "}
                <span className="blue">method</span>:{" "}
                <span className="txt">"PUT"</span>,<br />
                {"       "}
                <span className="blue">headers</span>: {"{"}{" "}
                <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"                  "}
                <span className="txt">"Authorization"</span>:
                <span className="txt"> `Bearer</span>{" "}
                <span className="letBlue">${"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">`</span> {"}"},<br />
                {"       "}
                <span className="blue">body</span>:{" "}
                <span className="blue">JSON</span>.
                <span className="yellow">stringify</span>(
                <span className="blue">bodyContent</span>)<br />
                {"     "}
                {"}"})<br />
              </pre>
            </div>
          ) : langCode == "python" ? (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"} <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"              "}
                <span className="txt">"Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">"</span> {"}"}
                <br />
                {"  "}data = {"{"} <span className="txt">"category"</span>:{" "}
                <span className="txt">"denim-pants"</span>,
                <span className="txt"> "detected_attributes_types"</span>: [],
                <br />
                {"           "}
                <span className="txt">"verified"</span>:
                <span className="letBlue"> True </span>
                {"}"}
                <br />
                {"  "}response = requests.put
                <br />
                {"       "}({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/products/product2_id"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">data</span> = json.dumps(data)
                <br />
                {"      "}) <br />
                <br />
                <span class="grayChar"># example request with changes</span>
                <br />
                <br />
                <span class="grayChar">$ </span>
                headers = {"{"} <span className="txt">"Content-Type"</span>:
                <span className="txt"> "application/json"</span>,<br />
                {"              "}
                <span className="txt">"Authorization"</span>:{" "}
                <span className="letBlue">f</span>
                <span className="txt">"Bearer </span>
                <span className="letBlue">{"{"}</span>
                <span className="blue">access_token</span>
                <span className="letBlue">{"}"}</span>
                <span className="txt">"</span> {"}"}
                <br />
                {"  "}data = {"{"} <span className="txt">"category"</span>:{" "}
                <span className="txt">"jeans"</span>,<br />
                {"           "}
                <span className="txt">"detected_attributes_types"</span>: [{"{"}{" "}
                <span className="txt">"attribute_type"</span>:{" "}
                <span className="txt">"rise"</span>,<br />
                {"           "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}
                <span className="txt"> "attribute"</span>:{" "}
                <span className="txt">"high-rise" </span>
                {"}]}}"},<br />
                {"           "}
                {"{"} <span className="txt">"attribute_type"</span>:{" "}
                <span className="txt">"bottoms_length"</span>,<br />
                {"           "}
                <span className="txt">"attributes"</span>: {"{"}{" "}
                <span className="txt">"correct"</span>: [{"{"}{" "}
                <span className="txt">"attribute"</span>:{" "}
                <span className="txt">"3-4-long" </span>
                {"}]}}]"},<br />
                {"           "}
                <span className="txt">"verified"</span>:
                <span className="letBlue"> True </span>
                {"}"}
                <br />
                {"  "}response = requests.put
                <br />
                {"       "}({" "}
                <span className="txt">
                  "https://pva.pixyle.ai/v4/solutions/auto-tag/products/product2_id"
                </span>
                ,<br />
                {"       "}
                <span className="blue">headers</span> = headers,
                <br />
                {"       "}
                <span className="blue">data</span> = json.dumps(data)
                <br />
                {"      "}) <br />
              </pre>
            </div>
          ) : (
            <div class=" theCode">
              <pre>
                <span class="grayChar"># example request without changes</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Content-Type:
                application/json' \<br />
                {"       "}-H 'Authorization: Bearer access_token' \<br />
                {"       "}--data '{"{"}"category": "denim-pants",
                "detected_attributes_types": [], "verified": true{"}"}' \<br />
                {"       "}-X PUT https://pva.pixyle.ai/v4/solutions/auto-tag \
                <br />
                {"       "}/products/product2_id
                <br />
                <br />
                <span class="grayChar"># example request with changes</span>
                <br />
                <br />
                <span class="grayChar">$</span> curl -H 'Content-Type:
                application/json' \<br />
                {"       "}-H 'Authorization: Bearer access_token' \<br />
                {"       "}--data '{"{"}"category": "jeans",
                <br />
                {"       "}"detected_attributes_types": [{"{"}"attribute_type":
                "rise", \<br />
                {"       "}"attributes": {"{"}"correct": [{"{"}"attribute":
                "high-rise"{"}]}}"}, \<br />
                {"       "}
                {"{"}"attribute_type": "bottoms_length", \<br />
                {"       "}"attributes": {"{"}"correct": [{"{"}"attribute":
                "3-4-long" {"}]}}]"},\
                <br />
                {"       "}"verified": true{"}"}' \<br />
                {"       "}-X PUT https://pva.pixyle.ai/v4/solutions/auto-tag \
                <br />
                {"       "}/products/product2_id
              </pre>
            </div>
          )}
          <div class="content">
            <p class="content">
              After the product is validated successfully, the API returns a
              success message.
            </p>
          </div>
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example JSON response</span>
              <br />
              <br />
              <span class="orangeChar">
                <span class="redChar">{"{"}</span>
                <br />
                {"  "}"status"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">"OK"</span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"method"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">
                  "autotag/products/{"{"}product_id{"}"}"
                </span>
                <span class="whiteChar">,</span>
                <br />
                {"  "}"message"<span class="whiteChar">:</span>{" "}
                <span class="yellChar">
                  "Validation successful. Correct data is stored."
                </span>
                <br />
                <span class="redChar">{"}"}</span>
              </span>
            </pre>
          </div>
        </div>

        {openModal ? (
          <Modal setOpenModal={setOpenModal} sol={"at"} img={img} />
        ) : null}
      </main>
    </div>
  );
};

export default AutomaticTagging;
