import React,{useEffect} from 'react'
import { useStore } from 'react-context-hook';
import { HashLink } from 'react-router-hash-link';

const AutomaticRescale = () => {
  const [langCode, setLangCode] = useStore("langCode",'');

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br />
        <h3 class="content" id="automatic_rescale">3. Automatic Rescale</h3>
        <div class="content">
          <p class="content">
            <HashLink to={'/v4-documentation/automatic_rescale#start_solution'}>
              <h4 class="content" id="start_solution">Start Solution</h4></HashLink><br />
            Pixyle's scaling images solution detects fashion items in the image and scales the image proportionally so that the garments inside fit proportionally to the human body. This solution works with garments on a plain background, without a model wearing them.
            <br /><br />
            You can use it by simply uploading your image collection. Pixyle will then localize the fashion item, detect the category and scale the images proportionally to the human body. After the process is finished you will get a JSON as a response, filled with the rescaling data information. This information will allow you to perform resizing and cutting of the fashion item only for your catalogue images.
          </p>
          <div class="content">
            <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale</pre>
          </div>
        </div>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, to start the auto-scale process</td>
            </tr>
          </tbody>
        </table>
     
        {langCode == "js" ?  
        <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
            {'       '}/solutions/auto-scale"</span>, {'{'}<br />
            {'       '}<span className="blue">method</span>: <span className="txt">"GET"</span>,<br />
            {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span>{'}'}<br />
            {'     '}{'}'})<br />
          </pre>
        </div> 
          :  langCode == "python" ? 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />

            {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
            {'       '}/solutions/auto-scale"</span>,<br/>
            {'       '}<span className="blue">headers</span> = headers<br />
            {'     '})<br />
            </pre></div>
          : 
            <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133/solutions/auto-scale</pre>
        </div>
 
          }
        <h4 class="content unbold">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>collection_name</td>
              <td>Name of the collection</td>
            </tr>
            <tr>
              <td>start_time</td>
              <td>The time when the automatic scaling process has started</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"auto-scale"</span><span class="whiteChar">,</span><br />
              {'  '}"message"<span class="whiteChar">:</span> <span class="yellChar">"Automatic scaling process has started, please check status..."</span><span class="whiteChar">,</span><br />
              {'  '}"meta"<span class="whiteChar">:</span><span class="redChar">{' {'}</span><br />
              {'    '}"dataset_id"<span class="whiteChar">:</span> <span class="whiteChar">1570624498775133,</span><br />
              {'    '}"collection_name"<span class="whiteChar">:</span> <span class="yellChar">"images.csv"</span><span class="whiteChar">,</span><br />
              {'    '}"start_time"<span class="whiteChar">:</span> <span class="yellChar">"Tue, 27 Dec 2019 09:23:36"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <div class="content">
        <HashLink to={'/v4-documentation/automatic_rescale#solution_status'}>
          <h4 class="content" id="solution_status">Solution Status</h4></HashLink>
        <p class="content">The user will get this response immediately, and in the backend, Pixyle starts processing the dataset. Depending on the number of images, this may take some time, so you can check the scaling process status on the following endpoint.</p><br/>
          <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale/status</pre>
        </div>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, where the automatic scaling process is started or finished</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
            {'       '}/solutions/auto-scale/status"</span>, {'{'}<br />
            {'       '}<span className="blue">method</span>: <span className="txt">"GET"</span>,<br />
            {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
            {'     '}{'}'})<br />
            </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
          <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
            {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
            {'       '}/solutions/auto-scale/status"</span>,<br/>
            {'       '}<span className="blue"> headers</span> = headers<br />
          {'     '})<br />
          </pre></div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
            {'       '}/solutions/auto-scale/status</pre>
            </div>
          }
        
       
        <p class="content">Pixyle will return the amount and percentage state of scaled products at the time the request has been made. </p>
        <h4 class="content unbold">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>counter_products</td>
              <td>Total number of products</td>
            </tr>
            <tr>
              <td>processed_products</td>
              <td>Number of products successfully scaled</td>
            </tr>
            <tr>
              <td>processed_products_percentage</td>
              <td>Percentage value of the number of processed products, over the total number of products</td>
            </tr>
            <tr>
              <td>dataset_count</td>
              <td>Number of datasets by the user</td>
            </tr>
            <tr>
              <td>collection_name</td>
              <td>Name of the collection</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"auto-scale/status"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"meta"<span class="whiteChar">: </span><span class="redChar">{'{'}</span><br />
              {'    '}"counter_products"<span class="whiteChar">: 1000,</span><br />
              {'    '}"processed_products"<span class="whiteChar">: 100,</span><br />
              {'    '}"processed_products_percentage"<span class="whiteChar">: 10.0</span><br />
              {'    '}"collection_name"<span class="whiteChar">: </span> <span class="yellChar">"images.csv"</span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_count"<span class="whiteChar">: 2,</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <p class="content">When Pixyle finishes with the autoscale process, you can get the general result about the categories in this dataset, or the result for one product. You can get the autotag result, only after the autoscale process is finished.</p>
        <div class="content">
          <HashLink to={'/v4-documentation/automatic_rescale#get_categories'}>
            <h4 class="content" id="get_categories">Get Categories</h4> </HashLink>
          <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale/categories</pre>
          <p class="content">
            Тo make the process easier to manage, you can get access to all detected categories on this endpoint. Later, this can help you to see scale information for each product.
          </p>
        </div>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, where the automatic scaling process is started or finished</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
            {'       '}/solutions/auto-scale/categories"</span>, {'{'}<br />
            {'       '}<span className="blue">method</span>: <span className="txt">"GET"</span>,<br />
            {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
            {'     '}{'}'})<br />
          </pre>
        </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
            {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
            {'       '}/solutions/auto-scale/categories"</span>,<br/>
            {'       '}<span className="blue">headers</span> = headers<br />
            {'     '})<br />
            </pre></div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
            {'       '}/solutions/auto-scale/categories</pre>
        </div>
          }
        
        
        <h4 class="content unbold">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>detected_categories</td>
              <td>An array of objects for all detected categories from the dataset. Each object consists of a corresponding category and number of products associated with it</td>
            </tr>
            <tr>
              <td>category</td>
              <td>Name of a detected category in the data file</td>
            </tr>
            <tr>
              <td>primary_image</td>
              <td>An image that represents the category</td>
            </tr>
            <tr>
              <td>counter_products</td>
              <td>Number of products in the category</td>
            </tr>
            <tr>
              <td>collection_name</td>
              <td>Name of the collection</td>
            </tr>
            <tr>
              <td>counter_categories</td>
              <td>Number of categories detected in the data file</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"detected_categories"<span class="whiteChar">: </span> <span class="redChar">[</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}"category"<span class="whiteChar">: </span><span class="yellChar"> "skirts"</span><span class="whiteChar">,</span><br />
              {'      '}"counter_products"<span class="whiteChar">: 70,</span><br />
              {'      '}"primary_image"<span class="whiteChar">: </span><span class="yellChar"> "https://image1.jpg"</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}"category"<span class="whiteChar">: </span><span class="yellChar"> "jeans"</span><span class="whiteChar">,</span><br />
              {'      '}"counter_products"<span class="whiteChar">: 150,</span><br />
              {'      '}"primary_image"<span class="whiteChar">: </span><span class="yellChar"> "https://image2.jpg"</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'      '}<span class="whiteChar">...</span><br />
              {'  '}<span class="redChar">]</span><span class="whiteChar">,</span><br />
              {'  '}"meta"<span class="whiteChar">: </span> <span class="redChar">{'{'}</span><br />
              {'    '}"counter_categories"<span class="whiteChar">: 10</span><span class="whiteChar">,</span><br />
              {'    '}"collection_name"<span class="whiteChar">: </span> <span class="yellChar">"images.csv"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <div class="content">
          <HashLink to={'/v4-documentation/automatic_rescale#get_category_products'}><h4 class="content" id="get_category_products">
            Get Category Products
          </h4></HashLink>
          <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale/categories/<i>{'{category}'}</i>/products</pre>
          <p class="content">
            You can get access to all detected products in a specific category on this endpoint.
          </p>
        </div>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, where the automatic scaling process is started or finished</td>
            </tr>
            <tr>
              <td>category</td>
              <td>A valid detected category in the data file</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
            <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
              {'       '}/solutions/auto-scale/categories/jeans/products"</span>, {'{'}<br />
              {'       '}<span className="blue">method</span>: <span className="txt">"GET"</span>,<br />
              {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
              {'     '}{'}'})<br />
            </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
          <span class="grayChar">$ </span>
          headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
          {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
          {'       '}/solutions/auto-scale/categories/jeans/products"</span>,<br/>
          {'       '}<span className="blue">headers</span> = headers<br />
          {'     '})<br />
          </pre></div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
            {'       '}/solutions/auto-scale/categories/jeans/products</pre>
        </div>
          }
        
      
        <h4 class="content unbold">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>category</td>
              <td>Name of the category requesting which products are requested</td>
            </tr>
            <tr>
              <td>result</td>
              <td>An array of objects for all products associated with the requested category  </td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>An id of a product</td>
            </tr>
            <tr>
              <td>primary_image</td>
              <td>The primary image of the product</td>
            </tr>
            <tr>
              <td>product_coordinates</td>
              <td>Coordinates of the box around the detected product (top-left corner and bottom-right)</td>
            </tr>
            <tr>
              <td>scale_information</td>
              <td>An object that contains the scaling information</td>
            </tr>
            <tr>
              <td>counter_products</td>
              <td>Number of products in the requested category</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">: </span><span class="yellChar"> "OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span><span class="yellChar"> "categories/jeans/products"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"category"<span class="whiteChar">: </span><span class="yellChar"> "jeans"</span><span class="whiteChar">,</span><br />
              {'  '}"result"<span class="whiteChar">: </span> <span class="redChar">[</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}"product_id"<span class="whiteChar">: </span><span class="yellChar"> "product2_id"</span><span class="whiteChar">,</span><br />
              {'      '}"primary_image"<span class="whiteChar">: </span><span class="yellChar"> "https://image2.jpg"</span><span class="whiteChar">,</span><br />
              {'      '}"scale_information"<span class="whiteChar">: </span><span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}<span class="whiteChar">...</span><br />
              {'  '}<span class="redChar">]</span><br />
              {'  '}"meta"<span class="whiteChar">: </span> <span class="redChar">{'{'}</span><br />
              {'    '}"collection_name"<span class="whiteChar">: </span><span class="yellChar"> "images.csv"</span><span class="whiteChar">,</span><br />
              {'    '}"counter_products"<span class="whiteChar">: 15</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <div class="content">
          <HashLink to={'/v4-documentation/automatic_rescale#get_product'}><h4 class="content" id="get_product">Get Product</h4></HashLink>
        </div>
        <div class="content">
          <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale/products/<i>{'{product_id}'}</i></pre>
        </div>
        <p class="content">
          After the rescaling process finished, you can use this endpoint to get scaled data for one product.
        </p>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, where the automatic scaling process is started or finished</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>A valid id of one of your datasets products, you want to get scale information for</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span><span className="yellow"><span className="yellow">fetch</span></span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
             {'       '}/solutions/auto-scale/products/product2_id"</span>, {'{'}<br />
             {'       '}<span className="blue">method</span>: <span className="txt">"GET"</span>,<br />
             {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
              {'     '}{'}'})<br />
            </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
          <span class="grayChar">$ </span>
          headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
          {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
          {'       '}/solutions/auto-scale/products/product2_id"</span>,<br/>
          {'       '}<span className="blue">headers</span> = headers<br />
          {'     '})<br />
          </pre></div>
          :
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
            {'       '}/solutions/auto-scale/products/product2_id</pre>
        </div> 
          }
        
        
        <h4 class="content unbold">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>An id of the requested product</td>
            </tr>
            <tr>
              <td>category</td>
              <td>Category of the requested product</td>
            </tr>
            <tr>
              <td>primary_image</td>
              <td>The primary image of the requested product</td>
            </tr>
            <tr>
              <td>scale_factor</td>
              <td>A number that shows how much the image should be rescaled</td>
            </tr>
            <tr>
              <td>product_coordinates</td>
              <td>Coordinates of the box around the detected product (top-left corner and bottom-right)</td>
            </tr>
            <tr>
              <td>custom_field</td>
              <td>Information about a certain field. (eg. title, description etc. )</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"auto-scale/products/get"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"result"<span class="whiteChar">: </span><br />
              {'  '}<span class="redChar">{'{'}</span><br />
              {'    '}"product_id"<span class="whiteChar">: </span> <span class="yellChar">"product1_id"</span><span class="whiteChar">,</span><br />
              {'    '}"title"<span class="whiteChar">: </span> <span class="yellChar">"product1_title"</span><span class="whiteChar">,</span><br />
              {'    '}"category"<span class="whiteChar">: </span> <span class="yellChar">"jeans"</span><span class="whiteChar">,</span><br />
              {'    '}"scale_factor"<span class="whiteChar">: </span> <span class="whiteChar"> 0.75,</span><br />
              {'    '}"product_coordinates"<span class="whiteChar">: </span><span class="redChar"> {'{'}</span><br />
              {'      '}"x_min"<span class="whiteChar">: 516,</span><br />
              {'      '}"y_min"<span class="whiteChar">: 569,</span><br />
              {'      '}"x_max"<span class="whiteChar">: 1063,</span><br />
              {'      '}"y_max"<span class="whiteChar">: 1695</span><br />
              {'    '}<span class="redChar">{'}'}</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              {'  '}"meta"<span class="whiteChar">: </span><span class="redChar">{'{'}</span><br />
              {'    '}"collection_name"<span class="whiteChar">: </span><span class="yellChar">"images.csv"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <div class="content">
          <HashLink to={'/v4-documentation/automatic_rescale#download_file'}><h4 class="content" id="download_file">
            Download File
          </h4></HashLink>
        </div>
        <div class="content">
          <pre class="mainLink">POST /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/auto-scale/files/<i>{'{file_type}'}</i></pre>
        </div>
        <p class="content">
          Taking into account the number of products in a dataset, the best way for you to get the scale data for each one of them is to use this endpoint.If the request body is empty the returned file will consist of data only from the auto-scale solution.<br /><br />
          You can use additional optional parameters to get the data from the other solutions in the same file.To get the result data from another solution, just add the solution name followed with “true” value in the request body.<br /><br />
          The response you will get here is a file, which can be either JSON or CSV.
        </p>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, where the automatic scaling process is started or finished</td>
            </tr>
            <tr>
              <td>file_type</td>
              <td>A string that represents the type of file you want to get the result file in.</td>
            </tr>
            <tr>
              <td>solution_name</td>
              <td>A name of a solution, which the result data will be added in the file.<br />
                Possible solution_name =[“auto-tag”]</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span><span className="yellow"><span className="yellow">fetch</span></span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
            {'       '}/solutions/auto-scale/files/csv"</span>, {'{'}<br />
            {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
            {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
            {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>({'{'}<span className=""><span className="txt"> "auto-tag"</span>:<span className="letBlue"> true </span></span>{'}'})<br/> 
            {'     '}{'}'})<br />
          </pre>
        </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
            {'  '}data = {'{'}<span className="txt">"auto-tag"</span>:<span className="letBlue"> True </span>{'}'}<br/>
            {'  '}requests.post( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br/>
            {'       '}/solutions/auto-scale/files/csv"</span>, <br />
            {'       '}<span className="blue">headers</span> = headers,<br />
            {'       '}<span className="blue">data</span> = json.dumps(data) <br/>
            {'     '})<br />
            </pre>
          </div>
          :
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}--data '{'{'}"auto-tag": true{'}'}' \<br />
            {'       '}-X POST https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
            {'       '}/solutions/auto-scale/files/csv
          </pre>
        </div> 
          }
        
        
        <p class="content" style={{ marginBottom: '10px' }}>You can download the file from the response.</p>
        <br />
        <div class="content">
          <p class="content">
            <HashLink to={'/v4-documentation/automatic_rescale#scale_one_image'}>
              <h4 class="content" id="scale_one_image">
                Scale One Image
              </h4></HashLink><br />
            Pixyle can also scale only one image. In this case, there is no need for you to create a dataset. You can simply upload a photo on this endpoint. After a short amount of time, you will get a  JSON object as a response, containing the detected category, a scale factor and the bounding box coordinates of the localized fashion item.
          </p>
          <br />
          <p class="content">
            An optional category field is also available on this endpoint. For more accurate results you can assign the category of the image in the request.
          </p>
          <br />
          <p class="content">
            If no category is sent with the image, Pixyle will detect each product but will scale only the biggest one.
          </p>
          <div class="content">
            <pre class="mainLink">POST /v4/solutions/auto-scale/image</pre>
          </div>
        </div>
        <h4 class="content unbold">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>image</td>
              <td>A valid image file or a valid image URL as a string</td>
            </tr>
            <tr>
              <td>category</td>
              <td>The category of the product in the image you want to scale</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
           <div class=" theCode">
           <pre>
             <span class="grayChar"># example request with image file</span><br /><br />
             <span class="grayChar">$ </span> 
             {/* <span className="letBlue">let</span>  */}
             {/* <span className="blue">files</span>= <span className="txt">'@image1.jpg'</span><br /> */}
             <span className="letBlue">const</span> <span className="blue">formData</span> = <span className="letBlue">new</span> <span className="green">FormData()</span>;<br />
             {'  '}<span className="blue">formData</span>.<span className="yellow">append</span>( <span className="txt">"image"</span>, <span className="blue">file</span> );<br />
             {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/solutions/auto-scale/image"</span>, {'{'}<br />
             {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
             {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "multipart/form-data"</span>,<br />
             {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
             {'       '}<span className="blue">body</span>: <span className="letBlue">formData</span> <br />
             {'     '}{'}'})<br />
          
           <br />
           <span class="grayChar"># example request with image URL</span><br /><br />
           <span class="grayChar">$ </span>
           <span className="letBlue">let</span> <span className="blue">bodyContent</span> = {'{'}
           <span className="txt"> "image"</span>:<span className="txt"> "https://store.image1.jpg"</span>,<span className="txt"> "category"</span>: <span className="txt">"jeans" </span>{'}'} <br/>
             {'  '}<span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/solutions/auto-scale/image"</span>, {'{'}<br />
             {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
             {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Content-Type"</span>:<span className="txt"> "multipart/form-data"</span>,<br />
             {'                  '}<span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'},<br />
             {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>(<span className="blue">bodyContent</span>)<br/>
             {'     '}{'}'})<br />
             </pre>  
           </div>
          : langCode == "python" ?  
          <div class=" theCode">
          <pre><span class="grayChar"># example request with image file</span><br /><br />
          <span class="grayChar">$ </span>
          headers = {'{'}
                  <span className="txt"> "Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>" </span>
                  {'}'} <br/>
          {'  '}files = {'{'}
              <span className="txt"> "image"</span>: (<span className="txt"> "image1.jpg"</span>, <span className="yellow">open</span>( <span className="txt">"image1.jpg"</span>, <span className="txt">"rb" </span>), )
                          {'}'}<br/>
             
          {'  '}response = requests.post<br/>
          {'       '}( <span className="txt">"https://pva.pixyle.ai/v4/solutions/auto-scale/image"</span>,<br />
          {'       '}<span className="blue">headers</span> = headers,<br />
          {'       '}<span className="blue">files</span> = files <br />
          {'      '}) <br /><br />
          <span class="grayChar"># example request with image URL</span><br /><br />
          <span class="grayChar">$ </span>
          headers = {'{'}
                  <span className="txt"> "Authorization"</span>: <span className='letBlue'>f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>" </span>
                  {'}'} <br/>
          {'  '}files = {'{'}
          <span className="txt"> "image"</span>: (<span className="blue"> None</span>, <span className="txt">"https://store.image1.jpg" </span>),<br/>
          {'            '}<span className="txt">"category"</span>: (<span className="blue"> None</span>, <span className="txt">"jeans" </span>) {'}'}<br/>
         
          {'  '}response = requests.post<br/>
          {'       '}( <span className="txt">"https://pva.pixyle.ai/v4/solutions/auto-scale/image"</span>,<br/>
          {'       '}<span className="blue">headers</span> = headers,<br />
          {'       '}<span className="blue">files</span> = files <br />
          {'      '}) <br />
          </pre>
        </div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request with image file</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
            {'       '}-H 'Authorization: Bearer access_token' \<br />
            {'       '}-F 'image=@image1.jpg' \<br />
            {'       '}-X POST https://pva.pixyle.ai/v4/solutions/auto-scale/image<br /><br />
            <span class="grayChar"># example request with image url</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br />
            {'       '}-H 'Authorization: Bearer access_token' \<br />
            {'       '}-F 'image=https://store.image1.jpg' \<br />
            {'       '}-F 'category=jeans' \<br />
            {'       '}-X POST https://pva.pixyle.ai/v4/solutions/auto-scale/image
          </pre>
        </div>

          }
       
        <h4 class="content unbold">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>category</td>
              <td>The category of the product in the image you want to scale</td>
            </tr>
            <tr>
              <td>scale_factor</td>
              <td>A number that indicates how much the image needs to be scaled</td>
            </tr>
            <tr>
              <td>product_coordinates</td>
              <td>Coordinates of the box around the detected product (top-left corner and bottom-right)</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">: </span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">: </span> <span class="yellChar">"auto-scale/image"</span><span class="whiteChar">,</span><br />
              {'  '}"img_name"<span class="whiteChar">: </span> <span class="yellChar">"image1.jpg"</span><span class="whiteChar">,</span><br />
              {'  '}"category"<span class="whiteChar">: </span> <span class="yellChar">"jeans"</span><span class="whiteChar">,</span><br />
              {'  '}"scale_factor"<span class="whiteChar">: </span> <span class="whiteChar"> 0.75,</span><br />
              {'  '}"product_coordinates"<span class="whiteChar">: </span><span class="redChar"> {'{'}</span><br />
              {'    '}"x_min"<span class="whiteChar">: 516,</span><br />
              {'    '}"y_min"<span class="whiteChar">: 569,</span><br />
              {'    '}"x_max"<span class="whiteChar">: 1063,</span><br />
              {'    '}"y_max"<span class="whiteChar">: 1695</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
      </div>
    </main>
  </div>
  )
}

export default  AutomaticRescale