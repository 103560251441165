import React from "react";
import { useStore } from "react-context-hook";

const Login = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content">
        <div class="content"><br/>
          <h2 class="content" id="api_overview">
            API Overview
          </h2><br/>
          <h3 class="content" id="introduction">
            Introduction
          </h3><br/>
          <p class="content">
            Pixyle’s API is accurate, powerful and integrates seamlessly with any tech stack. It provides endpoints that allow developers to index their data and use our solutions efficiently. Here you can find detailed guidelines on how to use Pixyle's API. We are continuously improving the accuracy of our Visual AI technology and all solutions, and releasing new versions twice a year. At any release, the API documentation will be updated.
          </p><br/>
          <h3 class="content" id="login">
            Login
          </h3>
          <br />
          <p class="content">
            After we give you your username and password, you can use them to log in on this endpoint. As a response you will get access_token and refresh_token, so later you can use them on other endpoints.
          </p>
          <div class="content">
            <pre class="mainLink">POST /v3/users/login</pre>
          </div>
          <h4 class="content unbold">Request Parameters</h4>
        </div>
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>username</td>
                <td>The username you are provided with</td>
              </tr>
              <tr>
                <td>password</td>
                <td>The password you are provided with</td>
              </tr>
            </tbody>
          </table>
        </div>
        {langCode== 'js' ? 
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span><span className="yellow">fetch</span>(<span className="txt">"https://pva.pixyle.ai/v3/users/login"</span>, {'{'}<br />
              {'       '}<span className="blue">method</span>: <span className="txt">"POST"</span>,<br />
              {'       '}<span className="blue">headers</span>: {'{'}<span className="txt">"Content-Type"</span>: <span className="txt">"application/json"</span>{'}'},<br />
              {'       '}<span className="blue">body</span>: <span className="blue">JSON</span>.<span className="yellow">stringify</span>({'{'}"username":"FrontEnd","password":"password"{'}'})<br />
              {'     '}{'}'})<br />
            </pre>
          </div>
        : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
             <span class="grayChar">$ </span>
             data={'{'}"username":"FrontEnd", "password":"password"{'}'}<br/>
             {'  '}requests.post(<span className="txt">"https://pva.pixyle.ai/v3/users/login"</span>, <br />
             {'       '}<span className="blue">headers</span>= {'{'}<span className="txt">"Content-Type"</span>: <span className="txt">"application/json"</span>{'}'},<br />
             {'       '}<span className="blue">data</span>=json.dumps(data)<br />
             {'     '})<br />
            </pre>
          </div>
        : <div class=" theCode">
          <pre><span class="grayChar"># example request</span> <br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \  <br />
            {'      '} --data '{'{'}"username":"FrontEnd","password":"password"{'}'}' \ <br />
            {'      '} -X POST https://pva.pixyle.ai/v3/users/login
          </pre>
        </div>}
      
        <br />
        <h4 class="content unbold">Response properties</h4>
        <br />
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>access_token</td>
                <td>A unique token for the user, to use for authorization</td>
              </tr>
              <tr>
                <td>refresh_token</td>
                <td>A refresh token, to get new access token, when the old one is expired</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" theCode">
          <pre><span class="grayChar"># example JSON response</span><br /><br />
            <span class="redChar">{'{'}</span><br />
            {'    '}<span class="orangeChar">"access_token"</span>: <span class="yellChar">"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE...5Kxsf8txew3c"</span>,<br />
            {'    '}<span class="orangeChar">"refresh_token"</span>: <span class="yellChar">"eyJ0eXAiOiJKV1QiLCbGciOiJIUzI1NiJ9.eyJpYXQiOj...6cmfmjFbu6mHE2g"</span><br />
            <span class="redChar">{'}'}</span></pre>
        </div>
      </div>
    </main>
  </div>
  )
}

export default Login