import React from "react";
import { useStore } from "react-context-hook";

const RenameFile = () => {
  const [langCode, ] = useStore("langCode",'');

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
        <h3 class="content" id="rename_file">
          Rename File
        </h3>

        <div class="content">
          <div class="content">
            <pre class="mainLink">PUT /v2/autotag/rename</pre>
          </div>

          <p class="content">
            You can use this endpoint to rename your dataset. Please provide the API endpoint with the correct dataset id and the new name.
          </p>
          <br />
          <h4 class="content unbold">Request Parameters</h4>
        </div>
        <div class="content">

          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>Valid dataset_id, you want to rename it</td>
              </tr>
              <tr>
                <td>title</td>
                <td>New title of the dataset</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h4 class="content unbold">Response properties</h4>
          <br />
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>ID of the changed file</td>
              </tr>
              <tr>
                <td>new_dataset_title</td>
                <td>The new name of the dataset</td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class=" theCode">
          <pre>
          {langCode== 'js' ? 
          <>
          <span class="grayChar">$ </span>fetch("https://pva.pixyle.ai/v2/autotag/rename" , {'{'}<br />
              {'       '}method: "PUT",<br />
              {'       '}headers: {'{'}"Content-Type": "application/json", \<br />
              {'       '}"Authorization": "Bearer access_token", {'}'}<br />
              {'       '}body: '{'{'}"title":"changed_name.csv","dataset_id":1570624498775133{'}'}'<br />
              {'     '}{'}'})<br />
          </>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.put("https://pva.pixyle.ai/v2/autotag/rename", <br />
            {'       '}data ='{'{'}"title":"changed_name.csv","dataset_id":1570624498775133{'}'}'<br />
            {'       '}headers= {'{'}"Content-Type": "application/json", <br />
            {'      '}"Authorization": "Bearer access_token"  {'}'}<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <>
          <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Content-Type: application/json' \<br/>
            {'       '}-H 'Authorization: Bearer access_token' \<br/>
            {'       '}--data '{'{'}"title":"changed_name.csv","dataset_id":1570624498775133{'}'}' \<br/>
            {'       '}-X PUT https://pva.pixyle.ai/v2/autotag/rename<br/><br/>
          </>
          }
          <br />
            <span class="grayChar"># example JSON response</span><br/><br/>
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br/>
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"delete"</span><span class="whiteChar">,</span><br/>
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br/>
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br/>
              {'  '}"new_dataset_title"<span class="whiteChar">: <span class="yellChar">"changed_name.csv"</span></span><br/>
              <span class="redChar">{'}'}</span></span></pre>
        </div>
      </div>
    </main>
  </div>
  )
}

export default RenameFile