import React from "react";
import { HashLink } from 'react-router-hash-link';
import { useStore } from "react-context-hook";
const SimilarRecommendations = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br />
        <div class="content">
          <h3 class="content" id="similar_recommendations">
            5. Similar Recommendations
          </h3><br />
          <HashLink to={'/v4-documentation/similar-recommendations#start-Solution'}>
            <h4 class="content" id="start-Solution">Start Solution</h4></HashLink><br />
        </div>
        <p class="content">
          Our Similar Recommendations solution allows your customers to search against your online collection with existing images in it. To start using similar recommendations, you first need to start initializing the setup for that collection with the corresponding dataset_id. This will create all files necessary for similar recommendations to work for your online store.
        </p>
        <div class="content">
          <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/similar-recommendations</pre>
        </div>
        <h4 class="content">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, to mark for similar recommendations</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
            <div class=" theCode">
            <pre>
              <span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
              {'       '}/solutions/similar-recommendations"</span>, {'{'}<br />
              {'       '}<span className="blue">method</span>: <span className="txt">"GET"</span>,<br />
              {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
              {'     '}{'}'})<br />
            </pre>
        </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre> <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
              {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
              {'       '}/solutions/similar-recommendations"</span>,<br />
            {'       '}<span className="blue">headers</span> = headers<br />
            {'     '})<br />
            </pre>
          </div>
          :
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
            {'       '}/solutions/similar-recommendations</pre>
        </div> 
          }
        
      
        <h4 class="content">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>collection_name</td>
              <td>Name of the collection</td>
            </tr>
            <tr>
              <td>start_time</td>
              <td>The time when the similar recommendations process has started</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"similar-recommendations"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">:</span> <span class="whiteChar">1570624498775133,</span><br />
              {'  '}"message"<span class="whiteChar">:</span> <span class="yellChar">"Similar Recommendations initializing process has started..."</span><span class="whiteChar">,</span><br />
              {'  '}"meta"<span class="whiteChar">:</span><span class="redChar"> {'{'}</span><br />
              {'    '}"collection_name"<span class="whiteChar">:</span> <span class="yellChar">"images.csv"</span><span class="whiteChar">,</span><br />
              {'    '}"user"<span class="whiteChar">:</span> <span class="yellChar">FrontEnd,</span><br />
              {'    '}"start_time"<span class="whiteChar">:</span> <span class="yellChar">"Thu, 10 Jan 2020 09:19:34"</span><br />
              {'  '}<span class="redChar">{'}'}</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <p class="content">Once the similar recommendations initialization endpoint is called, you will get this response immediately. Depending on the number of images, this may take some time, so you can check the status of the similar recommendations process on the following endpoint.</p>
        <div class="content"> <br/>
          <HashLink to={'/v4-documentation/similar-recommendations#solution-Status'}>
            <h4 class="content" id="solution-Status">Solution Status</h4></HashLink>
        </div>
        <div class="content">
          <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/similar-recommendations/status</pre>
        </div>
        <h4 class="content">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, where the similar recommendations process is started or finished</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
           <div class=" theCode">
           <pre>
             <span class="grayChar"># example request</span><br /><br />
             <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
              {'       '}/solutions/similar-recommendations/status"</span>, {'{'}<br />
             {'       '}<span className="blue">method</span>: <span className="txt">"GET"</span>,<br />
             {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
             {'     '}{'}'})<br />
           </pre>
       </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre> <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
            {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
            {'       '}/solutions/similar-recommendations/status"</span>,<br />
            {'       '}<span className="blue">headers</span> = headers<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
            {'       '}/solutions/similar-recommendations/status</pre>
        </div>
          }
        <p class="content">This endpoint will send back information about the initialization progress of similar recommendations for the given dataset_id. </p>
        <h4 class="content">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>counter_products</td>
              <td>Total number of products</td>
            </tr>
            <tr>
              <td>processed_products</td>
              <td>Number of products successfully processed</td>
            </tr>
            <tr>
              <td>processed_products_percentage</td>
              <td>Percentage value of the number of processed products, over the total number of products</td>
            </tr>
            <tr>
              <td>dataset_count</td>
              <td>Number of datasets by the user</td>
            </tr>
            <tr>
              <td>collection_name</td>
              <td>Name of the collection</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"similar-recommendations/status"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"meta"<span class="whiteChar">:</span><span class="redChar"> {'{'}</span><br />
              {'    '}"counter_products"<span class="whiteChar">: 1000,</span><br />
              {'    '}"processed_products"<span class="whiteChar">: 100,</span><br />
              {'    '}"processed_products_percentage"<span class="whiteChar">: 10.0</span><span class="whiteChar">,</span><br />
              {'    '}"collection_name"<span class="whiteChar">:</span> <span class="yellChar">"images.csv"</span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_count"<span class="whiteChar">: 2</span><br />
              <span class="redChar">{'}'}</span></span></pre>
        </div>
        <p class="content">Once the similar recommendations initialization process is finished, the collection can be used for searching similars with an existing image in it, by sending a valid Product ID. We will use the detected fashion item in the query product image, and we'll return its visually similar products. A Product ID can be sent on the following endpoint.</p>
        <div class="content"><br />
        <HashLink to={'/v4-documentation/similar-recommendations#getSrByProduct'}>
            <h4 class="content" id="getSrByProduct">Get similar recommendations by product</h4></HashLink>
        </div>
        <div class="content">
          <pre class="mainLink">GET /v4/datasets/<i>{'{dataset_id}'}</i>/solutions/similar-recommendations/products/<i>{'{product_id}'}</i>
          </pre>
        </div>
        <h4 class="content">Request Parameters</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for a certain data file, where the similar recommendations process is finished</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>A valid id of one of datasets products, you want to get similars for</td>
            </tr>
          </tbody>
        </table>
        {langCode== 'js' ? 
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span><span className="yellow">fetch</span>( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
              {'       '}/solutions/similar-recommendations/products/product2_id"</span>, {'{'}<br />
              {'       '}<span className="blue">method</span>: <span className ="txt">"GET"</span>,<br />
              {'       '}<span className="blue">headers</span>: {'{'} <span className="txt">"Authorization"</span>:<span className="txt"> `Bearer</span> <span className='letBlue'>${'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}</span><span className='txt'>`</span> {'}'}<br />
              {'     '}{'}'})<br />
            </pre>
        </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre> <span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>
            headers = {'{'}<span className="txt"> "Authorization"</span>:<span className='letBlue'> f</span><span className="txt">"Bearer </span><span className='letBlue'>{'{'}</span><span className='blue'>access_token</span><span className='letBlue'>{'}'}<span className='txt'>" </span></span>{'}'}<br />
              {'  '}requests.get( <span className="txt">"https://pva.pixyle.ai/v4/datasets/1570624498775133<br />
              {'       '}/solutions/similar-recommendations/products/product2_id"</span>,<br />
            {'       '}<span className="blue">headers</span> = headers<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v4/datasets/1570624498775133 \<br />
            {'       '}/solutions/similar-recommendations/products/product2_id</pre>
        </div>
          }
        <p class="content">Pixyle returns an array of objects for each similar item for the product in the query image, sorted by their confidence score. Each object contains certain metadata for a similar product.</p>
        <h4 class="content">Response Properties</h4>
        <table class="content">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>dataset_id</td>
              <td>A unique identifier for the data file</td>
            </tr>
            <tr>
              <td>result</td>
              <td>An array of most visually similar products for the product item in the query image</td>
            </tr>
            <tr>
              <td>product_id</td>
              <td>A product id of the resulting product</td>
            </tr>
            <tr>
              <td>custom_field</td>
              <td>Information about a certain field. (eg. title, description etc.)</td>
            </tr>
            <tr>
              <td>counter_categories</td>
              <td>Number of categories detected in the query image</td>
            </tr>
          </tbody>
        </table>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br /><br />
            <span class="orangeChar">
              <span class="redChar">{'{'}</span><br />
              {'  '}"status"<span class="whiteChar">:</span> <span class="yellChar">"OK"</span><span class="whiteChar">,</span><br />
              {'  '}"method"<span class="whiteChar">:</span> <span class="yellChar">"similar-recommendations/products/get"</span><span class="whiteChar">,</span><br />
              {'  '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br />
              {'  '}"meta"<span class="whiteChar">:</span><span class="redChar"> {'{'}</span><br />
              {/* {'    '}"counter_categories"<span class="whiteChar">: 15,</span><br /> */}
              {'    '}"collection_name"<span class="whiteChar">:</span><span class="yellChar"> "images.csv"</span><br />
              {'  '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'  '}"result"<span class="whiteChar">:</span><span class="redChar"> [</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}"title"<span class="whiteChar">:</span> <span class="yellChar">""</span><span class="whiteChar">,</span><br />
              {'      '}"description"<span class="whiteChar">:</span> <span class="yellChar">""</span><span class="whiteChar">,</span><br />
              {'      '}"primary_image"<span class="whiteChar">:</span> <span class="yellChar">"https://www.projectcece.nl/static/img/products/942.jpg"</span><span class="whiteChar">,</span><br />
              {'      '}"primary_url"<span class="whiteChar">:</span> <span class="yellChar">"https://www.projectcece.nl/products/9b42.jpg"</span><span class="whiteChar">,</span><br />
              {'      '}"category"<span class="whiteChar">:</span> <span class="yellChar">"dress"</span><span class="whiteChar">,</span><br />
              {'      '}"product_id"<span class="whiteChar">:</span> <span class="yellChar">"12"</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}<span class="redChar">{'{'}</span><br />
              {'      '}"title"<span class="whiteChar">:</span> <span class="yellChar">""</span><span class="whiteChar">,</span><br />
              {'      '}"description"<span class="whiteChar">:</span> <span class="yellChar">""</span><span class="whiteChar">,</span><br />
              {'      '}"primary_image"<span class="whiteChar">:</span> <span class="yellChar">"https://www.projectcece.nl/static/img/products/87r.jpg"</span><span class="whiteChar">,</span><br />
              {'      '}"primary_url"<span class="whiteChar">:</span> <span class="yellChar">"https://www.projectcece.nl/products/8f7r.jpg"</span><span class="whiteChar">,</span><br />
              {'      '}"category"<span class="whiteChar">:</span> <span class="yellChar">"dress"</span><span class="whiteChar">,</span><br />
              {'      '}"product_id"<span class="whiteChar">:</span> <span class="yellChar">"156"</span><br />
              {'    '}<span class="redChar">{'}'}</span><span class="whiteChar">,</span><br />
              {'    '}<span class="redChar">{'{'}</span><span class="whiteChar">...</span><span class="redChar">{'}'}</span><br />
              {'  '}<span class="redChar">]</span><span class="whiteChar">,</span><br />
              {'  '}"counter_products"<span class="whiteChar">:</span> <span class="whiteChar">200</span><br />
              <span class="redChar">{'}'}</span></span>
          </pre>
        </div>
      </div>
    </main>
  </div>

  )
}

export default SimilarRecommendations