import React from "react";
import { useStore } from "react-context-hook";
const UploadFile = () => {
  const [langCode, ] = useStore("langCode",'');

  const textStyle = {
    fontSize: '12px',
    color: '#424b54',
    margin: '0px',
    padding: '0px',
    textDecoration: 'underline'
  }

  const secondTextStyle = {
    fontSize: '14px',
    color: '#424b54',
    margin: '0px',
    padding: '0px',
    textDecoration: 'underline'
  }

  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content">
        <h2 class="content" id="index_your_data"><br/>
          Index your data API
        </h2>

        <p class="content">
          Indexing defines the way we represent images and how we make your images available for tagging. Pixyle provides API endpoints for you to be able to manage your data feed (image collections). To add a data file, you call a specific endpoint with a file and a token. You can also remove a certain data file, and all its images. Please note, you need to structure your file correctly before uploading it.
        </p>

        <p class="content noteText">
          * Please note how to create and <a style ={textStyle} href="#organize_schema_fields">organize your data file</a> before uploading one.
        </p>

        <h3 class="content" id="upload_file_create">
          Upload file - Create
        </h3>

        <div class="content">
          <div class="content">
            <pre class="mainLink">POST /v2/create</pre>
          </div>


          <p class="content">
            This API endpoint is for uploading a data file to create a new dataset of your image collection.
          </p>
          <br />
          <h4 class="content unbold">Request parameters</h4>
          <br />
          <p class="content">
            The requested parameter for the upload to be successful is a file with images data. It must be in the following format:
          </p>


          <div class="content">
            <pre class="mainLink">{'{key}'} = value</pre>
          </div>

          <p class="content">
            Where key is file, and value is the file.
          </p>
        </div>

        <div class="content">

          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>file</td>
                <td>
                  A valid data file, which follows <a style={secondTextStyle} href="#organize_schema_fields">Pixyle’s Schema fields requirement</a>.
                </td>
              </tr>
            </tbody>
          </table>

          <p class="content">
            The maximum length of file name is 128.
          </p>
          <br />
          <h4 class="content unbold">Response properties</h4>
          <br />
          <table class="content" style={{marginBottom: '10px'}}>
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>
                  A unique identifier for a particular data file
                </td>
              </tr>
              <tr>
                <td>dataset_count</td>
                <td>
                  Number of datasets by this user
                </td>
              </tr>
              <tr>
                <td>collection_name</td>
                <td>
                  The original name of the collection
                </td>
              </tr>
              <tr>
                <td>errors</td>
                <td>
                  A list of objects containing information about failed images
                </td>
              </tr>
              <tr>
                <td>number_of_products</td>
                <td>
                  Amount of products in this dataset
                </td>
              </tr>
              <tr>
                <td>number_of_images</td>
                <td>
                  Amount of images in this dataset
                </td>
              </tr>
            </tbody>
          </table>
          <p class="content noteText">
            * Note the dataset_id, it will be necessary in other endpoints
          </p>
        </div>
    

        <div class=" theCode">
          <pre>
          <span class="grayChar"># example request</span><br /><br />
          {langCode== 'js' ? 
          
           <>
              <span class="grayChar">$ </span> let files = '@images.csv'<br />
              {'       '}const formData = new FormData();<br />
              {'       '}formData.append("image", files);<br />
              {'       '}fetch("https://pva.pixyle.ai/v2/create" , {'{'}<br />
              {'       '}method: "POST",<br />
              {'       '}headers: {'{'}"Content-Type": "multipart/form-data",<br />
              {'      '}"Authorization": "Bearer access_token"{'}'}<br />
              {'       '}body: formData <br />
              {'     '}{'}'})<br />
            
          </>
          : langCode == "python" ?  
            <>
              <span class="grayChar">$ </span>
              files = '@images.csv'<br />
              {'       '}response = requests.post('https://pva.pixyle.ai/v2/create',<br/> 
              {'       '}headers= {'{'}"Authorization": "Bearer access_token", <br/>
              {'       '}"Content-Type": "multipart/form-data"{'}'}<br />
              {'       '}files=files)" <br />
              </>
          :
          <>
            <span class="grayChar">$</span> curl -H 'Content-Type: multipart/form-data' \<br/>
            {'       '}-H 'Authorization: Bearer access_token' \<br/>
            {'       '}-F 'file<span class="redChar">=</span>@images.csv' \<br/>
            {'       '}-X POST https://pva.pixyle.ai/v2/create<br/><br/>
          </>
          }
<br/>
            <span class="grayChar"># example JSON response</span><br/><br/>
            <span class="redChar">{'{'}</span><br/>
            {'  '}<span class="orangeChar">"status"</span>: <span class="yellChar">"OK"</span>,<br/>
            {'  '}<span class="orangeChar">"method"</span>: <span class="yellChar">"create"</span>,<br/>
            {'  '}<span class="orangeChar">"dataset_id"</span>: <span class="whiteChar"> 1570624498775133,</span><br/>
            {'  '}<span class="orangeChar">"dataset_count"</span>: 2,<br/>
            {'  '}<span class="orangeChar">"collection_name"</span>: <span class="yellChar">"images.csv"</span>,<br/>
            {'  '}<span class="orangeChar">"errors"</span>: <span class="redChar">[]</span>,<br/>
            {'  '}<span class="orangeChar">"meta"</span>:<br/>
            {'    '}<span class="redChar">{'{'}</span><br/>
            {'      '}<span class="orangeChar">"number_of_products"</span>: 1000,<br/>
            {'      '}<span class="orangeChar">"number_of_images"</span>: 4000<br/>
            {'    '}<span class="redChar">{'}'}</span><br/>
            <span class="redChar">{'}'}</span></pre></div><br/>


      </div>
    </main>
  </div>

  )
}

export default UploadFile