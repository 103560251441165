import React from "react";
import { useStore } from "react-context-hook";

const Dashboard = () => {
  const [langCode, ] = useStore("langCode",'');
  return (<div class="gridContainer">
    <main class="mainContainer">
      <div class="mainContent js-toc-content"><br/>
      <h3 class="content" id="dashboard">
          Dashboard
        </h3>
        <div class="content">
          <pre class="mainLink" style={{margin: '0 0 16px 0'}}>GET /v3/dashboard/<i>{'{solution}'}</i></pre>
        </div>
        <p class="content">
          This API endpoint allows you to check all your datasets. As a response you will get a list of JSON objects, each representing a dataset data in a specific solution.
        </p>
        <br />
        <br />
        <h4 class="content unbold">Request Parameters</h4>
        <br />
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>solution</td>
                <td>A solution which dashboard should be returned <br />
                  Possible values = [“auto-tag”, “auto-scale”, “visual-search”]</td>
              </tr>
            </tbody>
          </table>
          <h4 class="content unbold">Response properties</h4>
        </div>

        {langCode== 'js' ? 
          <div class=" theCode">
            <pre>
              <span class="grayChar"># example request</span><br /><br />
              <span class="grayChar">$ </span><span className="yellow">fetch</span>(<span className="txt">"https://pva.pixyle.ai/v3/dashboard/auto-tag"</span>, {'{'}<br />
              {'       '}<span className="blue">method</span>:<span className="txt"> "GET"</span>,<br />
              {'       '}<span className="blue">headers</span>: {'{'}<span className="txt">"Authorization"</span>:<span className="txt"> "Bearer access_token"</span>{'}'}<br />
              {'     '}{'}'})<br />
            </pre>
          </div>
          : langCode == "python" ?  
          <div class=" theCode">
            <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$ </span>requests.get(<span className="txt">"https://pva.pixyle.ai/v3/dashboard/auto-tag"</span>, <br />
            {'       '}<span className="blue">headers</span>= {'{'}<span className="txt">"Authorization"</span>:<span className="txt"> "Bearer access_token"</span>{'}'}<br />
            {'     '})<br />
            </pre>
          </div>
          : 
          <div class=" theCode">
          <pre><span class="grayChar"># example request</span><br /><br />
            <span class="grayChar">$</span> curl -H 'Authorization: Bearer access_token' \<br />
            {'       '}-X GET https://pva.pixyle.ai/v3/dashboard/auto-tag</pre></div>
          }
        <div class="content">
          <table class="content">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>dataset_id</td>
                <td>A unique identifier for a particular data file</td>
              </tr>
              <tr>
                <td>collection_name</td>
                <td>The original name of the collection</td>
              </tr>
              <tr>
                <td>created_at</td>
                <td>Date when the dataset was created</td>
              </tr>
              <tr>
                <td>updated_at</td>
                <td>Date when last time the dataset was updated</td>
              </tr>
              <tr>
                <td>number_of_products</td>
                <td>Total number of products in the dataset</td>
              </tr>
              <tr>
                <td>verified_products</td>
                <td>Number of validated products</td>
              </tr>
              <tr>
                <td>verified_products_percentage</td>
                <td>Percentage value of the amount of validated products, over the total number of products</td>
              </tr>
              <tr>
                <td>init_products_percentage</td>
                <td>Percentage value of the amount of processed products, over the total number of products</td>
              </tr>
              <tr>
                <td>downloaded_percentage</td>
                <td>Percentage value of the amount of downloaded products, over the total number of products</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" theCode">
          <pre>
            <span class="grayChar"># example JSON response</span><br/><br/>
            <span class="orangeChar">
              <span class="redChar">[</span><br/>
              {'  '}<span class="redChar">{'{'}</span><br/>
              {'    '}"dataset_id"<span class="whiteChar">: 1570624498775133,</span><br/>
              {'    '}"collection_name"<span class="whiteChar">:</span> <span class="yellChar">"images.csv"</span><span class="whiteChar">,</span><br/>
              {'    '}"created_at"<span class="whiteChar">:</span> <span class="yellChar">"Fri, 27 Dec 2019 12:25:46 GMT"</span><span class="whiteChar">,</span><br/>
              {'    '}"updated_at"<span class="whiteChar">:</span> <span class="yellChar">"Fri, 27 Dec 2019 12:25:46 GMT"</span><span class="whiteChar">,</span><br/>
              {'    '}"number_of_products"<span class="whiteChar">: 1000,</span><br/>
              {'    '}"verified_products"<span class="whiteChar">: 0,</span><br/>
              {'    '}"verified_products_percentage"<span class="whiteChar">: 0.00,</span><br/>
              {'    '}"init_products_percentage"<span class="whiteChar">: 100.00,</span><br/>
              {'    '}"downloaded_percentage"<span class="whiteChar">: 100.00</span><br/>
              {'  '}<span class="redChar">{'}'}<span class="whiteChar">,</span></span><br/>
              {'  '}<span class="whiteChar">...</span><br/>
              <span class="redChar">]</span>
            </span></pre></div>
      </div>
    </main>
  </div>

  )
}

export default Dashboard